//import 'hammerjs';
import { ImageCropperModule } from 'ngx-image-cropper';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { LoginComponent } from './components/account/login/login.component';
import { LogoutComponent } from './components/account/logout/logout.component';
import { HomeComponent } from './components/page/home/home.component';
import { FooterComponent } from './components/page/footer/footer.component';
import { HeaderComponent } from './components/page/header/header.component';
import { UploadDocumentComponent } from './components/document/upload-document/upload-document.component';
import { UploadDocumentPageComponent } from './pages/upload-document-page/upload-document-page.component';
import { DocumentsPageComponent } from './pages/documents-page/documents-page.component';
import { StatsPageComponent } from './pages/stats-page/stats-page.component';
import { DocumentlistComponent } from './components/document/documentlist/documentlist.component';
import { PagerComponent } from './components/core/pager/pager.component';
import { CheckboxGroupComponent } from './components/core/checkbox-group/checkbox-group.component';
import { DocumentdetailsComponent } from './components/document/documentdetails/documentdetails.component';
import { DocumentdetailsIntakeComponent } from './components/document/documentdetails-intake/documentdetails-intake.component';
import { DatePipe } from '@angular/common';
import { ReportlistComponent } from './components/report/reportlist/reportlist.component';
import { ReportDateSelectorComponent } from './components/core/report-date-selector/report-date-selector.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
//import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';

//
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs'; 
import { MatAutocompleteModule } from '@angular/material/autocomplete'; 
import { MatTooltipModule } from '@angular/material/tooltip'; 
import { MatIconModule } from '@angular/material/icon'; 
import { MatMenuModule } from '@angular/material/menu'; 
import { MatButtonModule } from '@angular/material/button'; 
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox'; 
import { MatRadioModule } from '@angular/material/radio'; 
import { MatSelectModule } from '@angular/material/select'; 
import { MatListModule } from '@angular/material/list'; 
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator'; 
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { PlainLayoutComponent } from './layout/plain-layout/plain-layout.component';
import { ZZReportlistComponent } from './components/report/zzreportlist/zzreportlist.component';
import { AssetsIssuePageComponent } from './pages/assets-issue-page/assets-issue-page.component';
import { AssetsPageComponent } from './pages/assets-page/assets-page.component';
import { AssetlistComponent } from './components/asset/assetlist/assetlist.component';
import { ContactlistComponent } from './components/contact/contactlist/contactlist.component';
import { CompanylistComponent } from './components/contact/companylist/companylist.component';
import { CompaniesPageComponent } from './pages/companies-page/companies-page.component';
import { DocumentPageComponent } from './pages/document-page/document-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AssetsLogPageComponent } from './pages/assets-log-page/assets-log-page.component';
import { AssetloglistComponent } from './components/asset/assetloglist/assetloglist.component';
import { AccountPasswordComponent } from './components/account/account-password/account-password.component';
import { AccountProfileComponent } from './components/account/account-profile/account-profile.component';
import { AccountDeleteComponent } from './components/account/account-delete/account-delete.component';
import { AccountImageComponent } from './components/account/account-image/account-image.component';
import { AccountPasswordForgottenComponent } from './components/account/account-password-forgotten/account-password-forgotten.component';
import { AccountNotificationsComponent } from './components/account/account-notifications/account-notifications.component'; 
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { SignatureComponent } from './components/core/signature/signature.component';
import { AssetIssueComponent } from './components/asset/asset-issue/asset-issue.component';
import { AssetReturnComponent } from './components/asset/asset-return/asset-return.component';
import { AssetsReturnPageComponent } from './pages/assets-return-page/assets-return-page.component';
import { AssetreportlistComponent } from './components/report/assetreportlist/assetreportlist.component';
import { CommentlistComponent } from './components/report/commentlist/commentlist.component';
import { AssetDetailsComponent } from './components/asset/asset-details/asset-details.component';
import { AssetPageComponent } from './pages/asset-page/asset-page.component';
import { ContentDiviPageComponent } from './pages/content-divi-page/content-divi-page.component';
import { CheckinPageComponent } from './pages/checkin-page/checkin-page.component';
import { CheckinSelfPageComponent } from './pages/checkin-self-page/checkin-self-page.component';
import { EmployeeCheckinStatusComponent } from './components/employee/employee-checkin-status/employee-checkin-status.component';
import { CheckinSupportedPageComponent } from './pages/checkin-supported-page/checkin-supported-page.component';
import { EmployeeCheckinSupportedComponent } from './components/employee/employee-checkin-supported/employee-checkin-supported.component';
import { EmployeesPageComponent } from './pages/employees-page/employees-page.component';
import { EmployeeDetailsComponent } from './components/employee/employee-details/employee-details.component';
import { CheckinreportlistComponent } from './components/report/checkinreportlist/checkinreportlist.component';
import { CallsPageComponent } from './pages/calls-page/calls-page.component';
import { ConfigPageComponent } from './pages/config-page/config-page.component';
import { CalllistComponent } from './components/call/calllist/calllist.component';
import { ConfigDialogsComponent } from './components/config/config-dialogs/config-dialogs.component';
import { FormsIntakePageComponent } from './pages/forms-intake-page/forms-intake-page.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    ReportlistComponent,
    ReportDateSelectorComponent,
    UploadDocumentComponent,
    UploadDocumentPageComponent,
    DocumentsPageComponent,
    StatsPageComponent,
    NavigationComponent,
    CalllistComponent,
    DocumentlistComponent,
    PagerComponent,
    CheckboxGroupComponent,
    DocumentdetailsComponent,
    DocumentdetailsIntakeComponent,
    MainLayoutComponent,
    PlainLayoutComponent,
    ZZReportlistComponent,
    AssetsIssuePageComponent,
    AssetsPageComponent,
    AssetlistComponent,
    ContactlistComponent,
    CompanylistComponent,
    CompaniesPageComponent,
    DocumentPageComponent,
    HomePageComponent,
    AssetsLogPageComponent,
    AssetloglistComponent,
    ProfilePageComponent,
    AccountPasswordComponent,
    AccountProfileComponent,
    AccountDeleteComponent,
    AccountImageComponent,
    AccountPasswordForgottenComponent,
    AccountNotificationsComponent,
    SignatureComponent,
    AssetIssueComponent,
    AssetReturnComponent,
    AssetsReturnPageComponent,
    AssetreportlistComponent,
    CommentlistComponent,
    AssetDetailsComponent,
    AssetPageComponent,
    ContentDiviPageComponent,
    CheckinPageComponent,
    EmployeeCheckinStatusComponent,
    CheckinSelfPageComponent,
    CheckinSupportedPageComponent,
    EmployeeCheckinSupportedComponent,
    EmployeesPageComponent,
    EmployeeDetailsComponent,
    CheckinreportlistComponent,
    CallsPageComponent,
    ConfigPageComponent,
    ConfigDialogsComponent,
    FormsIntakePageComponent
  ],
  imports: [
    FormsModule,
    ImageCropperModule,
    ReactiveFormsModule,
    Ng2GoogleChartsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatCardModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    MatPaginatorModule,
    MatMenuModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatListModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatProgressBarModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    NgxQRCodeModule
  ],
  providers: [
    DatePipe,
    //NativeAudio
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
