import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { StorageService } from '@app/services/storage.service';
import { UserService } from '@app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { User } from '@app/models/user.model';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HttpEvent, HttpEventType } from '@angular/common/http';

declare let M: any;

@Component({
  selector: 'app-account-image',
  templateUrl: './account-image.component.html',
  styleUrls: ['./account-image.component.css']
})
export class AccountImageComponent extends CustomComponent implements OnInit {

  @Input() userInput: User;
  action: string = 'view';

  profileImagesForm: FormGroup;
  profileImage = null;
  profileImagesMetadata = null;

  submitted = false;
  succeeded = false;
  uploadProcessing = false;
  uploadProgress: number = 0;

  imageChangedEvent: any = '';

  constructor(
    protected formBuilder: FormBuilder,
    protected override storageService: StorageService,
    protected userService: UserService,
    protected override router: Router
  ) { 
    super(storageService, router, true);
  }

  get pif() { return this.profileImagesForm.controls; }

  ngOnInit(): void {
    this.profileImagesForm = this.formBuilder.group({
      file : new FormControl(null),
      fileSource: new FormControl(null),
    }); 
  }

  edit(): void {
    this.action = 'edit';
    document.getElementById('profileImageInputfield').click();
  }

  save(): void {
    this.action = 'view';
  }

  onDocumentFileChange(event) {
    this.imageChangedEvent = event;

    if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          var file = event.target.files[i];
          var reader = new FileReader();

          reader.onload = (event:any) => {
            //console.log(event.target.result);
            this.profileImage = event.target.result; 

            this.profileImagesForm.patchValue({
              fileSource: this.profileImage
            });
          }

          this.profileImagesMetadata = {
            'name': file.name,
            'lastmodified': file.lastModified,
            'size': file.size,
            'type': file.type
          };

          reader.readAsDataURL(file);
        }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.profileImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  deleteProfileImage() {
    this.profileImage = null;
    this.profileImagesMetadata = null;
  }

  cancel() {
    this.deleteProfileImage();
    this.action = 'view';
  }

  upload() {
    this.submitted = true;
    this.validateAllFormControl(this.profileImagesForm);

    if (this.profileImagesForm.invalid) {
        return;

    } else {
      this.updateUserProfilImage();
    }

  }

  updateUserProfilImage() {
    this.uploadProcessing = true;

    var user = this.storageService.getUser();
    user.profileimage = this.profileImage;

    this.userService.updateUserProfilImage(user)
      .subscribe((event: HttpEvent<any>) => {
      
        switch (event.type) {
          case HttpEventType.Sent:
            // console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            // console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.uploadProgress = Math.round(event.loaded / event.total * 100);
            // console.log(`Uploaded! ${this.uploadProgress}%`);
            break;
          case HttpEventType.Response:
            this.succeeded = true;
            this.user.profileimage = user.profileimage;
            this.storageService.setUser(user);
            M.toast({html: 'Ihr Profilbild wurde aktualisiert.', classes: 'primary-bg'});
  
            this.deleteProfileImage();
            this.action = 'view';
    
            setTimeout(() => {
              this.uploadProgress = 0;
            }, 1500);
  
        }      
      });

  }
}
