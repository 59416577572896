<app-header></app-header>

<router-outlet></router-outlet>

<app-footer class="hide-on-med-and-up"></app-footer>

<div class="backtotop">
  <a class="btn-floating btn primary-bg">
    <i class="mdi mdi-chevron-up"></i>
  </a>
</div>
