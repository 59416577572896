import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { CallService } from '@app/services/call.service';
import { ConfigService } from '@app/services/config.service';
import { StorageService } from '@app/services/storage.service';
import { interval, Subscription } from 'rxjs';

declare let M: any;
declare let $: any;

@Component({
  selector: 'app-calls-page',
  templateUrl: './calls-page.component.html',
  styleUrls: ['./calls-page.component.scss']
})
export class CallsPageComponent extends CustomComponent implements OnInit {

  calls = null;
  totalCalls = null;
  pageSize = 10;
  page = 0;
  pageSizeOptions: number[] = [10, 25, 50];
  attributeFilters = []
  panelOpenState = true;
  private updateSubscription: Subscription;
  call = null;
  lastUpdate = null;

  callStatus = new FormControl('Open');
  callStatusData = [
    {'key': 'Alle', 'value': ''},
    {'key': 'Unbearbeitet', 'value': 'Open'},
    {'key': 'Bearbeitet', 'value': 'Done'}
  ]

  callIntent = new FormControl('');
  callIntentData = [
    {'key': 'Alle', 'value': ''}
  ]

  filterControls = {};
  filterControlsForm: FormGroup;

  /*
  callIntentString = 'Rezept';
  callIntentChanged2(event) {
    alert(this.callIntentString)
  }
  callStatusString = 'Open';
  callStatusChanged2(event) {
    alert(this.callIntentString)
  }
  */

  constructor(
    protected override storageService: StorageService,
    protected callService: CallService,
    protected configService: ConfigService,
    protected override router: Router
  ) { 

    super(storageService, router, true);
  }

  ngOnInit(): void {
    this.filterControlsForm = new FormGroup(this.filterControls);

    this.loadCalls();
    this.loadDialogConfig();
    this.loadFilters();

    this.updateSubscription = interval(60000).subscribe(
      (val) => { 
        if (this.call == null) {
          this.loadCalls();
        }
      }
    );
  }

  unsetCall() {
    this.call = null;
  }

  callSet(event) {
    this.call = event;
  }

  callChanged(event) {
    this.call = event;
    this.loadCalls();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  pageChanged(event) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex;
    this.loadCalls();
  }

  callIntentChanged(event) {
    this.page = 0;
    this.loadCalls();
    this.loadFilters();
  }

  callStatusChanged(event) {
    this.page = 0;
    this.loadCalls();
    this.loadFilters();
  }

  loadDialogConfig() {
    this.configService.getDialogConfig(this.user.guid)
    .subscribe(
      (getDialogConfigResponse) => {
        if (getDialogConfigResponse.config != null) {
          var dialogConfig = getDialogConfigResponse.config;

          dialogConfig.intents.forEach(intent => {
            this.callIntentData.push({'key': intent.name, 'value': intent.name});
          });
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  loadCalls() {
    this.lastUpdate = new Date();
    this.calls = null;
    this.callService.getCalls(this.user.guid, this.page, this.pageSize, this.callIntent.value, this.callStatus.value, this.filterControlsForm.value)
    .subscribe(
      (getCallsResponse) => {
        if (getCallsResponse.calls != null) {
          this.calls = getCallsResponse.calls;
          this.totalCalls = getCallsResponse.total;
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  loadFilters() {
    //this.calls = null;
    this.configService.getFilters(this.user.guid, this.callIntent.value, this.callStatus.value)
    .subscribe(
      (getAttributeFiltersResponse) => {
        console.log(getAttributeFiltersResponse);
        if (getAttributeFiltersResponse.attributeFilters != null) {
          this.attributeFilters = getAttributeFiltersResponse.attributeFilters;

          this.recreateFilterFormControls();
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  hasFilter() {
    var result = false;

    this.attributeFilters.forEach(attributeFilter => {
      if (attributeFilter.values != null && attributeFilter.values.length > 0) {
        result = true;
      }
    });

    return result;
  }

  recreateFilterFormControls() {
    if (this.attributeFilters != null && this.attributeFilters.length > 0) {
      this.attributeFilters.forEach(attributeFilter => {
        var filterControl = new FormControl('');
        this.filterControls[attributeFilter.name] = filterControl;
      });
    }

    this.filterControlsForm = new FormGroup(this.filterControls);
  }



  @ViewChild('callsContent') set callsContent(element) {
    if (element) {
      M.AutoInit();
    }
  }

  attributeFilterChanged(event) { 
    this.loadCalls();
  }

}
