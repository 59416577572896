<div class="container">
    <div class="section">
    
        <h1 class="bot-20 sec-tit">Objekt</h1>

        <div class="row">
        
            <app-asset-details></app-asset-details>
            
        </div>

    </div>

</div>


