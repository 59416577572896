import { Injector } from '@angular/core';
import { AbstractControl, FormControl, Validators, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UserService } from '@app/services/user.service';

export class CustomValidators {

    static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {

        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
            // if control is empty return no error
            return null;
            }
        
            // test the value of the control against the regexp supplied
            const valid = regex.test(control.value);
        
            // if true, return no error (no error), else return error passed in the second parameter
            return valid ? null : error;
        };

    }

    static passwordMatchValidator(control: AbstractControl) {

        const password: string = control.get('password').value; 
        const confirmPassword: string = control.get('confirmPassword').value; 

        if (password !== confirmPassword) {
            control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
        }
        
    }

    /*
    static conditionalRequiredValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (<FormGroup>control.parent) {
                var parent = <FormGroup>control.parent;
                if (parent.get("guid").value == -1) {
                    if (Validators.required(control)) {
                        return { required: true };
                    } else {
                        return null;
                    }
                }
            }
        }
    }
    */

    static objectformRequiredValidator(formGroup: FormGroup) {

        if (formGroup.get('guid').value == -1) {
            var error = false;

            if (Validators.required(formGroup.get('title'))) {
                formGroup.get('title').setErrors({ required: true });
                error = true;
            }
            if (Validators.required(formGroup.get('street'))) {
                formGroup.get('street').setErrors({ required: true });
                error = true;
            }
            if (Validators.required(formGroup.get('houseno'))) {
                formGroup.get('houseno').setErrors({ required: true });
                error = true;
            }
            if (Validators.required(formGroup.get('zipcode'))) {
                formGroup.get('zipcode').setErrors({ required: true });
                error = true;
            }
            if (Validators.required(formGroup.get('city'))) {
                formGroup.get('city').setErrors({ required: true });
                error = true;
            }

            return (error == true) ? { required: true } : null;
        }

        return null;
    }
        
}
