import { Component, OnInit } from '@angular/core';
import { StorageService } from '@app/services/storage.service';
import { UserService } from '@app/services/user.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from '@app/models/user.model';
import { CustomValidators } from '@app/validators/custom-validators';

declare let M: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  emailInput: string;
  tnc: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { 
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.tnc == 1) {
        this.tnc = true;
      }
    });
  }

  ngOnInit(): void {
    this.submitted = false;

    this.loginForm = this.formBuilder.group({
      //firstname: new FormControl('', Validators.required),
      //lastname: new FormControl('', Validators.required),
      //phone: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]), 
      password: new FormControl('', [
        Validators.required,
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        //CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        //CustomValidators.patternValidator(/[ [!@#$%^&*()_+-=[]{};':"|,.<>/?]/](<mailto:!@#$%^&*()_+-=[]{};':"|,.<>/?]/>), { hasSpecialCharacters: true }),
        Validators.minLength(8)
      ]),
      acceptTerms: new FormControl(false, (this.tnc==true)?Validators.requiredTrue:null)
    },
    {
    });

    this.route.queryParams.subscribe(params => {
      this.emailInput = params.email;
      if (this.emailInput) {
        this.loginForm.controls['email'].patchValue(this.emailInput);
      }
    });

  }

  get f() { return this.loginForm.controls; }

  validateAllFormControl(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);             
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormControl(control);            
      }
    });
  }

  login() {
    this.submitted = true;

    if (this.loginForm.invalid) {

        return;
    } else {

      this.userService.login(this.loginForm)
      .subscribe(
        (getUserResponse) => {
          if (getUserResponse.status == '200') {
            this.storageService.setUser(getUserResponse.user);
            this.router.navigateByUrl('/');

          } else if (getUserResponse.status == '400') {
            
            if (getUserResponse.errorcode == '0') {
              M.toast({html: 'Allgemeiner Fehler beim Login!', classes: 'primary-bg red white-text'});
            } else if (getUserResponse.errorcode == '100') {
              M.toast({html: 'Fehler beim Login!', classes: 'primary-bg red white-text'});
            } else if (getUserResponse.errorcode == '200') {
              M.toast({html: 'Sie haben Ihren Zugang noch nicht aktiviert!', classes: 'primary-bg red white-text'});
            }
          }

        },
        response => {
          console.log(response);
        },
        () => {
            //console.log("The POST observable is now completed.");
        });

      }
  }

  reset() {
    this.router.navigate(['/passwort'], { queryParams: { email: this.loginForm.controls['email'].value } });
  }

}
