import { Component, Input, OnInit } from '@angular/core';
import { CustomComponent } from '@app/components/core/custom-component';
import { User } from '@app/models/user.model';
import { StorageService } from '@app/services/storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import {Subscription} from 'rxjs';

declare let $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent extends CustomComponent implements OnInit {
  
  @Input() template: string;
  private entrySub: Subscription;
 
  constructor(
    protected override storageService: StorageService,
    protected override router: Router
  ) { 

    super(storageService, router, false);
  }

  ngOnInit(): void {
    this.entrySub = this.storageService.userChanged.subscribe(user => this.user = user);

    $('.burger, .overlay').click(function(){
      $('.burger').toggleClass('clicked');
      $('.overlay').toggleClass('show');
      $('nav').toggleClass('show');
      $('body').toggleClass('overflow');  
    });

  }

  ngOnDestroy() {
    if (this.entrySub) {
      this.entrySub.unsubscribe()
    }
  }

}
