import { StorageService } from '@app/services/storage.service';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { registerLocaleData } from '@angular/common';

import de from '@angular/common/locales/de';
import { User } from '@app/models/user.model';

export class CustomComponent {

    public user;
    numberPattern = "[0-9]+$";
    phonePattern = "([+]?)+[0-9 ]+$";
    emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  
    /* https://angular.io/guide/lifecycle-hooks */
    constructor(
        protected storageService: StorageService,
        protected router: Router,
        private loginCheck: boolean
    ) { 
        registerLocaleData(de);

        if (this.loginCheck && this.isLoggedIn() == false) {
            this.router.navigate(['/login']);
        }

        this.user = this.storageService.getUser();
    }

    public isApp(): boolean {
        if(window.hasOwnProperty("cordova")){
            return true;
        } else {
            return false;
        }
    }

    isJSON(str): boolean {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }

    public isLoggedIn(): boolean {
        return (this.storageService.getUser() != null);
    }

    public getUser(): User {
        return this.storageService.getUser();
    }

    public getUserPermissions(): any {
        var permissions = [];
        if (this.storageService.getUser()) {
            var permissionsString = this.storageService.getUser().permissions;

            try {
                permissions = JSON.parse(permissionsString);
            } catch (e) {
                permissions = [];
            }
        }

        return permissions;
    }

    public hasPermissions(permission) {
        var permissions = this.getUserPermissions();

        if (permissions[permission]) {
            return true;
        } else {
            return false;
        }
    }
    

    protected validateAllFormControl(formGroup: FormGroup) {     
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);             
            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFormControl(control);            
            }
        });
    }

    populateFormGroup_alt(form: FormGroup, obj: any): void {
        if (obj && form) {
            let formKeys = Object.keys(form.controls);
            for (let i=0;i<formKeys.length;i++) {
                if (form.controls[formKeys[i]]) {
                    form.controls[formKeys[i]].setValue(obj[formKeys[i]]);
                }
            }
        }
    }

    populateFormGroup(form: FormGroup, obj: any): void {
        if (obj && form) {
            let formKeys = Object.keys(form.controls);
    
            for (let key of formKeys) {
                if (form.controls[key]) {
                    if (form.controls[key] instanceof FormArray && Array.isArray(obj[key])) {
                        // Handle FormArray population
                        let formArray = form.controls[key] as FormArray;
                        formArray.clear(); // Clear existing entries
                        
                        obj[key].forEach(value => {
                            formArray.push(new FormControl(value)); // Add new values dynamically
                        });
                    } else {
                        // Handle regular form controls
                        form.controls[key].setValue(obj[key]);
                    }
                }
            }
        }
    }

    populateObject2(obj: any, form: FormGroup): void {
        if (obj && form) {
            let formKeys = Object.keys(form.controls);
            for (let i=0;i<formKeys.length;i++) {
                if (form.controls[formKeys[i]]) {
                    obj[formKeys[i]] = form.controls[formKeys[i]].value;
                }
            }
        }
    }

    populateObject(obj: any, form: FormGroup): void {
        if (obj && form) {
            let formKeys = Object.keys(form.controls);
            
            for (let key of formKeys) {
                if (form.controls[key]) {
                    if (form.controls[key] instanceof FormArray) {
                        // Convert FormArray to a simple array of values
                        obj[key] = (form.controls[key] as FormArray).controls.map(control => control.value);
                    } else {
                        obj[key] = form.controls[key].value;
                    }
                }
            }
        }
    }

    public getReadableFileSizeString(fileSizeInBytes) {
        var i = -1;
        var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);
    
        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    };

    public getTimestamp(timestamp) {
        //console.log(new Date().getTimezoneOffset());
        if (timestamp) {
            return timestamp.replace(/-/g,"/") + " UTC";
        } else {
            return null;
        }
    }

    public getSQLDateFromUIDate(dateString) {
        if (dateString && dateString != '') {
            var dmy = dateString.split(".");
//            var dateTmp = new Date(dmy[2], dmy[1] - 1, dmy[0]);
//            var date = dateTmp.toISOString().replace(/T/g," ").replace(/Z/g,"");

//            return date;

            return dmy[2] + "-" + dmy[1] + "-" + dmy[0];
        } else {
            return null;
        }

    }

    translateValue(data, val): string {
        if(val == null) {
            val = '';
        }

        var returnvalue = '';

        for (const item of data) {
            if (item.key == val) {
                returnvalue = item.value;
            }
        }

        return returnvalue;
    }
        
}
