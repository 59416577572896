import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { GetCallResponse } from '@app/models/responses/getCallResponse.model';
import { GetCallsResponse } from '@app/models/responses/getCallsResponse.model';

import { environment } from 'src/environments/environment';
import { GenericResponse } from '@app/models/responses/genericResponse.model';
import { GenericCallResponse } from '@app/models/responses/genericCallResponse.model';
import { GetHistoryResponse } from '@app/models/responses/getHistoryResponse.model';
import { GetCallRelationsResponse } from '@app/models/responses/getCallRelationsResponse.model';

@Injectable({
  providedIn: 'root'
})
export class CallService {
  private headers = null;
  private options = {};

  constructor(
    private http: HttpClient

  ) { 
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    this.options = {
      headers: this.headers
    }
  }

  getCalls(guid, page, pageSize, intent, status, attributeFilters) {
    let params = new HttpParams();
    params = params.append('user_guid', guid);
    params = params.append('intent', intent);
    params = params.append('status', status);
    params = params.append('attribute_filters', JSON.stringify(attributeFilters));
    params = params.append('page', page);
    params = params.append('page_size', pageSize);
    this.options['params'] = params;

    return this.http.get<GetCallsResponse>(environment.apiEndpoint + '/api/calls/1.0.0.php', this.options);
  }

  getCall(guid) {
    let params = new HttpParams();
    params = params.append('guid', guid);
    this.options['params'] = params;

    return this.http.get<GetCallResponse>(environment.apiEndpoint + '/api/call/1.0.0.php', this.options);
  }

  updateStatus(guid, call) {
    const body = {
      'guid': guid,
      'call': {
        'guid': call.guid,
        'status': call.status
      }
    };

    return this.http.put<GenericCallResponse>(environment.apiEndpoint + '/api/call/status/1.0.0.php', body, this.options);
  }

  getHistory(guid, call_guid) {
    let params = new HttpParams();
    params = params.append('guid', guid);
    params = params.append('call_guid', call_guid);
    this.options['params'] = params;

    return this.http.get<GetHistoryResponse>(environment.apiEndpoint + '/api/call/history/1.0.0.php', this.options);
  }

  getCallRelations(guid, call_guid) {
    let params = new HttpParams();
    params = params.append('guid', guid);
    params = params.append('call_guid', call_guid);
    this.options['params'] = params;

    return this.http.get<GetCallRelationsResponse>(environment.apiEndpoint + '/api/call/relation/1.0.0.php', this.options);
  }

  addNote(guid, call_guid, note) {
    const body = {
      'guid': guid,
      'call_guid': call_guid,
      'note': note
    };

    return this.http.post<GenericResponse>(environment.apiEndpoint + '/api/call/note/1.0.0.php', body, this.options);
  }
}
