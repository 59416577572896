import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as CryptoJS from 'crypto-js';  
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit {

  signaturePad = null;

  constructor(
    public dialogRef: MatDialogRef<SignatureComponent>
  ) { }

  ngOnInit(): void {
    var canvas = <HTMLCanvasElement>document.getElementById("signature_pad");

    this.signaturePad = new SignaturePad(canvas, {
      minWidth: 1,
      maxWidth: 5,
      penColor: "rgb(0, 0, 0)"
    });
    
    this.resizeCanvas(canvas);
  }

  clearWhiteboard() {
    this.signaturePad.clear();

    this.dialogRef.close(null);
  }

  resizeCanvas(canvas) {
    var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }

  saveSignature() {
    var dataSVG = this.signaturePad.toDataURL("image/svg+xml");
    var dataPNG = this.signaturePad.toDataURL();

    var data = {
      'svg': dataSVG,
      'png': dataPNG
    }
    this.dialogRef.close(data);
  }

}
