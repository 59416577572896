<!-- Regular page -->
<div *ngIf="succeeded == false">

    <!-- Buttons -->
    <div class="card-panel" *ngIf="integration == true">

        <div class="left">
            <div *ngIf="action == 'view'">
                <a (click)="setAction('edit')" class="btn white grey-text"><i class="mdi mdi-playlist-edit left"></i>Bearbeiten</a>
                &nbsp;
                <a (click)="lockDocument()" class="btn primary-bg" *ngIf="document.status == 0"><i class="mdi mdi-lock-outline left"></i>Freigeben</a>
            </div>
        
            <div *ngIf="action == 'edit'">
                <a (click)="cancel()" class="btn white grey-text"><i class="mdi mdi-close left"></i>Abbrechen</a>
                &nbsp;
                <a (click)="save()" class="btn primary-bg"><i class="mdi mdi-content-save-outline left"></i>Speichern</a>
                <mat-checkbox class="pad-left-10" [(ngModel)]="autolockDocument">Dokument freigeben?</mat-checkbox>
            </div>
        </div>

        <div class="right">
            <div *ngIf="integration == true && action == 'view'">
                <a (click)="unsetDocument()" class="btn primary-bg" *ngIf="action == 'view'"><i class="mdi mdi-close-box-outline"></i></a>
            </div>

            <div *ngIf="integration == true && action == 'edit'">
                <a (click)="deleteDocument()" class="btn red"><i class="mdi mdi-lock-outline left"></i>Löschen</a>
            </div>
        </div>

        <br clear="all">
    </div>

    <!-- Images -->
    <div class="card-panel" *ngIf="integration == true">

        <div class="row" *ngIf="integration == true">
            <div class="col s4 m3">
                <b>Bogen:</b>
            </div>
            <div class="col s8 m9">
                {{document.name}}
            </div>
        </div>

        <div class="row" *ngIf="integration == true">
            <div class="col s4 m3">
                <b>Gescannt am:</b>
            </div>
            <div class="col s8 m9">
                {{getTimestamp(document.timestamp) | date:'medium':'':'de'}}
            </div>
        </div>


        <div class="center-align">
            <a (click)="loadScans()" class="btn white grey-text" *ngIf="showScans == false">
                <i class="mdi mdi-file-image"></i>Bilder laden
            </a>
            <a (click)="toggleScans()" class="btn white grey-text" *ngIf="showScans == true">
                <i class="mdi mdi-file-image"></i>Bilder verstecken
            </a>
        </div>

        <div class="row" *ngIf="showScans == true">
            <br>
            <div class="col s6">
                <img src="/assets/images/img_placeholder.jpg" class="responsive-img z-depth-1" id="page0">
            </div>
            <div class="col s6">
                <img src="/assets/images/img_placeholder.jpg" class="responsive-img z-depth-1" id="page1">
            </div>
        </div>

    </div>

    <!-- Steps bar -->
    <div class="card-panel center-align">
        <mat-tab-group mat-stretch-tabs [selectedIndex]="step" (selectedIndexChange)="setStep($event)">
            <mat-tab label="Allgemeines"></mat-tab>
            <mat-tab label="Aufnahme"></mat-tab>
            <mat-tab label="Aufenthalt"></mat-tab>
            <mat-tab label="Hotelservice"></mat-tab>
            <mat-tab label="Weiterempfehlung"></mat-tab>
        </mat-tab-group>
    </div>

    <!-- Content -->
    <form [formGroup]="documentForm">

        <!-- Details Step 0 -->
        <div class="card darken-1" *ngIf="step == 0">

            <div class="card-content">

                <div class="row">
                    <div class="col s12 l6">
                        <fieldset>
                            <legend>Aufenthalt</legend>

                            <div class="row no-margin">
                                <div class="col s4">
                                    <b>Monat</b>
                                </div>
                                <div class="col s8">
                                    <div *ngIf="action == 'view'">
                                        {{monthString()}}
                                    </div>
                                    <div *ngIf="action == 'edit'" class="input-field">
                                        <select class="browser-default custom-select" formControlName="month" [ngClass]="{ 'invalid': submitted && df.month.errors }">
                                            <option *ngFor="let monthItem of monthData" [value]="monthItem.key">{{monthItem.value}}</option>
                                        </select>
                                        <div *ngIf="submitted && df.month.errors" class="error pad-top-5" data-error="Fehlerhafte Eingabe" data-success="">Fehlerhafte Eingabe</div>
                                    </div>
                                </div>
                            </div>
                
                            <div class="row no-margin">
                                <div class="col s4">
                                    <b>Jahr</b>
                                </div>
                                <div class="col s8">
                                    <div *ngIf="action == 'view'">
                                        {{document.content.year}}
                                    </div>
                                    <div *ngIf="action == 'edit'" class="input-field">
                                        <input type=text formControlName="year" id="year" [ngClass]="{ 'invalid': submitted && df.year.errors }">
                                        <span *ngIf="submitted && df.year.errors" class="error" data-error="Fehlerhafte Eingabe" data-success="">Fehlerhafte Eingabe</span>
                                    </div>
                                </div>
                            </div>

                            <div class="row no-margin">
                                <div class="col s4">
                                    <b>Aufenthaltsdauer in Tagen</b>
                                </div>
                                <div class="col s8">
                                    <div *ngIf="action == 'view'">
                                        {{document.content.days}}
                                    </div>
                                    <div *ngIf="action == 'edit'" class="input-field">
                                        <input type=text formControlName="days" id="days" [ngClass]="{ 'invalid': submitted && df.days.errors }">
                                        <span *ngIf="submitted && df.days.errors" class="error" data-error="Fehlerhafte Eingabe" data-success="">Fehlerhafte Eingabe</span>
                                    </div>
                                </div>
                            </div>
                
                        </fieldset>
                    </div>
                    <div class="col s12 l6">
                        <fieldset>
                            <legend>Einrichtung</legend>

                            <div class="row no-margin">
                                <div class="col s4">
                                    <b>Ebene</b>
                                </div>
                                <div class="col s8">
                                    <div *ngIf="action == 'view'">
                                        {{levelString()}}
                                    </div>
                                    <div *ngIf="action == 'edit'">
                                        <select class="browser-default custom-select" formControlName="level">
                                            <option *ngFor="let levelItem of levelData" [value]="levelItem.key">{{levelItem.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row no-margin">
                                <div class="col s4">
                                    <b>Department</b>
                                </div>
                                <div class="col s8">
                                    <div *ngIf="action == 'view'">
                                        {{departmentString()}}
                                    </div>
                                    <div *ngIf="action == 'edit'">
                                        <select class="browser-default custom-select" formControlName="department">
                                            <option *ngFor="let departmentItem of departmentData" [value]="departmentItem.key">{{departmentItem.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <!--div class="row no-margin">
                                <div class="col s4">
                                    <b>Empfehlung</b>
                                </div>
                                <div class="col s8">
                                    <div *ngIf="action == 'view'">
                                        {{document.content.referrer}}
                                    </div>
                                    <div *ngIf="action == 'edit'" class="input-field">
                                        <input type=text formControlName="referrer" id="referrer">
                                    </div>
                                </div>
                            </div-->
                            
                        </fieldset>
                    </div>
                </div>

                <div class="row no-margin">
                    <div class="col s12">
                        <fieldset>
                            <legend>Informationen</legend>

                            <div class="row">
                                <div class="col s12">
                                    Haben Sie sich vor Ihrem Aufenthalt über die Waldkliniken informiert?
                                </div>
                            </div>

                            <mat-radio-group formControlName="info">
                                <div class="row">
                                    <div class="col s12">
                                        <mat-radio-button class="pad-left-10" [disabled]="action == 'view'" value="no" [ngClass]="{ 'invalid': submitted && df.info.errors }">Nein</mat-radio-button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s12">
                                        <mat-radio-button class="pad-left-10" [disabled]="action == 'view'" value="yes" [ngClass]="{ 'invalid': submitted && df.info.errors }">Ja, und zwar über...</mat-radio-button>
                                        <div class="row" *ngIf="df.info.value == 'yes'">
                                            <mat-selection-list formControlName="infoList">
                                                <mat-list-option *ngFor="let item of infoList | keyvalue: returnZero" [value]="item.key" [disabled]="action == 'view'">
                                                    {{item.value}}
                                                </mat-list-option>
                                            </mat-selection-list>
                                        </div>
                                    </div>
                                </div>
                            </mat-radio-group>
                            <span *ngIf="submitted && df.info.errors" class="error" data-error="Fehlerhafte Eingabe" data-success="">Fehlerhafte Eingabe</span>
                                
                        </fieldset>
                    </div>
                </div>

            </div>

        </div>

        <!-- Details Step 1 -->
        <div class="card darken-1" *ngIf="step == 1">

            <div class="card-content">
                <span class="card-title">Aufnahme</span>
            
                <div class="row headline-row">
                    <div class="col s12 m12 l6"></div>
                    <div class="col s12 l6">
                        <div class="col s2 center-align">Das Beste</div>
                        <div class="col s2 center-align">Sehr gut</div>
                        <div class="col s2 center-align">Gut</div>
                        <div class="col s2 center-align">Akzeptabel</div>
                        <div class="col s2 center-align">Schlecht</div>
                    </div>
                </div>

                <app-checkbox-group label="1.1 Die Freundlichkeit der aufnehmenden Fachkraft ist..." [action]="action" [form]="documentForm" name="q1_1"></app-checkbox-group>
                <app-checkbox-group label="1.2 Die Aufklärung durch die Ärzte zu meinen Fragen hinsichtlich OP ist..." [action]="action" [form]="documentForm" name="q1_2"></app-checkbox-group>
                <app-checkbox-group label="1.3 Die Aufklärung durch die Ärzte zu meinen Fragen hinsichtlich Narkose ist..." [form]="documentForm" name="q1_3" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="1.4 Die Freundlichkeit der Ärzte ist..." [form]="documentForm" name="q1_4" [action]="action"></app-checkbox-group>

                <br>
                <div class="row no-margin">
                    <div class="col s12 m6">
                        Wie lange war die Gesamtwartezeit vom Eintreffen bis zum Verlassen der Waldkliniken Eisenberg? (Während der Aufnahme)
                    </div>
                    <div class="col s12 m6">
                        <div *ngIf="action == 'view'">
                            {{document.content.waitingtime_hours | number:'2.0'}}
                            <span *ngIf="document.content.waitingtime_hours != null">:</span>
                            {{document.content.waitingtime_minutes | number:'2.0'}}
                        </div>
                        <div *ngIf="action == 'edit'" class="input-field">
                            Stunden: <input type=text formControlName="waitingtime_hours" id="waitingtime_hours" [ngClass]="{ 'invalid': submitted && df.waitingtime_hours.errors }"><br>
                            <span *ngIf="submitted && df.waitingtime_hours.errors" class="error" data-error="Fehlerhafte Eingabe" data-success="">Fehlerhafte Eingabe</span><br>
                            Minuten: <input type=text formControlName="waitingtime_minutes" id="waitingtime_minutes" [ngClass]="{ 'invalid': submitted && df.waitingtime_minutes.errors }"><br>
                            <span *ngIf="submitted && df.waitingtime_minutes.errors" class="error" data-error="Fehlerhafte Eingabe" data-success="">Fehlerhafte Eingabe</span><br>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- Details Step 2 -->
        <div class="card darken-1" *ngIf="step == 2">

            <div class="card-content">
                <span class="card-title">Aufenthalt</span>
                
                <div class="row headline-row">
                    <div class="col s12 m12 l6"></div>
                    <div class="col s12 l6">
                        <div class="col s2 center-align">Das Beste</div>
                        <div class="col s2 center-align">Sehr gut</div>
                        <div class="col s2 center-align">Gut</div>
                        <div class="col s2 center-align">Akzeptabel</div>
                        <div class="col s2 center-align">Schlecht</div>
                    </div>
                </div>

                <app-checkbox-group label="2.1 Die Berücksichtigung meiner Sorgen und Ängste durch die Pflegekräfte ist..." [action]="action" [form]="documentForm" name="q2_1"></app-checkbox-group>
                <app-checkbox-group label="2.2 Die tägliche Unterstützung durch die Pflegekräfte ist..." [action]="action" [form]="documentForm" name="q2_2"></app-checkbox-group>
                <app-checkbox-group label="2.3 Die Auskünfte der Pflegekräfte der Station über die Abläufe in den Waldkliniken sind..." [form]="documentForm" name="q2_3" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.4 Die Freundlichkeit der Pflegekräfte ist..." [form]="documentForm" name="q2_4" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.5 Die Wahrung meiner Intimsphäre durch das Pflegepersonal ist..." [form]="documentForm" name="q2_5" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.6 Die Einfühlsamkeit, mit der mir die Diagnosen mitgeteilt werden, ist..." [form]="documentForm" name="q2_6" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.7 Der Umgang der Ärzte mit Fragen während der Visite ist..." [form]="documentForm" name="q2_7" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.8 Die Aufklärung durch die Ärzte über Behandlung und Verlauf meiner Krankheit ist..." [form]="documentForm" name="q2_8" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.9 Der Erfolg der Behandlung ist bis jetzt..." [form]="documentForm" name="q2_9" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.10 Die Wirksamkeit mit der meine Schmerzen gelindert werden, ist..." [form]="documentForm" name="q2_10" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.11 Meine Erfahrungen mit der Physiotherapie sind..." [form]="documentForm" name="q2_11" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.12 Meine Erfahrungen mit der Röntgenabteilung sind..." [form]="documentForm" name="q2_12" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.13 Meine Erfahrungen mit der Funktionsabteilung/EKG sind..." [form]="documentForm" name="q2_13" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.14 Meine Erfahrungen mit dem Sozialdienst sind..." [form]="documentForm" name="q2_14" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.15 Die hygienischen Verhältnisse sind..." [form]="documentForm" name="q2_15" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.16 Die Angemessenheit der Information für meine Angehörigen ist..." [form]="documentForm" name="q2_16" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.17 Die Vorbereitung auf meine Entlassung aus den Waldkliniken ist..." [form]="documentForm" name="q2_17" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.18 Die ärztliche Aufklärung über Komplikationen meiner Krankheit, auf die ich nach meiner Entlassung achten muss, ist..." [form]="documentForm" name="q2_18" [action]="action"></app-checkbox-group>
                                
                <div class="row headline-row">
                    <div class="col s12 m12 l6"></div>
                    <div class="col s12 l6">
                        <div class="col s2 center-align">Immer</div>
                        <div class="col s2 center-align">Oft</div>
                        <div class="col s2 center-align">Selten</div>
                        <div class="col s2 center-align">Nie</div>
                        <div class="col s2 center-align"></div>
                    </div>
                </div>

                <app-checkbox-group label="2.19 Das Personal desinfiziert sich die Hände, bevor es mich behandelt..." size="4" [form]="documentForm" name="q2_19" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="2.20 Das Personal desinfiziert sich die Hände, nachdem es mich behandelt hat..." size="4" [form]="documentForm" name="q2_20" [action]="action"></app-checkbox-group>

                <div class="row headline-row">
                    <div class="col s12 m12 l6"></div>
                    <div class="col s12 l6">
                        <div class="col s2 center-align">Ja</div>
                        <div class="col s2 center-align">Teilweise</div>
                        <div class="col s2 center-align">Nein</div>
                        <div class="col s2 center-align"></div>
                        <div class="col s2 center-align"></div>
                    </div>
                </div>

                <app-checkbox-group label="2.21 Das Personal zieht sich Handschuhe an, bevor es bei mir einen Verbandswechsel durchführt." size="3" [form]="documentForm" name="q2_21" [action]="action"></app-checkbox-group>
                
            </div>

        </div>

        <!-- Details Step 3 -->
        <div class="card darken-1" *ngIf="step == 3">

            <div class="card-content">
                <span class="card-title">Hotelservice</span>
                
                <div class="row headline-row">
                    <div class="col s12 m12 l6"></div>
                    <div class="col s12 l6">
                        <div class="col s2 center-align">Das Beste</div>
                        <div class="col s2 center-align">Sehr gut</div>
                        <div class="col s2 center-align">Gut</div>
                        <div class="col s2 center-align">Akzeptabel</div>
                        <div class="col s2 center-align">Schlecht</div>
                    </div>
                </div>

                <app-checkbox-group label="3.1 Die Patientenzimmer sind..." [action]="action" [form]="documentForm" name="q3_1"></app-checkbox-group>
                <app-checkbox-group label="3.2 Die Zimmertemperatur ist..." [action]="action" [form]="documentForm" name="q3_2"></app-checkbox-group>
                <app-checkbox-group label="3.3 Die Geräuschkulisse ist..." [form]="documentForm" name="q3_3" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="3.4 Die Angebotsvielfalt der Speisen ist..." [form]="documentForm" name="q3_4" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="3.5 Das Frühstück ist..." [form]="documentForm" name="q3_5" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="3.6 Das Mittagessen ist..." [form]="documentForm" name="q3_6" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="3.7 Das Abendessen ist..." [form]="documentForm" name="q3_7" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="3.8 Das Bistro/die Lobby ist/sind..." [form]="documentForm" name="q3_8" [action]="action"></app-checkbox-group>
            </div>

        </div>
        
        <!-- Details Step 4 -->
        <div class="card darken-1" *ngIf="step == 4">

            <div class="card-content">
                <span class="card-title">Weiterempfehlung</span>

                <div class="row headline-row">
                    <div class="col s12 m12 l6"></div>
                    <div class="col s12 l6">
                        <div class="col s2 center-align">Das Beste</div>
                        <div class="col s2 center-align">Sehr gut</div>
                        <div class="col s2 center-align">Gut</div>
                        <div class="col s2 center-align">Akzeptabel</div>
                        <div class="col s2 center-align">Schlecht</div>
                    </div>
                </div>

                <app-checkbox-group label="4.1 Ich werde die Waldkliniken weiterempfehlen als..." [action]="action" [form]="documentForm" name="q4_1"></app-checkbox-group>

                <br>
                <div class="row">
                    <div class="col s12 m7">
                        <b>Alter:</b>
                    </div>
                    <div class="col s12 m5">
                        <div *ngIf="action == 'view'">
                            {{document.content.age}}
                        </div>
                        <div *ngIf="action == 'edit'" class="input-field">
                            <input type=text formControlName="age" id="age" [ngClass]="{ 'invalid': submitted && df.age.errors }">
                            <span *ngIf="submitted && df.age.errors" class="error" data-error="Fehlerhafte Eingabe" data-success="">Fehlerhafte Eingabe</span><br>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12 m7">
                        <b>Geschlecht:</b>
                    </div>
                    <div class="col s12 m5">
                        <div *ngIf="action == 'view'">
                            {{genderString()}}
                        </div>
                        <div *ngIf="action == 'edit'">
                            <select class="browser-default custom-select" formControlName="gender">
                                <option *ngFor="let genderItem of genderData" [value]="genderItem.key">{{genderItem.value}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12">
                        <b>Wenn Sie an die Waldkliniken denken, was ist Ihr größtes Ärgernis?</b>
                    </div>
                    <div class="col s12">
                        <div *ngIf="action == 'view'">
                            {{document.content.comment_negative}}
                        </div>
                        <div *ngIf="action == 'edit'" class="input-field">
                            <input type=text formControlName="comment_negative" id="comment_negative">
                            <label for="comment_negative"></label>
                        </div>
                    </div>

                    <div class="col s12" *ngIf="showComments == true">
                        <img src="/assets/images/img_placeholder.jpg" class="responsive-img z-depth-1" id="comments_negative">
                    </div>
                </div>

                <div class="row">
                    <div class="col s12">
                        <b>Was schätzen Sie an den Waldkliniken am meisten?</b>
                    </div>
                    <div class="col s12">
                        <div *ngIf="action == 'view'">
                            {{document.content.comment_positive}}
                        </div>
                        <div *ngIf="action == 'edit'" class="input-field">
                            <input type=text formControlName="comment_positive" id="comment_positive">
                            <label for="comment_positive"></label>
                        </div>
                    </div>

                    <div class="col s12" *ngIf="showComments == true">
                        <img src="/assets/images/img_placeholder.jpg" class="responsive-img z-depth-1" id="comments_positive">
                    </div>
                </div>

                <!-- Kommentare -->
                <div class="center-align" *ngIf="integration == true">
                    <a (click)="loadComments()" class="btn white grey-text" *ngIf="showComments == false">
                        <i class="mdi mdi-file-image"></i>Kommentare zeigen
                    </a>
                    <a (click)="toggleComments()" class="btn white grey-text" *ngIf="showComments == true">
                        <i class="mdi mdi-file-image"></i>Kommentare verstecken
                    </a>
                </div>

            </div>

        </div>

    </form>

    <!-- Buttons -->
    <div class="card-panel" *ngIf="action == 'edit'">

        <div class="left">
                <div *ngIf="step > 0">
                    <a (click)="previous()" class="btn white grey-text"><i class="mdi mdi-arrow-left left"></i>Zurück</a>
                </div>
        </div>

        <div class="right">
                <div *ngIf="step == 4">
                    <a (click)="save()" class="btn primary-bg"><i class="mdi mdi-content-save-outline left"></i>Speichern</a>
                </div>
                <div *ngIf="step < 4">
                    <a (click)="next()" class="btn primary-bg"><i class="mdi mdi-arrow-right left"></i>Weiter</a>
                </div>
        </div>

        <br clear="all">
    </div>
</div>

<!-- Thank you page -->
<div *ngIf="succeeded == true">

    <h1>Patientenbefragung</h1>

    <div class="card darken-1">
        <div class="card-content">
            Vielen Dank für Ihre Teilnahme!
        </div>
    </div>
    
</div>