<!-- Regular page -->
<div *ngIf="succeeded == false">

    <!-- Buttons -->
    <div class="card-panel" *ngIf="integration == true">

        <div class="left">
            <div *ngIf="action == 'view'">
                <a (click)="setAction('edit')" class="btn white grey-text"><i class="mdi mdi-playlist-edit left"></i>Bearbeiten</a>
                &nbsp;
                <a (click)="lockDocument()" class="btn primary-bg" *ngIf="document.status == 0"><i class="mdi mdi-lock-outline left"></i>Freigeben</a>
            </div>
        
            <div *ngIf="action == 'edit'">
                <a (click)="cancel()" class="btn white grey-text"><i class="mdi mdi-close left"></i>Abbrechen</a>
                &nbsp;
                <a (click)="save()" class="btn primary-bg"><i class="mdi mdi-content-save-outline left"></i>Speichern</a>
                <mat-checkbox class="pad-left-10" [(ngModel)]="autolockDocument">Dokument freigeben?</mat-checkbox>
            </div>
        </div>

        <div class="right">
            <a (click)="unsetDocument()" class="btn primary-bg" *ngIf="action == 'view'"><i class="mdi mdi-close-box-outline"></i></a>
        </div>

        <br clear="all">
    </div>

    <!-- Images -->
    <div class="card-panel" *ngIf="integration == true">

        <div class="center-align">
            <a (click)="loadScans()" class="btn white grey-text" *ngIf="showScans == false">
                <i class="mdi mdi-file-image"></i>Bilder laden
            </a>
            <a (click)="toggleScans()" class="btn white grey-text" *ngIf="showScans == true">
                <i class="mdi mdi-file-image"></i>Bilder verstecken
            </a>
        </div>

        <div class="row" *ngIf="showScans == true">
            <br>
            <div class="col s6">
                <img src="/assets/images/img_placeholder.jpg" class="responsive-img z-depth-1" id="page0">
            </div>
            <div class="col s6">
                <img src="/assets/images/img_placeholder.jpg" class="responsive-img z-depth-1" id="page1">
            </div>
        </div>

    </div>

    <!-- Details -->
    <div class="card darken-1">

        <div class="card-content">
            <span class="card-title">Bogen: {{document.name}} <span *ngIf="integration == true">({{document.class}})</span></span>

            <form [formGroup]="documentForm">

                <div class="row" *ngIf="integration == true">
                    <div class="col s4 m3">
                        <b>Gescannt am:</b>
                    </div>
                    <div class="col s8 m9">
                        {{getTimestamp(document.timestamp) | date:'medium':'':'de'}}
                    </div>
                </div>

                <div class="row no-margin">
                    <div class="col s12 l6">
                        <fieldset>
                            <legend>Einrichtung</legend>

                            <div class="row no-margin">
                                <div class="col s4">
                                    <b>Ebene</b>
                                </div>
                                <div class="col s8">
                                    <div *ngIf="action == 'view'">
                                        {{levelString()}}
                                    </div>
                                    <div *ngIf="action == 'edit'">
                                        <select class="browser-default custom-select" formControlName="level">
                                            <option *ngFor="let levelItem of levelData" [value]="levelItem.key">{{levelItem.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row no-margin">
                                <div class="col s4">
                                    <b>Department</b>
                                </div>
                                <div class="col s8">
                                    <div *ngIf="action == 'view'">
                                        {{departmentString()}}
                                    </div>
                                    <div *ngIf="action == 'edit'">
                                        <select class="browser-default custom-select" formControlName="department">
                                            <option *ngFor="let departmentItem of departmentData" [value]="departmentItem.key">{{departmentItem.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row no-margin">
                                <div class="col s4">
                                    <b>Empfehlung</b>
                                </div>
                                <div class="col s8">
                                    <div *ngIf="action == 'view'">
                                        {{document.content.referrer}}
                                    </div>
                                    <div *ngIf="action == 'edit'" class="input-field">
                                        <input type=text formControlName="referrer" id="referrer">
                                    </div>
                                </div>
                            </div>
                            
                        </fieldset>
                    </div>
                    <div class="col s12 l6">
                        <fieldset>
                            <legend>Aufenthalt</legend>

                            <div class="row no-margin">
                                <div class="col s4">
                                    <b>Monat</b>
                                </div>
                                <div class="col s8">
                                    <div *ngIf="action == 'view'">
                                        {{monthString()}}
                                    </div>
                                    <div *ngIf="action == 'edit'">
                                        <select class="browser-default custom-select" formControlName="month">
                                            <option *ngFor="let monthItem of monthData" [value]="monthItem.key">{{monthItem.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                
                            <div class="row no-margin">
                                <div class="col s4">
                                    <b>Jahr</b>
                                </div>
                                <div class="col s8">
                                    <div *ngIf="action == 'view'">
                                        {{document.content.year}}
                                    </div>
                                    <div *ngIf="action == 'edit'" class="input-field">
                                        <input type=text formControlName="year" id="year">
                                    </div>
                                </div>
                            </div>
                
                            <div class="row no-margin">
                                <div class="col s4">
                                    <b>Tage</b>
                                </div>
                                <div class="col s8">
                                    <div *ngIf="action == 'view'">
                                        {{document.content.days}}
                                    </div>
                                    <div *ngIf="action == 'edit'" class="input-field">
                                        <input type=text formControlName="days" id="days">
                                    </div>
                                </div>
                            </div>
                
                        </fieldset>
                    </div>
                </div>

                <br>
                <div class="divider"></div>
                <br>
                
                <div class="row headline-row">
                    <div class="col s12 m12 l6"></div>
                    <div class="col s12 l6">
                        <div class="col s2 center-align">Das Beste</div>
                        <div class="col s2 center-align">Sehr gut</div>
                        <div class="col s2 center-align">Gut</div>
                        <div class="col s2 center-align">Akzeptabel</div>
                        <div class="col s2 center-align">Schlecht</div>
                    </div>
                </div>

                <app-checkbox-group label="1. Die Freundlichkeit des Personals bei der Patientenaufnahme ist..." [action]="action" [form]="documentForm" name="q1"></app-checkbox-group>
                <app-checkbox-group label="2. Die Berücksichtigung meiner Sorgen und Ängste durch das Pflegepersonal ist..." [action]="action" [form]="documentForm" name="q2"></app-checkbox-group>
                <app-checkbox-group label="3. Die Offenheit der Pflegekräfte für Anregungen und Verbesserungsvorschläge ist..." [form]="documentForm" name="q3" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="4. Die tägliche Unterstützung durch das Pflegepersonal ist..." [form]="documentForm" name="q4" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="5. Die Auskünfte der Pflegekräfte der Station über die Abläufe in den Waldkliniken ist..." [form]="documentForm" name="q5" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="6. Die Freundlichkeit des Pflegepersonals ist..." [form]="documentForm" name="q6" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="7. Die Wahrung meiner Intimsphäre durch das Pflegepersonal ist..." [form]="documentForm" name="q7" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="8. Die Einfühlsamkeit, mit der die Diagnosen mitgeteilt werden, ist..." [form]="documentForm" name="q8" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="9. Der Umgang der Ärzte mit meinen Fragen während der Visite ist..." [form]="documentForm" name="q9" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="10. Die Aufklärung durch die Ärzte über Behandlung und Verlauf meiner Krankheit ist..." [form]="documentForm" name="q10" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="11. Der Erfolg der Behandlung ist bis jetzt..." [form]="documentForm" name="q11" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="12. Die Wirksamkeit, mit der meine Schmerzen gelindert werden, ist..." [form]="documentForm" name="q12" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="13. Meine Erfahrungen mit der Physiotherapie sind..." [form]="documentForm" name="q13" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="14. Meine Erfahrungen mit der Röntgenabteilung sind..." [form]="documentForm" name="q14" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="15. Meine Erfahrungen mit der Funktionsabteilung/EKG sind..." [form]="documentForm" name="q15" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="16. Die hygienischen Verhältnisse sind..." [form]="documentForm" name="q16" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="17. Die Patientenzimmer sind..." [form]="documentForm" name="q17" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="18. Das Essen ist..." [form]="documentForm" name="q18" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="19. Die Angemessenheit der Informationen für meine Angehörigen ist..." [form]="documentForm" name="q19" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="20. Die Vorbereitung auf meine Entlassung aus den Waldkliniken ist..." [form]="documentForm" name="q20" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="21. Die Aufklärung über Komplikationen meiner Krankheit, auf die ich nach meiner Entlassung achten muss, ist..." [form]="documentForm" name="q21" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="22. Ich werde die Waldkliniken weiterempfehlen als..." [form]="documentForm" name="q22" [action]="action"></app-checkbox-group>

                <div class="row headline-row">
                    <div class="col s12 m12 l6"></div>
                    <div class="col s12 l6">
                        <div class="col s2 center-align">Immer</div>
                        <div class="col s2 center-align">Oft</div>
                        <div class="col s2 center-align">Selten</div>
                        <div class="col s2 center-align">Nie</div>
                        <div class="col s2 center-align"></div>
                    </div>
                </div>

                <app-checkbox-group label="23. Das Personal desinfiziert sich die Hände, bevor es mich behandelt." size=4 [form]="documentForm" name="q23" [action]="action"></app-checkbox-group>
                <app-checkbox-group label="24. Das Personal desinfiziert sich die Hände, nachdem es mich behandelt hat." size=4 [form]="documentForm" name="q24" [action]="action"></app-checkbox-group>

                <div class="row headline-row">
                    <div class="col s12 m12 l6"></div>
                    <div class="col s12 l6">
                        <div class="col s2 center-align">Ja</div>
                        <div class="col s2 center-align">Teilweise</div>
                        <div class="col s2 center-align">Nein</div>
                        <div class="col s2 center-align"></div>
                        <div class="col s2 center-align"></div>
                    </div>
                </div>

                <app-checkbox-group label="25. Das Personal zieht sich Handschuhe an, bevor es bei mir einen Verbandswechsel durchführt." size=3 [form]="documentForm" name="q25" [action]="action"></app-checkbox-group>

                <br>
                <div class="divider"></div>
                <br>

                <div class="row">
                    <div class="col s12 m7">
                        <b>Alter:</b>
                    </div>
                    <div class="col s12 m5">
                        <div *ngIf="action == 'view'">
                            {{document.content.age}}
                        </div>
                        <div *ngIf="action == 'edit'" class="input-field">
                            <input type=text formControlName="age" id="age">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12 m7">
                        <b>Geschlecht:</b>
                    </div>
                    <div class="col s12 m5">
                        <div *ngIf="action == 'view'">
                            {{genderString()}}
                        </div>
                        <div *ngIf="action == 'edit'">
                            <select class="browser-default custom-select" formControlName="gender">
                                <option *ngFor="let genderItem of genderData" [value]="genderItem.key">{{genderItem.value}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12">
                        <b>Wenn Sie an die Waldkliniken denken, was ist Ihr größtes Ärgernis?</b>
                    </div>
                    <div class="col s12">
                        <div *ngIf="action == 'view'">
                            {{document.content.comment_negative}}
                        </div>
                        <div *ngIf="action == 'edit'" class="input-field">
                            <input type=text formControlName="comment_negative" id="comment_negative">
                            <label for="comment_negative"></label>
                        </div>
                    </div>

                    <div class="col s12" *ngIf="showComments == true">
                        <img src="/assets/images/img_placeholder.jpg" class="responsive-img z-depth-1" id="comments_negative">
                    </div>
                </div>

                <div class="row">
                    <div class="col s12">
                        <b>Was schätzen Sie an den Waldkliniken am meisten?</b>
                    </div>
                    <div class="col s12">
                        <div *ngIf="action == 'view'">
                            {{document.content.comment_positive}}
                        </div>
                        <div *ngIf="action == 'edit'" class="input-field">
                            <input type=text formControlName="comment_positive" id="comment_positive">
                            <label for="comment_positive"></label>
                        </div>
                    </div>

                    <div class="col s12" *ngIf="showComments == true">
                        <img src="/assets/images/img_placeholder.jpg" class="responsive-img z-depth-1" id="comments_positive">
                    </div>
                </div>

            </form>

            <!-- Kommentare -->
            <div class="center-align" *ngIf="integration == true">
                <a (click)="loadComments()" class="btn white grey-text" *ngIf="showComments == false">
                    <i class="mdi mdi-file-image"></i>Kommentare zeigen
                </a>
                <a (click)="toggleComments()" class="btn white grey-text" *ngIf="showComments == true">
                    <i class="mdi mdi-file-image"></i>Kommentare verstecken
                </a>
            </div>

        </div>

    </div>

    <!-- Buttons -->
    <div class="card-panel" *ngIf="action == 'edit'">

        <div class="left">
        </div>

        <div class="right">
            <div *ngIf="integration == false">
                <a (click)="save()" class="btn primary-bg"><i class="mdi mdi-content-save-outline left"></i>Speichern</a>
            </div>

            <div *ngIf="integration == true">
                <a (click)="deleteDocument()" class="btn red"><i class="mdi mdi-lock-outline left"></i>Löschen</a>
            </div>
        </div>

        <br clear="all">
    </div>
</div>

<!-- Thank you page -->
<div *ngIf="succeeded == true">

    <h1>Patientenbefragung</h1>

    <div class="card darken-1">
        <div class="card-content">
            Vielen Dank für die Teilnahme.
        </div>
    </div>
    
</div>