import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CustomComponent } from '@app/components/core/custom-component';
import { EmployeeService } from '@app/services/employee.service';
import { StorageService } from '@app/services/storage.service';
import { Employee } from '@app/models/employee.model';

declare let M: any;

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.css']
})
export class EmployeeDetailsComponent extends CustomComponent implements OnInit {

  employee = null;
  employees = null;
  employeeForm: FormGroup;

  isLoading = false;
  submitted = false;
  succeeded = false;

  checkinStatusData = [
    {'key': '', 'value': 'Bitte wählen'},
    {'key': 'OK', 'value': 'OK'},
    {'key': 'OFFEN', 'value': 'OFFEN'},
    {'key': 'CHECK', 'value': 'CHECK'}
  ]

  typesData = [
    {'key': '', 'value': 'Bitte wählen'},
    {'key': '1', 'value': 'Intern'},
    {'key': '99', 'value': 'Extern'}
  ]

  recoveryData = [
    {'key': '', 'value': 'Bitte wählen'},
    {'key': '1', 'value': 'Ja'},
    {'key': '0', 'value': 'Nein'}
  ]
    
  constructor(
    protected override storageService: StorageService,
    protected employeeService: EmployeeService,
    protected formBuilder: FormBuilder, 
    protected override router: Router,
    protected changeDetectorRef: ChangeDetectorRef,
    protected datePipe: DatePipe
  ) { 

    super(storageService, router, true);
  }
  
  ngOnInit(): void {
    this.employeeForm = this.formBuilder.group({
      internal_id: new FormControl(null, Validators.required),
      type: new FormControl('', Validators.required),
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      email: new FormControl(null),
      birthdate: new FormControl(null),
      position: new FormControl(null),
      entrydate: new FormControl(null),
      checkin_status: new FormControl(''),
      last_vaccination: new FormControl(null),
      last_infection: new FormControl(null),
      recovered: new FormControl(''),
      validity_date: new FormControl(null)
    });
  }

  get ef() { return this.employeeForm.controls; }

  keyupEmployee(event:any) {
    if (!event.target.value || event.target.value.length <= 2) {
        this.employees = null;     

    } else if (event.target.value.length > 2) {
        this.search(event.target.value);
      }
  }

  onClickSuggestion(event, employee) {
    this.employee = employee;
    this.populateFormGroup(this.employeeForm, this.employee);
    this.changeDetectorRef.detectChanges();
}

  search(q) {
    this.isLoading = true;

    this.employeeService.searchEmployees(q)
    .subscribe(
      (searchContactResponse) => {
        if (searchContactResponse.status == 200) {
          this.employees = searchContactResponse.employees;
        }
      },
      response => {
        M.toast({html: 'Es ist ein Fehler aufgetreten!', classes: ' red lighten-2 white-text'});
      },
      () => {
        this.isLoading = false;
      }
    );    

    this.isLoading = false;

  }

  displayFn(contact) {
    return "";
  }

  edit(employee) {
    this.employee = employee;
    this.populateEmployeeForm();
  }

  reset() {
    this.submitted = false;
    this.employee = null;
    this.employeeForm.reset();
  }

  cancel() {
    this.reset();
  }

  new() {
    this.employee = {} as Employee;
  }

  save() {
    this.submitted = true;

    if (this.employeeForm.invalid) {
      return;
    } else {
      this.populateObject(this.employee, this.employeeForm);

      if (this.employee.guid) {
        this.updateEmployee();
      } else {
        this.createEmployee();
      }

    }
  }

  delete() {
    this.employeeService.deleteEmployee(this.employee.guid)
    .subscribe(
      (genericEmployeeResponse) => {

        if (genericEmployeeResponse.status == 200) {
          M.toast({html: 'Die Daten wurden erfolgreich gelöscht.', classes: 'primary-bg'});
          this.reset();
          this.router.navigateByUrl('/employees');
        } else {
          M.toast({html: 'Beim Löschen ist ein Fehler aufgetreten.', classes: 'red'});
        }

      },
      response => {
        M.toast({html: 'Beim Löschen ist ein Fehler aufgetreten.', classes: 'red'});
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });      
  }

  populateEmployeeForm() {
    this.populateFormGroup(this.employeeForm, this.employee);

    if (this.employee.validity_date) {
      var validity_date = this.datePipe.transform(this.employee.validity_date, 'yyyy-MM-ddTHH:mm');
      this.employeeForm.patchValue({'validity_date': validity_date});
    }
  }
  
  createEmployee() {
    this.employeeService.createEmployee(this.employee)
    .subscribe(
      (genericEmployeeResponse) => {

        if (genericEmployeeResponse.status == 200) {
          M.toast({html: 'Die Daten wurden erfolgreich gespeichert.', classes: 'primary-bg'});
          this.reset();
          this.router.navigateByUrl('/employees');
        } else {
          M.toast({html: 'Beim Speichern ist ein Fehler aufgetreten.', classes: 'red'});
        }

      },
      response => {
        M.toast({html: 'Beim Speichern ist ein Fehler aufgetreten.', classes: 'red'});
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });      
  }

  updateEmployee() {

    this.employeeService.updateEmployee(this.employee)
    .subscribe(
      (genericEmployeeResponse) => {

        if (genericEmployeeResponse.status == 200) {
          M.toast({html: 'Die Daten wurden erfolgreich gespeichert.', classes: 'primary-bg'});
          this.reset();
          this.router.navigateByUrl('/employees');
        } else {
          M.toast({html: 'Beim Speichern ist ein Fehler aufgetreten.', classes: 'red'});
        }

      },
      response => {
        M.toast({html: 'Beim Speichern ist ein Fehler aufgetreten.', classes: 'red'});
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });      

  }

  typeString(employee): string {
    return this.translateValue(this.typesData, employee.type)
  }

}
