<div class="row margin-bottom-10" [formGroup]="form">
    <div class="col s12 m12 l6">
        <b>{{label}}</b> <i class="mdi mdi-information-outline" matTooltip="{{tooltip}}" matTooltipClass="tooltip" *ngIf="tooltip != null"></i>
        <span *ngIf="description != null"><br>{{description}}</span>
    </div>

    <div class="col s12 l6">
        <div class="col s2 center-align" *ngIf="size > 0">
            <label>
                <input type="radio" [attr.disabled]="action=='view' ? 'disabled' : null" formControlName="{{name}}" value="1" class="with-gap">
                <span></span>
            </label>
        </div>
        <div class="col s2 center-align" *ngIf="size > 1">
            <label>
                <input type="radio" [attr.disabled]="action=='view' ? 'disabled' : null" formControlName="{{name}}" value="2" class="with-gap">
                <span></span>
            </label>
        </div>
        <div class="col s2 center-align" *ngIf="size > 2">
            <label>
                <input type="radio" [attr.disabled]="action=='view' ? 'disabled' : null" formControlName="{{name}}" value="3" class="with-gap">
                <span></span>
            </label>
        </div>
        <div class="col s2 center-align" *ngIf="size > 3">
            <label>
                <input type="radio" [attr.disabled]="action=='view' ? 'disabled' : null" formControlName="{{name}}" value="4" class="with-gap">
                <span></span>
            </label>
        </div>
        <div class="col s2 center-align" *ngIf="size > 4">
            <label>
                <input type="radio" [attr.disabled]="action=='view' ? 'disabled' : null" formControlName="{{name}}" value="5" class="with-gap">
                <span></span>
            </label>
        </div>
    
        <div class="col s2 center-align grey-text" *ngIf="action=='edit'">
            <!--i class="mdi mdi-18px mdi-play-circle-outline left" (click)="speakLabel(label)" *ngIf="showPlayButton == true"></i-->
            <i class="mdi mdi-18px mdi-close-circle-outline reset-radio-button" (click)="resetRadios(name)" *ngIf="showResetButton == true"></i>
        </div>
        <!--div class="col s2 center-align" *ngIf="showResetButton == false">
            <label>
                <input type="radio" [attr.disabled]="action=='view' ? 'disabled' : null" formControlName="{{name}}" value="" class="with-gap">
                <span></span>
            </label>
        </div-->
    </div>

    <br class="hide-on-large-only">
    <br class="hide-on-large-only">
    
    <!--div class="col s1 center-align" *ngIf="size > 0">
        <label>
            <input type="checkbox" [checked]="value == 1" [attr.disabled]="action=='view' ? 'disabled' : null" formControlName="{{name}}">
            <span></span>
        </label>
    </div>
    <div class="col s1 center-align" *ngIf="size > 1">
        <label>
            <input type="checkbox" [checked]="value == 2" [attr.disabled]="action=='view' ? 'disabled' : null" formControlName="{{name}}">
            <span></span>
        </label>
    </div>
    <div class="col s1 center-align" *ngIf="size > 2">
        <label>
            <input type="checkbox" [checked]="value == 3" [attr.disabled]="action=='view' ? 'disabled' : null" formControlName="{{name}}">
            <span></span>
        </label>
    </div>
    <div class="col s1 center-align" *ngIf="size > 3">
        <label>
            <input type="checkbox" [checked]="value == 4" [attr.disabled]="action=='view' ? 'disabled' : null" formControlName="{{name}}">
            <span></span>
        </label>
    </div>
    <div class="col s1 center-align" *ngIf="size > 4">
        <label>
            <input type="checkbox" [checked]="value == 5" [attr.disabled]="action=='view' ? 'disabled' : null" formControlName="{{name}}">
            <span></span>
        </label>
    </div-->
</div>
