import { Component, OnInit } from '@angular/core';
import { ReportService } from '@app/services/report.service';
import { Subscription } from 'rxjs';


import { GoogleChartsDashboardInterface } from 'ng2-google-charts';
import { GoogleChartsControlInterface } from 'ng2-google-charts';
import { GoogleChartInterface } from 'ng2-google-charts';
import { StorageService } from '@app/services/storage.service';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { DocumentService } from '@app/services/document.service';
import { StatsPageComponent } from '../stats-page/stats-page.component';

declare var google: any;

@Component({
  selector: 'app-stats-zzpage',
  templateUrl: './stats-zzpage.component.html',
  styleUrls: ['./stats-zzpage.component.css']
})
export class StatsZZPageComponent extends StatsPageComponent implements OnInit {

  document = null;
  selectedLevel = null;
    
  constructor(
    protected override reportService: ReportService,
    protected override storageService: StorageService,
    protected override documentService: DocumentService,
    protected override router: Router
  ) { 
    super(reportService, storageService, documentService, router);
  }

  override ngOnInit(): void {
   super.ngOnInit();
  }

  documentChanged(event) {
    if (event == null) {
      this.document = null;
    } else {
      this.loadDocument(event);
    }
  }

  levelChanged(selectedLevel) {
    if (selectedLevel == "") {
      this.selectedLevel = null;
    } else {
      this.selectedLevel = selectedLevel;
    }

    this.loadReports();
  }

  loadDocument(documentContent) {
    this.documentService.getDocument(documentContent.document_guid)
    .subscribe(
      (getDocumentResponse) => {
        if (getDocumentResponse.document != null) {
          this.document = getDocumentResponse.document;
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  override loadReports(): void {
    var fromDate = this.getDateTime(this.date);
    var toDate = this.getDateTime(this.todate);

    var filter = {
//      'user_guid': this.user.guid,
      'from': fromDate,
      'to': toDate,
      'level': this.selectedLevel
    };
    this.reportService.getZZReports(filter)
    .subscribe(
      (getZZReportsResponse) => {
        if (getZZReportsResponse.status == 200) {
          this.reportData = getZZReportsResponse.data;
          this.reportService.reportDataChanged.emit(this.reportData);
        }

      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

}
