<div class="container">
    <div class="section">
    
        <h1 class="bot-20 sec-tit">Ausgeliehene Objekte</h1>

        <div class="row card-panel toolbar">
            <div class="col s9">
                <!--a [matMenuTriggerFor]="menu" class="waves-effect waves-light btn white grey-text"><i class="mdi mdi-dots-horizontal right"></i>Neu</a>
                <mat-menu #menu="matMenu" class="toolbar">
                    <button mat-menu-item (click)="createAsset('key')">Schlüssel</button>
                </mat-menu>

                &nbsp;
                -->

                <button mat-button [matMenuTriggerFor]="menu" class="toolbar-btn" *ngIf="hasPermissions('assets.edit');"><i class="mdi mdi-cogs hide-on-small-only"></i> Daten</button>
                <mat-menu #menu="matMenu" class="toolbar">
                    <button mat-menu-item routerLink="/assets"><i class="mdi mdi-key hide-on-small-only"></i> Objekte</button>
                    <button mat-menu-item routerLink="/companies"><i class="mdi mdi-office-building hide-on-small-only"></i> Unternehmen</button>
                </mat-menu>

                <button routerLink="/documents/upload" class="toolbar-btn" mat-button class="toolbar-btn"><i class="mdi mdi-qrcode-scan hide-on-small-only"></i> Scan</button>
            </div>
            <div class="col s3 right-align">
                <button (click)="loadAssetLogs()" mat-button class="toolbar-btn">
                    <i class="mdi mdi-reload"></i>
                </button>
            </div>
        </div>

        <div class="card-panel" *ngIf="loading == true">
            <div class="center-align">
                <br><br>
                <div id="preloader"></div>
                <br>
                <div class="center-align">Bitte warten...</div>
            </div> 
        </div>

        <app-assetloglist [assetlogs]="assetlogs" (assetlogChangeEvent)="loadAssetLogs()" *ngIf="loading == false"></app-assetloglist>

        <!--div class="card-panel right-align">
            <button class="btn white grey-text" routerLink="/assets">Objekte</button>
            &nbsp;
            <button class="btn white grey-text" routerLink="/companies">Unternehmen</button>
        </div-->

    </div>
</div>


