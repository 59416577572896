import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { AssetService } from '@app/services/asset.service';
import { StorageService } from '@app/services/storage.service';

declare let M: any;
declare let cordova: any;

@Component({
  selector: 'app-assetlist',
  templateUrl: './assetlist.component.html',
  styleUrls: ['./assetlist.component.css']
})
export class AssetlistComponent extends CustomComponent implements OnInit {

  @Input() assets;
  assetsForm: FormGroup;

  constructor(
    protected override storageService: StorageService,
    protected assetService: AssetService,
    protected formBuilder: FormBuilder, 
    protected override router: Router
  ) { 

    super(storageService, router, true);
  }

  ngOnInit(): void {
    this.assetsForm = this.formBuilder.group({
      selectedGUIDs: this.formBuilder.array([])
    });
  }

  selectAll(event) {
    this.assets.forEach(asset => {
      asset.selected = event.target.checked;
    });
  }

  downloadPDF() {
    var guids: string[] = [];
    this.assets.forEach(asset => {
      if (asset.selected) {
        guids.push(asset.guid);
      }
    });

    if (guids.length > 0) {
      this.assetService.downloadAssetsPDF(guids);

    } else {
      M.toast({html: 'Bitte mindestens ein Objekt auswählen!', classes: 'primary-bg red white-text'});
    }

  }

  getExpiration(asset) {
    var days = Math.floor(asset.expiration_min / (24*60));
    var hours = Math.floor(asset.expiration_min / 60);
    var minutes = asset.expiration_min % 60;

    var exp = "";
    if (days > 0) {
      exp += days + "T ";
    }
    if (hours > 0) {
      exp += hours + "h ";
    }
    if (minutes > 0) {
      exp += minutes + "m ";
    }

    return exp;
  }

}
