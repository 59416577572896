import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { DataService } from '@app/services/data.service';
import { EmployeeService } from '@app/services/employee.service';
import { StorageService } from '@app/services/storage.service';

declare let cordova: any;
declare let M: any;

@Component({
  selector: 'app-employee-checkin-supported',
  templateUrl: './employee-checkin-supported.component.html',
  styleUrls: ['./employee-checkin-supported.component.css']
})
export class EmployeeCheckinSupportedComponent extends CustomComponent implements OnInit {

  employee = null;
  employees = null;
  employeeCheckinForm: FormGroup;

  isLoading = false;
  submitted = false;
  scannerOpen = "0";

  constructor(
    protected override storageService: StorageService,
    protected override router: Router,
    protected route: ActivatedRoute,
    protected employeeService: EmployeeService,
    protected dataService: DataService,
    protected formBuilder: FormBuilder, 
    protected changeDetectorRef: ChangeDetectorRef
  ) { 

    super(storageService, router, false);
  }

  get f() { return this.employeeCheckinForm.controls; }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {
      this.scannerOpen = queryParams.get("scanner");

      if (this.scannerOpen == "1") {
        this.scan();
      }
    });

    this.employeeCheckinForm = this.formBuilder.group({
      guid: new FormControl(null, [Validators.required]),
      checked: new FormControl(null, [Validators.requiredTrue])
    });

    //this.loadEmployeeById('3824');
  }

  scan(): void {

    cordova.plugins.barcodeScanner.scan(
      result => {

        try {
          var scanData = JSON.parse(result.text);

          if (scanData.class == "employee_sec") {
            this.decryptData(scanData.data);
        
          } else if (scanData.class == "employee") {
            this.handleScan(scanData);
          }

        } catch (e) {
          M.toast({html: 'Das Scannen ist fehlgeschlagen!', classes: ' red lighten-2 white-text'});
        }
      },
      err => {
        M.toast({html: 'Das Scannen ist fehlgeschlagen!', classes: ' red lighten-2 white-text'});
      },
      {
        preferFrontCamera : false, // iOS and Android
        showFlipCameraButton : true, // iOS and Android
        showTorchButton : true, // iOS and Android
        torchOn: false, // Android, launch with the torch switched on (if available)
        saveHistory: true, // Android, save scan history (default false)
        prompt : "QR-Code", // Android
        resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        formats : "QR_CODE,PDF_417,AZTEC", // default: all but PDF_417 and RSS_EXPANDED
        //orientation : "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
        disableAnimations : true, // iOS
        disableSuccessBeep: false // iOS and Android
      }
    );

  }

  decryptData(data): void {
    this.dataService.decryptData(data)
      .subscribe(
        (encryptDataResponse) => {

          if (encryptDataResponse.status == 200) {
            this.handleScan(encryptDataResponse.data);

          } else {
            console.log(encryptDataResponse);
            M.toast({html: 'Beim Entschlüsseln der Daten ist ein Fehler aufgetreten.', classes: 'red'});
          }
  
        },
        response => {
          console.log(response);
          M.toast({html: 'Beim Entschlüsseln der Daten ist ein Fehler aufgetreten.', classes: 'red'});
        },
        () => {
            //console.log("The POST observable is now completed.");
        });
  }

  handleScan(data): void {
    if (data.email) {
      var employeeId = data.email.substring(0, data.email.lastIndexOf("@"));

      if (employeeId) {

        this.employeeService.validateEmployeeById(employeeId)
        .subscribe(
          (genericEmployeeResponse) => {
            if (genericEmployeeResponse.status == 200) {
              this.setEmployee(genericEmployeeResponse.employee);

            } else {
              M.toast({html: 'Beim Abrufen der Daten ist ein Fehler aufgetreten.', classes: 'red'});
            }
    
          },
          response => {
            M.toast({html: 'Beim Abrufen der Daten ist ein Fehler aufgetreten.', classes: 'red'});
            console.log(response);
          },
          () => {
              //console.log("The POST observable is now completed.");
          });

      }

    }

  }

  setEmployee(employee): void {
    this.employee = employee;
    var guid = null;

    if (this.employee != null) {
      guid = this.employee.guid;
    }

    this.employeeCheckinForm.controls['guid'].patchValue(guid);
    this.changeDetectorRef.detectChanges(); 
  }

  loadEmployeeById(id): void {
    this.employeeService.validateEmployeeById(id)
    .subscribe(
      (genericEmployeeResponse) => {
        if (genericEmployeeResponse.status == 200) {
          this.employee = genericEmployeeResponse.employee;
          this.employeeCheckinForm.controls['guid'].patchValue(this.employee.guid);

          this.changeDetectorRef.detectChanges();

        } else {
          M.toast({html: 'Beim Abrufen der Daten ist ein Fehler aufgetreten.', classes: 'red'});
        }

      },
      response => {
        M.toast({html: 'Beim Abrufen der Daten ist ein Fehler aufgetreten.', classes: 'red'});
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  checkin(): void {
    this.employeeService.checkin(this.employeeCheckinForm.value)
    .subscribe(
      (stausResponse) => {
        if (stausResponse.status == 200) {
            M.toast({html: 'Die Daten wurden erfolgreich gespeichert.', classes: 'primary-bg'});
            this.router.navigateByUrl('/checkin');

        } else {
          M.toast({html: 'Beim Speichern der Daten ist ein Fehler aufgetreten.', classes: 'red'});
        }

      },
      response => {
        M.toast({html: 'Beim Speichern der Daten ist ein Fehler aufgetreten.', classes: 'red'});
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });    
  }

  save() {
    this.submitted = true;

    if (this.employeeCheckinForm.invalid) {
        return;

    } else {
      this.checkin();
    }
  }

  keyupContact(event:any) {
    if (!event.target.value || event.target.value.length <= 2) {
        this.employees = [];     

    } else if (event.target.value.length > 2) {
        this.search(event.target.value);
    }
  }

  onClickSuggestion(event, employee) {
    this.setEmployee(employee);
  }

  search(q) {
    this.isLoading = true;

    this.employeeService.searchEmployees(q)
    .subscribe(
      (searchEmployeesResponse) => {
        if (searchEmployeesResponse.status == 200) {
          this.employees = searchEmployeesResponse.employees;
        }
      },
      response => {
        M.toast({html: 'Es ist ein Fehler aufgetreten!', classes: ' red lighten-2 white-text'});
      },
      () => {
        this.isLoading = false;
        //console.log("The POST observable is now completed.");
      }
    );    

  }

  displayFn(contact) {
    return "";
  }


}
