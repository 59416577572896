import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { Employee } from '@app/models/employee.model';
import { StorageService } from '@app/services/storage.service';

@Component({
  selector: 'app-employee-checkin-status',
  templateUrl: './employee-checkin-status.component.html',
  styleUrls: ['./employee-checkin-status.component.css']
})
export class EmployeeCheckinStatusComponent extends CustomComponent implements OnInit {

  @Input() data: Employee;

  constructor(
    protected override storageService: StorageService,
    protected override router: Router
  ) { 

    super(storageService, router, false);
  }

  ngOnInit(): void {
  }

}
