<!-- Regular page -->
<div *ngIf="succeeded == false">

    <!-- Buttons -->
    <div class="card-panel" *ngIf="integration == true">

        <div class="left">
        </div>

        <div class="right">
            <a (click)="unsetDocument()" class="btn primary-bg"><i class="mdi mdi-close-box-outline"></i></a>
        </div>

        <br clear="all">
    </div>

    <!-- Details -->
    <div class="card darken-1">
        <div class="card-content">
            <!--span class="card-title">Bogen: {{document.name}} ({{document.class}})</span-->

            <form [formGroup]="documentForm">
                
                <div class="row no-margin">
                    <div class="col s12 l6">

                        <div class="row no-margin">
                            <div class="col s12 m4">
                                <b>Datum *</b>
                            </div>
                            <div class="col s12 m8">
                                <div *ngIf="action == 'view'">
                                    {{getTimestamp(document.content.date) | date:'mediumDate':'':'de'}}
                                </div>
                                <div *ngIf="action == 'edit'" #actionChanged>
                                    <input type="text" formControlName="date" id="date" class="datepicker" [ngClass]="{ 'invalid': submitted && f.date.errors }">
                                    <label for="date"></label>
                                    <span *ngIf="submitted && f.date.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                                </div>

                            </div>
                        </div>

                        <div class="row no-margin">
                            <div class="col s4">
                                <b>Ebene *</b>
                            </div>
                            <div class="col s8">
                                <div *ngIf="action == 'view'">
                                    {{levelString()}}
                                </div>
                                <div *ngIf="action == 'edit'">
                                    <select class="browser-default custom-select" formControlName="level">
                                        <option *ngFor="let levelItem of levelData" [value]="levelItem.key">{{levelItem.value}}</option>
                                    </select>
                                    <span *ngIf="submitted && f.level.errors" class="helper-text red-text">Fehlerhafte Eingabe</span>
                                </div>
                            </div>
                        </div>

                        <div class="row no-margin">
                            <div class="col s4">
                                <b>Schicht *</b>
                            </div>
                            <div class="col s8">
                                <div *ngIf="action == 'view'">
                                    {{shiftString()}}
                                </div>
                                <div *ngIf="action == 'edit'">
                                    <select class="browser-default custom-select" formControlName="shift">
                                        <option *ngFor="let shiftItem of shiftData" [value]="shiftItem.key">{{shiftItem.value}}</option>
                                    </select>
                                    <span *ngIf="submitted && f.shift.errors" class="helper-text red-text">Fehlerhafte Eingabe</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <br>
                <div class="divider"></div>
                <br>
                
                <div class="row headline-row">
                    <div class="col s12 m12 l6"></div>
                    <div class="col s12 l6">
                        <div class="col s2 center-align" style="background-color: #00ff00; color: #fff;">Grün</div>
                        <div class="col s2 center-align" style="background-color: #ff8c00; color: #fff;">Orange</div>
                        <div class="col s2 center-align" style="background-color: #ff0000; color: #fff;">Rot</div>
                        </div>
                </div>

                <app-checkbox-group [label]="formData[0].title" [tooltip]="formData[0].description" 
                    size=3 [action]="action" [form]="documentForm" [showResetButton]="false" name="q1"></app-checkbox-group>
                <app-checkbox-group [label]="formData[1].title" [tooltip]="formData[1].description"
                    size=3 [action]="action" [form]="documentForm" [showResetButton]="false" name="q2"></app-checkbox-group>
                <app-checkbox-group [label]="formData[2].title" [tooltip]="formData[2].description"
                    size=3 [action]="action" [form]="documentForm" [showResetButton]="false" name="q3"></app-checkbox-group>
                <app-checkbox-group [label]="formData[3].title" [tooltip]="formData[3].description"
                    size=3 [action]="action" [form]="documentForm" [showResetButton]="false" name="q4" ></app-checkbox-group>
                <app-checkbox-group [label]="formData[4].title" [tooltip]="formData[4].description"
                    size=3 [action]="action" [form]="documentForm" [showResetButton]="false" name="q5"></app-checkbox-group>
                <app-checkbox-group [label]="formData[5].title" [tooltip]="formData[5].description"
                    size=3 [action]="action" [form]="documentForm" [showResetButton]="false" name="q6"></app-checkbox-group>
                <app-checkbox-group [label]="formData[6].title" [tooltip]="formData[6].description"
                    size=3 [action]="action" [form]="documentForm" [showResetButton]="false" name="q7"></app-checkbox-group>

                <br>
                <div class="divider"></div>
                <br>

                <div class="row">
                    <div class="col s12">
                        <b>Was war verbesserungswürdig?</b>
                    </div>
                    <div class="col s12">
                        <div *ngIf="action == 'view'">
                            {{document.content.comment_negative}}
                        </div>
                        <div *ngIf="action == 'edit'" class="input-field">
                            <textarea matInput formControlName="comment_negative" id="comment_negative"></textarea>
                            <label for="comment_negative"></label>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12">
                        <b>Was war positiv? *</b>
                    </div>
                    <div class="col s12">
                        <div *ngIf="action == 'view'">
                            {{document.content.comment_positive}}
                        </div>

                        <div *ngIf="action == 'edit'" class="input-field">
                            <textarea formControlName="comment_positive" id="comment_positive"></textarea>
                            <label for="comment_positive"></label>
                        </div>
                        <span *ngIf="submitted && f.comment_positive.errors" class="helper-text red-text">Fehlerhafte Eingabe</span>
                    </div>
                </div>

                <div class="row" *ngIf="action == 'edit'">
                    <div class="col s12">
                        Alle mit einem * markierten Felder sind Pflichtfelder und müssen ausgefüllt werden!
                    </div>
                </div>

            </form>

        </div>

    </div>

    <!-- Buttons -->
    <div class="card-panel" *ngIf="integration == false">

        <div class="left">
        </div>

        <div class="right">
            <div *ngIf="action == 'edit'">
                <a (click)="save()" class="btn primary-bg"><i class="mdi mdi-content-save-outline left"></i>Speichern</a>
            </div>
        </div>

        <br clear="all">
    </div>

</div>

<!-- Thank you page -->
<div *ngIf="succeeded == true">

    <h1>Zufriedenheitsabfrage</h1>

    <div class="card darken-1">
        <div class="card-content">
            Vielen Dank für Ihre Teilnahme!<br>
            Ihre Daten wurden übernommen.
        </div>
    </div>
    
</div>

