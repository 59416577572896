<div class="container">
    <div class="section">

        <div class="row" *ngIf="document != null">
            <div class="col s12">
                <app-documentdetails-wke-universal [document]="document" [integration]="false" *ngIf="document.class == 'survey_universal'"></app-documentdetails-wke-universal>
            </div>
        </div>

    </div>
</div>


