import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactlist',
  templateUrl: './contactlist.component.html',
  styleUrls: ['./contactlist.component.css']
})
export class ContactlistComponent implements OnInit {

  @Input() contacts;

  constructor() { }

  ngOnInit(): void {
  }

}
