<div *ngIf="employee == null">
    <h1 class="bot-20 sec-tit">Mitarbeiter</h1>

    <div class="card-panel toolbar" *ngIf="hasPermissions('employees.admin')">
        <div class="col s12">
            <button mat-button class="toolbar-btn" (click)="new()">
                <i class="mdi mdi-account hide-on-small-only"></i> Neu
            </button>
        </div>
    </div>

    <div class="card-panel">
        <div class="title"><h2>Suche</h2></div>
    
        <div class="row">
            <div class="col s12">
                Sie möchten die Mitarbeiterdaten ändern? Suchen Sie zunächst den/die Mitarbeiter/in und klicken den passenden Treffer aus der Liste an.
            </div>
        </div>
    
        <div class="row">
            <div class="col s12 m3">
                Suchbegriff (Name, Personalnummer)
            </div>
    
            <div class="col s12 m9">
                <input matInput placeholder="" aria-label="" [matAutocomplete]="auto" (keyup)="keyupEmployee($event)">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                <!--ng-container *ngIf="!isLoading">
                    <mat-option *ngFor="let employee of employees" [value]="employee.guid"
                        (onSelectionChange)="onClickSuggestion($event, employee)">
                    <span>{{employee.firstname}} {{employee.lastname}}<span *ngIf="employee.internal_id" class="grey-text"> ({{employee.internal_id}})</span></span>
                    </mat-option>
                </ng-container-->
                </mat-autocomplete>
            </div>
        </div>
    </div>
    
    <div class="card-panel">
        <div class="title"><h2>Trefferliste</h2></div>
    
        <div *ngIf="employees == null">
            Bitte einen Suchbegriff eingeben!<br>
            (Mindestens 3 Zeichen)
        </div>
    
        <div *ngIf="employees != null && employees.length == 0">
            Es wurden keine Daten gefunden.
        </div>
    
        <div *ngIf="employees != null && employees.length > 0">
    
            Gefundene Datensätze: {{employees.length}}<br><br>
    
            <table id="employeesTable" class="highlight slim">
                <thead>
                    <tr>
                        <th>Typ</th>
                        <th>Personalnummer</th>
                        <th>Vorname</th>
                        <th>Nachname</th>
                        <th>E-Mail</th>
                        <th></th>
                    </tr>
                <tbody>
                    <ng-container *ngFor="let employee of employees">
                    <tr (click)="edit(employee)">
                        <td valign="top">
                            {{typeString(employee)}}
                        </td>
                        <td valign="top">
                            {{employee.internal_id}}
                        </td>
                        <td valign="top">
                            {{employee.firstname}}
                        </td>
                        <td valign="top">
                            {{employee.lastname}}
                        </td>
                        <td valign="top">
                            {{employee.email}}
                        </td>
                        <td valign="top" class="right-align">
                            <a (click)="edit(employee)" class="btn white grey-text"><i class="mdi mdi-square-edit-outline"></i></a>
                        </td>
                    </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>

</div>

<div *ngIf="employee != null">
    <h1 class="bot-20 sec-tit" *ngIf="employee.guid == null">Neuer Datensatz</h1>
    <h1 class="bot-20 sec-tit" *ngIf="employee.guid != null">{{employee.firstname}} {{employee.lastname}} ({{employee.internal_id}})</h1>
    <form [formGroup]="employeeForm">

        <mat-tab-group dynamicHeight>
            
            <mat-tab>
                <ng-template mat-tab-label>
                    <span>Persönliche Daten</span>
                </ng-template>

                <div class="card darken-1">
                    <div class="card-content">

                        <div class="row">
                            <div class="col s12 m3">
                                <b>Typ</b>
                            </div>
                            <div class="col s12 m9">
                                <mat-select formControlName="type" [ngClass]="{ 'invalid': submitted && ef.type.errors }">
                                    <mat-option *ngFor="let type of typesData" [value]="type.key">
                                        {{type.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="submitted && ef.type.hasError('required')">
                                    Fehlerhafte Eingabe
                                </mat-error>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col s12 m3">
                                <b>Personalnummer</b>
                            </div>
                            <div class="col s12 m9">
                                <input matInput formControlName="internal_id" autocomplete="false" [ngClass]="{ 'invalid': submitted && ef.internal_id.errors }">
                                <span *ngIf="submitted && ef.internal_id.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col s12 m3">
                                <b>Vorname</b>
                            </div>
                            <div class="col s12 m9">
                                <input matInput formControlName="firstname" autocomplete="false" [ngClass]="{ 'invalid': submitted && ef.firstname.errors }">
                                <span *ngIf="submitted && ef.firstname.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col s12 m3">
                                <b>Nachname</b>
                            </div>
                            <div class="col s12 m9">
                                <input matInput formControlName="lastname" autocomplete="false" [ngClass]="{ 'invalid': submitted && ef.lastname.errors }">
                                <span *ngIf="submitted && ef.lastname.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col s12 m3">
                                <b>E-Mail</b>
                            </div>
                            <div class="col s12 m9">
                                <input matInput formControlName="email" email autocomplete="false" [ngClass]="{ 'invalid': submitted && ef.email.errors }">
                                <span *ngIf="submitted && ef.email.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col s12 m3">
                                <b>Geburtstag</b>
                            </div>
                            <div class="col s12 m9">
                                <input matInput formControlName="birthdate" type="date" autocomplete="false" [ngClass]="{ 'invalid': submitted && ef.birthdate.errors }">
                                <span *ngIf="submitted && ef.birthdate.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                            </div>
                        </div>
        
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Unternehmensdaten">
                <div class="card darken-1">
                    <div class="card-content">
        
                        <div class="row">
                            <div class="col s12 m3">
                                <b>Position</b>
                            </div>
                            <div class="col s12 m9">
                                <input matInput formControlName="position" autocomplete="false" [ngClass]="{ 'invalid': submitted && ef.position.errors }">
                                <span *ngIf="submitted && ef.position.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col s12 m3">
                                <b>Eintrittsdatum</b>
                            </div>
                            <div class="col s12 m9">
                                <input matInput formControlName="entrydate" type="date" autocomplete="false" [ngClass]="{ 'invalid': submitted && ef.entrydate.errors }">
                                <span *ngIf="submitted && ef.entrydate.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                            </div>
                        </div>
        
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Medizinische Daten">
                <div class="card darken-1">
                    <div class="card-content">
        
                        <div class="row">
                            <div class="col s12 m3">
                                <b>Check-In Status</b>
                            </div>
                            <div class="col s12 m9">
                                <mat-select formControlName="checkin_status" [ngClass]="{ 'invalid': submitted && ef.checkin_status.errors }">
                                    <mat-option *ngFor="let checkin of checkinStatusData" [value]="checkin.key">
                                        {{checkin.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="submitted && ef.checkin_status.hasError('required')">
                                    Fehlerhafte Eingabe
                                </mat-error>
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col s12 m3">
                                <b>Letzte Impfung</b>
                            </div>
                            <div class="col s12 m9">
                                <input matInput formControlName="last_vaccination" type="date" autocomplete="false" [ngClass]="{ 'invalid': submitted && ef.last_vaccination.errors }">
                                <span *ngIf="submitted && ef.last_vaccination.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col s12 m3">
                                <b>Letzte Infektion</b>
                            </div>
                            <div class="col s12 m9">
                                <input matInput formControlName="last_infection" type="date" autocomplete="false" [ngClass]="{ 'invalid': submitted && ef.last_infection.errors }">
                                <span *ngIf="submitted && ef.last_infection.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col s12 m3">
                                <b>Genesen</b>
                            </div>
                            <div class="col s12 m9">
                                <mat-select formControlName="recovered">
                                    <mat-option *ngFor="let recovery of recoveryData" [value]="recovery.key" [ngClass]="{ 'invalid': submitted && ef.recovered.errors }">
                                        {{recovery.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="submitted && ef.recovered.hasError('required')">
                                    Fehlerhafte Eingabe
                                </mat-error>
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col s12 m3">
                                <b>Check-In gültig bis</b>
                            </div>
                            <div class="col s12 m9">
                                <input matInput formControlName="validity_date" type="datetime-local" autocomplete="false" [ngClass]="{ 'invalid': submitted && ef.validity_date.errors }">
                            </div>
                            <span *ngIf="submitted && ef.validity_date.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                        </div>
        
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Sonstiges">
                <div class="card darken-1">
                    <div class="card-content">
        
                        <div class="row">
                            <div class="col s12 m3">
                                <b>Datensatz löschen</b>
                            </div>
                            <div class="col s12 m9">
                                <a class="btn red" *ngIf="employee.guid" (click)="delete()"><i class="mdi mdi-delete-forever left"></i>Löschen</a>
                                <div *ngIf="employee.guid == null">Kein Löschen möglich</div>
                            </div>
                        </div>
        
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>

        <div class="card darken-1">
            <div class="card-content">

                <div class="right-align">
                    <button class="waves-effect waves-light btn-large white grey-text" (click)="cancel()">Abbrechen</button>
                    &nbsp;
                    <a class="waves-effect waves-light btn-large primary-bg" (click)="save()"><i class="mdi mdi-arrow-right-bold-box-outline left"></i>Speichern</a>
                </div>

            </div>
        </div>

    </form>

</div>

