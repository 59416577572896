import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { AssetService } from '@app/services/asset.service';
import { ContactService } from '@app/services/contact.service';
import { StorageService } from '@app/services/storage.service';
import { DataService } from '@app/services/data.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignatureComponent } from '@app/components/core/signature/signature.component';
import { Contact } from '@app/models/contact.model';

declare let cordova: any;
declare let M: any;

@Component({
  selector: 'app-asset-issue',
  templateUrl: './asset-issue.component.html',
  styleUrls: ['./asset-issue.component.css']
})
export class AssetIssueComponent extends CustomComponent implements OnInit {

  asset = null;
  contact = null;
  signatureData = null;
  contacts = null;
  storageContacts = {};

  isLoading = false;
  scannerOpen = "0";

  constructor(
    protected override storageService: StorageService,
    protected contactService: ContactService,
    protected assetService: AssetService,
    protected dataService: DataService,
    protected override router: Router,
    protected route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog
  ) { 

    super(storageService, router, true);
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {
      this.scannerOpen = queryParams.get("scanner");

      if (this.scannerOpen == "1") {
        // Generalschlüssel
        //this.loadAsset("d60fc80b-d348-11eb-bfd6-0242ac110004");
        // Hol+Bringedienst 1
        //this.loadAsset("d61018af-d348-11eb-bfd6-0242ac110004");
        
        //this.loadContact("6029eebb-b490-11eb-9807-0242ac110003");
        this.scan(null);
      }
    });

    this.storageContacts = this.storageService.getValue('storageContacts');
    if (this.storageContacts == null) {
      this.storageContacts = {};
    }
  }

  loadAsset(guid) {
    this.assetService.getAsset(guid)
    .subscribe(
      (getAssetResponse) => {
        if (getAssetResponse.status == 200 && getAssetResponse.asset) {
          this.asset = getAssetResponse.asset;
          this.ref.detectChanges();
        } else {
          M.toast({html: 'Objekt nicht gefunden!', classes: ' red lighten-2 white-text'});
        }
      },
      response => {
        M.toast({html: 'Es ist ein Fehler aufgetreten!', classes: ' red lighten-2 white-text'});
      },
      () => {
          //console.log("The POST observable is now completed.");
      }
    );
  }

  loadContact(guid) {
    this.contactService.getContact(guid)
    .subscribe(
      (getContactResponse) => {
        if (getContactResponse.status == 200 && getContactResponse.contact) {
          this.contact = getContactResponse.contact;
          this.ref.detectChanges();
        } else {
          M.toast({html: 'Kontakt nicht gefunden!', classes: ' red lighten-2 white-text'});
        }
      },
      response => {
        M.toast({html: 'Es ist ein Fehler aufgetreten!', classes: ' red lighten-2 white-text'});
      },
      () => {
          //console.log("The POST observable is now completed.");
      }
    );    
  }

  scan(assetClass): void {

    cordova.plugins.barcodeScanner.scan(
      result => {

        try {
          var scanData = JSON.parse(result.text);

          if (scanData.class == "key" || scanData.class == "keygroup") {
            this.loadAsset(scanData.guid);

          } else if (scanData.class == "contact") {
            this.loadContact(scanData.guid);
          
          } else if (scanData.class == "employee_sec") {
            this.decryptData(scanData.data);
  
          } else if (scanData.class == "employee") {
            this.handleScan(scanData);
          }

        } catch (e) {
          M.toast({html: 'Das Scannen ist fehlgeschlagen!', classes: ' red lighten-2 white-text'});
        }
      },
      err => {
        M.toast({html: 'Das Scannen ist fehlgeschlagen!', classes: ' red lighten-2 white-text'});
      },
      {
        preferFrontCamera : false, // iOS and Android
        showFlipCameraButton : true, // iOS and Android
        showTorchButton : true, // iOS and Android
        torchOn: false, // Android, launch with the torch switched on (if available)
        saveHistory: true, // Android, save scan history (default false)
        prompt : "QR-Code", // Android
        resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        formats : "QR_CODE,PDF_417,AZTEC", // default: all but PDF_417 and RSS_EXPANDED
        //orientation : "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
        disableAnimations : true, // iOS
        disableSuccessBeep: false // iOS and Android
      }
    );

  }

  decryptData(data): void {
    this.dataService.decryptData(data)
      .subscribe(
        (encryptDataResponse) => {

          if (encryptDataResponse.status == 200) {
            this.handleScan(encryptDataResponse.data);

          } else {
            M.toast({html: 'Beim Entschlüsseln der Daten ist ein Fehler aufgetreten.', classes: 'red'});
          }
  
        },
        response => {
          M.toast({html: 'Beim Entschlüsseln der Daten ist ein Fehler aufgetreten.', classes: 'red'});
          console.log(response);
        },
        () => {
            //console.log("The POST observable is now completed.");
        });
  }

  handleScan(scanData) {
    this.contact = {} as Contact;
    this.contact.firstname = scanData.firstname;
    this.contact.lastname = scanData.lastname;
    this.ref.detectChanges();
  }

  save() {
    ////////////////////
    /*
    this.assetService.sendToSalesforce(this.asset, this.contact, this.signatureData)
      .subscribe(
        (issueAssetResponse) => {
          console.log(issueAssetResponse);
        },
        response => {
          console.log(response);
        },
        () => {
            //console.log("The POST observable is now completed.");
        });
    */
    ////////////////////

    var errorFields = [];

    if (this.asset == null) {
      errorFields.push("Gegenstand");
    }

    if (this.contact == null) {
      errorFields.push("Kontakt");
    }

    if (this.signatureData == null) {
      errorFields.push("Unterschrift");
    }

    if (errorFields.length == 0) {
      this.issueAsset();
    } else {
      var errorMessage = "Folgende Felder sind Pflichtfelder:<br><br>" + errorFields.join(", ");
      M.toast({html: errorMessage, classes: ' red lighten-2 white-text'});
    }

  }

  issueAsset() {
    this.assetService.issueAsset(this.asset, this.contact, this.signatureData)
      .subscribe(
        (issueAssetResponse) => {
  
          if (issueAssetResponse.status == 200) {
            M.toast({html: 'Die Daten wurden erfolgreich gespeichert.', classes: 'primary-bg'});
            this.router.navigateByUrl('/assets/logs');
          } else {
            M.toast({html: 'Beim Speichern ist ein Fehler aufgetreten.', classes: 'red'});
          }
  
        },
        response => {
          M.toast({html: 'Beim Speichern ist ein Fehler aufgetreten.', classes: 'red'});
          console.log(response);
        },
        () => {
            //console.log("The POST observable is now completed.");
        });
  }

  keyupContact(event:any) {
    if (!event.target.value || event.target.value.length <= 2) {
        this.contacts = [];     

    } else if (event.target.value.length > 2) {
        this.search(event.target.value);
    }
  }

  onClickSuggestion(event, contact) {
    this.contact = contact;

    if (contact.guid == 0) {
      if (!Object.keys(this.storageContacts).includes(contact.firstname)) {
        this.storageContacts[contact.firstname] = 1;
      } else {
        this.storageContacts[contact.firstname] += 1;
      }
    }

    this.storageService.setValue('storageContacts', this.storageContacts);
  }

  search(q) {
    this.isLoading = true;

    this.contactService.searchContacts(q)
    .subscribe(
      (searchContactResponse) => {
        if (searchContactResponse.status == 200) {
          this.contacts = searchContactResponse.contacts;

          var historyFound = false;
          searchContactResponse.history.forEach(contactLog => {
            if (contactLog.contact_name.toLowerCase().includes(q.toLowerCase())) {
              this.contacts.push({'id': 0, 'guid': 0, 'firstname': contactLog.contact_name, 'lastname': ''});
              historyFound = true;
            }
          })

          if (!historyFound) {
            this.contacts.push({'id': 0, 'guid': 0, 'firstname': q, 'lastname': ''});
          }
        }
      },
      response => {
        M.toast({html: 'Es ist ein Fehler aufgetreten!', classes: ' red lighten-2 white-text'});
      },
      () => {
        this.isLoading = false;
        //console.log("The POST observable is now completed.");
      }
    );    

  }

  displayFn(contact) {
    return "";
  }

  sign(): void {
    const dialogRef = this.dialog.open(SignatureComponent, {
      width: '800px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(signatureData => {
      this.signatureData = signatureData;
    });
  }

}
