<div class="row">
    <div class="col s12">
        Ich bin mir bewusst, dass im Falle eines Verlusts oder Missbrauchs ich für den entstandenen Schaden haftbar gemacht werden kann.
    </div>
</div>

<div class="row">
    <div class="col s12 center">
        <canvas id="signature_pad" class="signature-field" backgroundColor="#cecece" size="2"></canvas>

        <a class="waves-effect waves-light btn-large white grey-text" (click)="clearWhiteboard()"><i class="mdi mdi-eraser left"></i>Löschen</a>
        &nbsp;
        <a class="waves-effect waves-light btn-large primary-bg" (click)="saveSignature()"><i class="mdi mdi-arrow-right-bold-box-outline left"></i>OK</a>
    </div>
</div>        