<div class="container">
    <div class="section">

        <div class="row" *ngIf="document != null">
            <div class="col s12">
                <app-documentdetails-intake [document]="document" [integration]="false" *ngIf="document.class == 'customer_intake'"></app-documentdetails-intake>
            </div>
        </div>

    </div>
</div>


