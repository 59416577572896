import { Component, Input, OnInit, SimpleChanges,EventEmitter, Output, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { DocumentdetailsComponent } from '@app/components/document/documentdetails/documentdetails.component';
import { StorageService } from '@app/services/storage.service';
import { DocumentService } from '@app/services/document.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

declare let M: any;
declare let $: any;

@Component({
  selector: 'app-documentdetails-zz',
  templateUrl: './documentdetails-zz.component.html',
  styleUrls: ['./documentdetails-zz.component.css']
})
export class DocumentdetailsZZComponent extends DocumentdetailsComponent implements OnInit {

  override levelData = [
//    {'key': '', 'value': 'Keine Angabe'},
    {'key': '1', 'value': '1'},
    {'key': '2', 'value': '2'},
    {'key': '3', 'value': '3'},
    {'key': '4', 'value': '4'},
//    {'key': 'Anästhesie', 'value': 'Anästhesie'},
    {'key': 'Boarding', 'value': 'Boarding'},
//    {'key': 'Funktionsabteilung', 'value': 'Funktionsabteilung'},
    {'key': 'ITS', 'value': 'ITS'},
    {'key': '0', 'value': 'Kinder'},
    {'key': 'NFA', 'value': 'NFA'},
    {'key': 'OrthAmb', 'value': 'Orthopädische Ambulanz'},
//    {'key': 'Physio', 'value': 'Physiotherapie'},
//    {'key': 'Röntgen', 'value': 'Röntgen'},
//    {'key': 'WLS', 'value': 'WLS'}
    {'key': 'ZMA', 'value': 'ZMA'}
  ]

  shiftData = [
//    {'key': '', 'value': 'Keine Angabe'},
    {'key': '0', 'value': 'Früh'},
    {'key': '1', 'value': 'Spät'}
    //{'key': '2', 'value': 'Nacht'}
  ]

  formData = [];

  constructor(
    protected override storageService: StorageService,
    protected override documentService: DocumentService,
    protected override formBuilder: FormBuilder, 
    protected override router: Router,
    protected datePipe: DatePipe
  ) { 

    super(storageService, documentService, formBuilder, router);
  }

  override ngOnInit(): void {
    this.document.content.level = this.storageService.getValue('level');
 
    if (this.integration == false) {
      this.action = 'edit';
    }

    this.documentForm = this.formBuilder.group({
      date: new FormControl(null, Validators.required),
      level: new FormControl(null, Validators.required),
      shift: new FormControl('', Validators.required),
      comment_positive: new FormControl(null, Validators.required),
      comment_negative: new FormControl(null),
      q1: new FormControl(null),
      q2: new FormControl(null),
      q3: new FormControl(null),
      q4: new FormControl(null),
      q5: new FormControl(null),
      q6: new FormControl(null),
      q7: new FormControl(null)
    });

    this.documentForm.get("level").valueChanges.subscribe(x => {
      this.prepareFormData();
    })

    this.populateDocumentForm();
  }

  get f() { return this.documentForm.controls; }

  prepareFormData() {
    this.formData = [
      {
        'title': '1. Visitenbegleitung', 
        'description': 'Konnten Sie die Ärzte bei der Visite begleiten oder fanden zu viele Visiten gleichzeitig statt?'
      },
      {
        'title': '2. Ausfallkompensation vs. Pflegeaufwand', 
        'description': 'Waren genügend Kollegen verfügbar, um den Pflegeanspruch zu erfüllen?'
      },
      {
        'title': '3. Interdisziplinäre Schnittstelle', 
        'description': 'Hat die Zusammenarbeit mit anderen Bereichen gut funktioniert? (z.B. OP, Radiologie, Ergo, orth. Werkstatt, Stationen,...)'
      },
      {
        'title': '4. Einarbeitung neuer Kollegen/Betreuung Azubis/Schüler', 
        'description': 'War es möglich, genügend Zeit für die Einarbeitung/Betreuung zur Verfügung zu stellen?'
      },
      {
        'title': '5. Teamwork', 
        'description': 'Wie hat die Zusammenarbeit mit meinen Kollegen auf der Ebene funktioniert?'
      },
      {
        'title': '6. IT - Funktionierende Infrastruktur', 
        'description': 'Haben alle Systeme (Hardware, Software) wie z.B. Visitenwagen, ORBIS, Drucker, Monitore,... funktioniert?'
      },
      {
        'title': '7. Technik/Medizintechnik - Funktionierende Infrastruktur', 
        'description': 'Haben alle Systeme wie z.B. TV, Aufzug,... funktioniert?'
      }
    ];

    if (['Boarding', 'OrthAmb', 'NFA', 'ZMA'].includes(this.documentForm.controls['level'].value)) {
      this.formData[0] = {'title': '1. Arztpräsenz', 'description': 'War ein rascher, strukturierter Arbeitsablauf ohne lange Wartezeiten möglich oder waren die Ärzte in andere Abteilungen involviert?'};
      this.formData[1] = {'title': '2. Ausfallkompensation vs. Arbeitsaufwand', 'description': 'Waren genügend Kollegen verfügbar, um den Arbeitsaufwand zu erfüllen?'};
      this.formData[4] = {'title': '5. Teamwork', 'description': 'Wie hat die Zusammenarbeit mit den Kollegen meiner Abteilung funktioniert?'};
    }
  }

  shiftString(): string {
    return this.translateValue(this.shiftData, this.document.content.shift)
  }

  override levelString(): string {
    return this.translateValue(this.levelData, this.document.content.level)
  }

  override populateDocumentForm() {
    this.populateFormGroup(this.documentForm, this.document.content);

    var dateTmp = new Date();
    var date;

    if (this.document.content.date) {
      date = this.datePipe.transform(this.document.content.date, 'dd.MM.yyyy');
      this.documentForm.patchValue({'date': date});
    } else {
      date = (dateTmp.getDate() < 10 ? "0":"") + dateTmp.getDate() + "." + 
            (dateTmp.getMonth() < 9 ? "0":"") + (dateTmp.getMonth() + 1) + "." + 
             dateTmp.getFullYear();
      this.documentForm.patchValue({'date': date});
    }

    if (dateTmp.getHours() == 14) {
      this.documentForm.patchValue({'shift': '0'});
    } else if (dateTmp.getHours() == 22) {
      this.documentForm.patchValue({'shift': '1'});
    //} else if (dateTmp.getHours() == 6) {
    //  this.documentForm.patchValue({'shift': '2'});
    }

    this.resetDatepicker();
  }

  resetDatepicker() {
    $(document).ready(function() {
      $(".datepicker").datepicker({
        maxDate: 0,
        firstDay: 1,
        i18n: {
          months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
          monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
          weekdays: ["Sonntag","Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
          weekdaysShort: ["So","Mo", "Di", "Mi", "Do", "Fr", "Sa"],
          weekdaysAbbrev: ["S","M", "D", "M", "D", "F", "S"],
          cancel: 'Abbrechen',
          clear: 'Löschen',
          done: 'Ok'
        },
        format: "dd.mm.yyyy"
      });
    
    });

  }

  @ViewChild('actionChanged') set actionChanged(element) {
    if (element) {
      this.resetDatepicker();
    }
  }

  save() {
    this.submitted = true;
    var inputValue = (<HTMLInputElement>document.getElementById("date")).value;
    this.documentForm.patchValue({'date': inputValue});

    if (this.documentForm.invalid) {
      return;

    } else {
      this.populateObject(this.document, this.documentForm);
      this.populateObject(this.document.content, this.documentForm);

      var date = this.getSQLDateFromUIDate(this.documentForm.controls['date'].value);
      this.document.content.date = date;

      this.createDocument();

      this.storageService.setValue('level', this.document.content.level);
    }
  }

}
