import { Component, Input, OnInit } from '@angular/core';
import { CustomComponent } from '@app/components/core/custom-component';
import { StorageService } from '@app/services/storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportService } from '@app/services/report.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-reportlist',
  templateUrl: './reportlist.component.html',
  styleUrls: ['./reportlist.component.css']
})
export class ReportlistComponent extends CustomComponent implements OnInit {

  @Input() date;
  @Input() todate;
  @Input() reportData = null;
  private reportDataSub: Subscription;

  historyData = {};

  view = null;

  constructor(
    protected reportService: ReportService,
    protected override storageService: StorageService,
    protected override router: Router
  ) { 
    super(storageService, router, true);
  
  }

  ngOnInit(): void {
    this.reportDataSub = this.reportService.reportDataChanged.subscribe(
      data => {
        this.reportData = data;
      }
    );
  }

  ngOnDestroy() {
    if (this.reportDataSub) {
      this.reportDataSub.unsubscribe()
    }
  }

  reload(): void {
    var dates = [this.date, this.todate];
    this.reportService.datesChanged.emit(dates);
  }

  addToHistory(): void {
    var item = <HTMLElement> document.getElementById('resultTable');
    var clone = <HTMLElement> item.cloneNode(true);

    var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    var key = '<b>' + this.date.toLocaleDateString("de-DE", options) + '</b> bis <b>' + this.todate.toLocaleDateString("de-DE", options) + '</b>';
    this.historyData[key] = clone.outerHTML;
  }

  deleteHistory(key) {
    delete this.historyData[key];
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  getUsersByType(type) {
    for (const [key, obj] of Object.entries(this.reportData['user_source'])) {
      const obj2 = <any> obj;
      if (obj2.value == type) {
        return obj2.count;
      }
    }
  }  

}
