import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentService } from '@app/services/document.service';
import { ReportService } from '@app/services/report.service';
import { StorageService } from '@app/services/storage.service';
import { StatsPageComponent } from '../stats-page/stats-page.component';

@Component({
  selector: 'app-stats-station-page',
  templateUrl: './stats-station-page.component.html',
  styleUrls: ['./stats-station-page.component.css']
})
export class StatsStationPageComponent extends StatsPageComponent implements OnInit {

  document = null;
  selectedLevel = "all";
  levelData = [
    {'key': '1', 'value': '1'},
    {'key': '2', 'value': '2'},
    {'key': '3', 'value': '3'},
    {'key': '4', 'value': '4'},
    {'key': '0', 'value': 'Kinder'}
  ]

  constructor(
    protected override reportService: ReportService,
    protected override storageService: StorageService,
    protected override documentService: DocumentService,
    protected override router: Router
  ) { 
    super(reportService, storageService, documentService, router);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  levelfilterChanged(event) {
    if (this.selectedLevel == '') {
      this.selectedLevel = null;
    }
    
    this.loadReports();
  }

  reload(): void {
    this.loadReports();
  }

  override loadReports(): void {
    var fromDate = this.getDateTime(this.date);
    var toDate = this.getDateTime(this.todate);

    var filter = {
//      'user_guid': this.user.guid,
      'from': fromDate,
      'to': toDate,
      'level': this.selectedLevel
    };
    this.reportService.getStationReports(filter)
    .subscribe(
      (getStationReportsResponse) => {
        if (getStationReportsResponse.status == 200) {
          this.reportData = getStationReportsResponse.data;
          this.reportService.reportDataChanged.emit(this.reportData);
        }

      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

}
