<div class="container">
    <div class="section">
    
        <h1 class="bot-20 sec-tit">Reports (Objects)</h1>

        <div>
            <app-report-date-selector 
                [date]="date" [todate]="todate">
            </app-report-date-selector>

            <app-assetreportlist 
                [date]="date" [todate]="todate" [reportData]="reportData">
            </app-assetreportlist>
        </div>

    </div>
</div>
