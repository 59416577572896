import { Component, Input, OnInit, SimpleChanges,EventEmitter, Output, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { DocumentdetailsComponent } from '@app/components/document/documentdetails/documentdetails.component';
import { StorageService } from '@app/services/storage.service';
import { DocumentService } from '@app/services/document.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

declare let M: any;
declare let $: any;

@Component({
  selector: 'app-documentdetails-zma',
  templateUrl: './documentdetails-zma.component.html',
  styleUrls: ['./documentdetails-zma.component.css']
})
export class DocumentdetailsZMAComponent extends DocumentdetailsComponent implements OnInit {

  constructor(
    protected override storageService: StorageService,
    protected override documentService: DocumentService,
    protected override formBuilder: FormBuilder, 
    protected override router: Router,
    protected datePipe: DatePipe
  ) { 

    super(storageService, documentService, formBuilder, router);
  }

  override ngOnInit(): void {
    this.documentForm = this.formBuilder.group({
      treatment_date: new FormControl(null),
      waitingtime_hours: new FormControl('', [Validators.min(0), Validators.max(24)]),
      waitingtime_minutes: new FormControl('', [Validators.min(0), Validators.max(59)]),
      comment_positive: new FormControl(null),
      comment_negative: new FormControl(null),
      q1: new FormControl(null),
      q2: new FormControl(null),
      q3: new FormControl(null),
      q4: new FormControl(null),
      q5: new FormControl(null),
      q6: new FormControl(null),
      q7: new FormControl(null),
      q8: new FormControl(null),
      q9: new FormControl(null),
      q10: new FormControl(null),
      q11: new FormControl(null),
      q12: new FormControl(null),
      q13: new FormControl(null),
      q14: new FormControl(null),
      q15: new FormControl(null),
      q16: new FormControl(null),
      q17: new FormControl(null),
      q18: new FormControl(null),
      q19: new FormControl(null),
      q20: new FormControl(null),
      q21: new FormControl(null),
      q22: new FormControl(null),
      q23: new FormControl(null)
    });

    this.populateDocumentForm();
  }

  override populateDocumentForm() {
    this.populateFormGroup(this.documentForm, this.document.content);

    if (this.document.content.treatment_date) {
      var treatmentDate = this.datePipe.transform(this.document.content.treatment_date, 'dd.MM.yyyy');
      this.documentForm.patchValue({'treatment_date': treatmentDate});
    }

    this.resetDatepicker();
  }

  resetDatepicker() {
    $(document).ready(function() {
      $(".datepicker").datepicker({
        firstDay: 1,
        i18n: {
          months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
          monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
          weekdays: ["Sonntag","Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
          weekdaysShort: ["So","Mo", "Di", "Mi", "Do", "Fr", "Sa"],
          weekdaysAbbrev: ["S","M", "D", "M", "D", "F", "S"],
          cancel: 'Abbrechen',
          clear: 'Löschen',
          done: 'Ok'
        },
        format: "dd.mm.yyyy"
      });
    
    });

  }

  @ViewChild('actionChanged') set actionChanged(element) {
    if (element) {
      this.resetDatepicker();
    }
  }

  save() {
    this.submitted = true;
    var inputValue = (<HTMLInputElement>document.getElementById("treatment_date")).value;
    this.documentForm.patchValue({'treatment_date': inputValue});

    if (this.documentForm.invalid) {
        return;

    } else {
      this.populateObject(this.document, this.documentForm);
      this.populateObject(this.document.content, this.documentForm);

      var date = this.getSQLDateFromUIDate(this.documentForm.controls['treatment_date'].value);
      this.document.content.treatment_date = date;

      if (this.document.guid) {
        this.updateDocument();
      } else {
        this.createDocument();
      }

    }
  }

}
