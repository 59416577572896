import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { ContactService } from '@app/services/contact.service';
import { DataService } from '@app/services/data.service';
import { StorageService } from '@app/services/storage.service';

@Component({
  selector: 'app-content-divi-page',
  templateUrl: './content-divi-page.component.html',
  styleUrls: ['./content-divi-page.component.css']
})
export class ContentDiviPageComponent extends CustomComponent implements OnInit {

  data = null;

  constructor(
    protected override storageService: StorageService,
    protected contactService: ContactService,
    protected dataService: DataService,
    protected override router: Router
  ) { 

    super(storageService, router, false);
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.dataService.getDiviData()
    .subscribe(
      (getDiviDataResponse) => {
        if (getDiviDataResponse.status == 200) {
          this.data = getDiviDataResponse.data;
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

}
