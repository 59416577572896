<div class="container">
    <div class="section">
    
        <h1 class="bot-20 sec-tit">Unternehmen</h1>

        <div class="row card-panel">
            <div class="col s12">
                <!--button mat-button [matMenuTriggerFor]="menu" class="toolbar-btn">Neu <i class="mdi mdi-dots-horizontal hide-on-small-only"></i></button>
                <mat-menu #menu="matMenu" class="toolbar">
                    <button mat-menu-item (click)="createCompany()">Unternehmen</button>
                </mat-menu-->

                <button mat-button (click)="toggleCodes()" class="toolbar-btn">
                    <i *ngIf="this.qrcodesDisplay == true" class="mdi mdi-eye-off-outline"></i>
                    <i *ngIf="this.qrcodesDisplay == false" class="mdi mdi-eye-outline"></i>
                    QR-Codes
                </button>
            </div>
        </div>

        <app-companylist [companies]="companies" *ngIf="qrcodesDisplay == false"></app-companylist>

        <div class="qrcodelist card-panel" *ngIf="companies != null && companies.length > 0 && qrcodesDisplay == true">
            <span *ngFor="let company of companies">
                <div class="qrcode center" *ngFor="let contact of company.contacts">
                    <i class="mdi mdi-account-check small"></i> {{contact.firstname}} {{contact.lastname}}<br>
                    <b>{{company.name}}</b><br><br>
                    <ngx-qrcode 
                        elementType="text" 
                        [value] = "getContactCodeValue(contact)"
                        cssClass = "contact_qrcode"
                        errorCorrectionLevel = "L">
                    </ngx-qrcode>
                </div>
            </span>
            <br clear="all">
        </div>

        <div class="card-panel right-align">
            <button class="btn white grey-text" routerLink="/assets/logs">Zurück</button>
        </div>

    </div>
</div>


