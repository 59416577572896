<div class="card-panel" *ngIf="companies == null">
    <br><br>
    <div id="preloader"></div>
    <br>
    <div class="center-align">Bitte warten...</div>
</div>

<div *ngIf="companies != null">

    <div class="companies card-panel" *ngIf="companies.length == 0">
        <div class="title">Sie haben noch kein Unternehmen angelegt.</div>
    </div>    
    
    <div class="companies card-panel" *ngIf="companies.length > 0">
        <div class="title">Die Liste beinhaltet {{companies.length}} Einträge.</div>
    </div>    

    <ul class="companies" *ngIf="companies.length > 0">
        <table id="companiesTable" *ngIf="companies != null">
            <thead>
                <tr>
                    <th>Firma</th>
                    <th>Kontakte</th>
                </tr>
            <tbody>
                <tr *ngFor="let company of companies">
                    <td>
                        <b>{{company.name}}</b><br>
                        {{company.street}} {{company.houseno}}<br>
                        {{company.zipcode}} {{company.city}} <br>
                        {{company.country}} 
                    </td>
                    <td>
                        <app-contactlist [contacts]="company.contacts"></app-contactlist>
                    </td>
                </tr>
            </tbody>
        </table>
    </ul>

</div>

