import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetDialogConfigResponse } from '@app/models/responses/getDialogConfigResponse.model';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { GetAttributeFiltersResponse } from '@app/models/responses/getAttributeFiltersResponse.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private headers = null;
  private options = {};

  constructor(
    protected storageService: StorageService,
    private http: HttpClient

  ) { 
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    this.options = {
      headers: this.headers
    }
  }

  getDialogConfig(guid) {
    let params = new HttpParams();
    params = params.append('guid', guid);
    this.options['params'] = params;

    return this.http.get<GetDialogConfigResponse>(environment.apiEndpoint + '/api/config/dialog/1.0.0.php', this.options);
  }

  updateDialogConfig(payload: object) {
    const body = payload;
    return this.http.put<GetDialogConfigResponse>(environment.apiEndpoint + '/api/config/dialog/1.0.0.php', body, this.options);
  }

  getFilters(guid, intent, status) {
    let params = new HttpParams();
    params = params.append('guid', guid);
    params = params.append('intent', intent);
    params = params.append('status', status);
    this.options['params'] = params;

    return this.http.get<GetAttributeFiltersResponse>(environment.apiEndpoint + '/api/config/attributes/1.0.0.php', this.options);
  }

}
