<div class="container">

    <div *ngIf="employee != null">
        <div class="section">
            <app-employee-checkin-status [data]="employee"></app-employee-checkin-status>
        </div>
    </div>

    <div *ngIf="employee == null">
        <div class="section center-align">
            
            <button class="btn primary-bg" (click)="scan()">
                <i class="mdi mdi-tablet-ipad right"></i> Scan
            </button>

        </div>
    </div>

</div>


