<div *ngIf="reportData == null">
    <div class="card-panel center-align">
        <br><br>
        <div id="preloader"></div>
        <br>
        <div class="center-align">Bitte warten...</div>
    </div> 
</div>

<div *ngIf="reportData != null && reportData.documents != null">
    <div class="row" *ngIf="reportData.documents.length > 0" #documentsContent>
        <h2>Kommentare</h2>

        <div class="col s12">
            <ul class="tabs full-transparent transparent z-depth-0">
                <li class="tab col s6"><a href="#comments_positive_container" class="active">Positiv</a></li>
                <li class="tab col s6"><a href="#comments_negative_container">Negativ</a></li>
            </ul>
        </div>

        <div id="comments_positive_container" class="col s12 active">
            <div class="tab-content transparent z-depth-0">
                <ul class="collection" *ngIf="reportData.documents.length > 0">
                    <ng-container *ngFor="let document of reportData.documents">
                        <li class="collection-item" *ngIf="document.comment_positive && document.comment_positive != ''">
                            {{document.comment_positive}}<br>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
        <div id="comments_negative_container" class="col s12 active">
            <div class="tab-content transparent z-depth-0">
                <ul class="collection" *ngIf="reportData.documents.length > 0">
                    <ng-container *ngFor="let document of reportData.documents">
                        <li class="collection-item" *ngIf="document.comment_negative && document.comment_negative != ''">
                            {{document.comment_negative}}<br>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</div>