import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { GetReportsResponse } from '@app/models/responses/getReportsResponse.model';

import { environment } from 'src/environments/environment';
import { GetZZReportsResponse } from '@app/models/responses/getZZReportsResponse.model';
import { GetAssetsReportsResponse } from '@app/models/responses/getAssetsReportsResponse.model';
import { GetZmaReportsResponse } from '@app/models/responses/getZmaReportsResponse.model';
import { GetStationReportsResponse } from '@app/models/responses/getStationReportsResponse.model';
import { GetCheckinsReportsResponse } from '@app/models/responses/getCheckinsReportsResponse.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private headers = null;
  private options = {};
  
  datesChanged: EventEmitter<Array<Date>> = new EventEmitter<Array<Date>>();
  reportDataChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private http: HttpClient

  ) { 
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    this.options = {
      headers: this.headers
    }
  }

  getReports(data) {
    let params = new HttpParams();
    Object.entries(data).forEach(([key, value]) => {
      params = params.append(key, value.toString());
    });
    this.options['params'] = params;

    return this.http.get<GetReportsResponse>(environment.apiEndpoint + '/api/reports/1.0.0.php', this.options);
  }

  getReportsFrom(data, api) {
    let params = new HttpParams();
    Object.entries(data).forEach(([key, value]) => {
      if (value != null) {
        params = params.append(key, value.toString());
      }
    });
    this.options['params'] = params;

    return this.http.get<GetStationReportsResponse>(environment.apiEndpoint + '/api/reports/' + api + '/1.0.0.php', this.options);
  }

  getStationReports(data) {
    return this.getReportsFrom(data, 'station');
  }

  getZmaReports(data) {
    return this.getReportsFrom(data, 'zma');
  }
  
  getZZReports(data) {
    return this.getReportsFrom(data, 'zz');
  }

  getAssetsReports(data) {
    return this.getReportsFrom(data, 'assets');
  }

  getCheckinsReports(data) {
    return this.getReportsFrom(data, 'checkins');
  }

}
