import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { ContactService } from '@app/services/contact.service';
import { StorageService } from '@app/services/storage.service';

@Component({
  selector: 'app-companies-page',
  templateUrl: './companies-page.component.html',
  styleUrls: ['./companies-page.component.css']
})
export class CompaniesPageComponent extends CustomComponent implements OnInit {

  companies = null;
  qrcodesDisplay = false;

  constructor(
    protected override storageService: StorageService,
    protected contactService: ContactService,
    protected override router: Router
  ) { 

    super(storageService, router, true);
  }

  ngOnInit(): void {
    this.loadCompanies();
  }

  toggleCodes() {
    this.qrcodesDisplay = !this.qrcodesDisplay;
  }

  loadCompanies() {
    this.contactService.getCompanies()
      .subscribe(
        (getCompaniesResponse) => {
          if (getCompaniesResponse.status == 200) {
            this.companies = getCompaniesResponse.companies;
          }
        },
        response => {
          console.log(response);
        },
        () => {
            //console.log("The POST observable is now completed.");
        }
      );
  }

  getContactCodeValue(contact) {
    var qrcodeData = {
      'class': 'contact',
      'guid': contact.guid
    }

    return JSON.stringify(qrcodeData);
  }

  createContact(company) {
  }

  createCompany() {
  }

}
