import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { AssetService } from '@app/services/asset.service';
import { StorageService } from '@app/services/storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-assets-log-page',
  templateUrl: './assets-log-page.component.html',
  styleUrls: ['./assets-log-page.component.css']
})
export class AssetsLogPageComponent extends CustomComponent implements OnInit {

  loading = true;
  assetlogs = [];
  private assetlogsSub: Subscription;

  constructor(
    protected override storageService: StorageService,
    protected assetService: AssetService,
    protected override router: Router
  ) { 

    super(storageService, router, true);

    this.assetlogs = null;

  }

  ngOnInit(): void {
    this.loadAssetLogs();
  }

  loadAssetLogs() {
    this.loading = true;
    
    this.assetService.getOpenAssetLogs()
      .subscribe(
        (getAssetLogsResponse) => {
          if (getAssetLogsResponse.status == 200) {
            this.assetlogs = getAssetLogsResponse.assetlogs;
            this.loading = false;
          }
        },
        response => {
          console.log(response);
        },
        () => {
            //console.log("The POST observable is now completed.");
        }
      );
  }  

}
