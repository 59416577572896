import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EncryptDataResponse } from '@app/models/responses/encryptDataResponse.model';
import { GetDiviDataResponse } from '@app/models/responses/getDiviDataResponse.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private headers = null;
  private options = {};

  constructor(
    private http: HttpClient

  ) { 
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    this.options = {
      headers: this.headers
    }
  }

  getDiviData() {
    return this.http.get<GetDiviDataResponse>(environment.apiEndpoint + '/api/data/divi/1.0.0.php', this.options);
  }

  encryptData(data: any) {
    var body = {
      'data': data
    }

    return this.http.post<EncryptDataResponse>(environment.apiEndpoint + '/api/data/encrypt/1.0.0.php', body, this.options);
  }

  decryptData(data: any) {
    var body = {
      'data': data
    }

    return this.http.post<EncryptDataResponse>(environment.apiEndpoint + '/api/data/decrypt/1.0.0.php', body, this.options);
  }

}
