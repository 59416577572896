<div class="container">
    <div class="section">

        <h1 class="bot-20 sec-tit">Befragungen</h1>

        <div class="row card-panel toolbar" *ngIf="document == null">
            <div class="col s8">
                <button mat-button [matMenuTriggerFor]="menu" class="toolbar-btn"><i class="mdi mdi-file-document hide-on-small-only"></i> Neu</button>
                <mat-menu #menu="matMenu" class="toolbar">
                    <button mat-menu-item (click)="createDocument('survey_universal')"><i class="mdi mdi-file-outline hide-on-small-only"></i> Universal</button>
                    <button mat-menu-item (click)="createDocument('station')"><i class="mdi mdi-file-outline hide-on-small-only"></i> Station</button>
                    <button mat-menu-item (click)="createDocument('zma')"><i class="mdi mdi-file-outline hide-on-small-only"></i> ZMA</button>
                    <!--button mat-menu-item (click)="createDocument('zz')"><i class="mdi mdi-file-outline hide-on-small-only"></i> ZufriedenheitZ</button-->
                </mat-menu>

                <button mat-button routerLink="/documents/upload" class="toolbar-btn"><i class="mdi mdi-upload hide-on-small-only"></i> Upload</button>
            </div>
            <div class="col s4">
                <select class="browser-default custom-select" [formControl]="documentClass" (change)="documentClassesChanged($event)">
                    <option *ngFor="let documentClassItem of documentClassData" [ngValue]="documentClassItem.value">{{documentClassItem.key}}</option>
                </select>
            </div>
        </div>

        <div class="row" *ngIf="document == null" #documentsContent>
        
            <div class="col s12">
                <ul class="tabs full-transparent transparent z-depth-0">
                    <li class="tab col s6"><a href="#checklist" class="active">Zur Überprüfung</a></li>
                    <li class="tab col s6"><a href="#commited">Freigegeben</a></li>
                </ul>
            </div>
            <div id="checklist" class="col s12 active">
                <div class="tab-content transparent z-depth-0">
                    <app-documentlist [documents]="documentsToCheck" [total]="totalDocumentsToCheck" folder="tocheck" (documentChangeEvent)="documentChanged($event)"></app-documentlist>
                    
                    <mat-paginator [length]="totalDocumentsToCheck"
                                [pageSize]="pageSize"
                                [pageSizeOptions]="pageSizeOptions"
                                (page)="pageToCheckChanged($event)">
                    </mat-paginator>
                </div>
            </div>
            <div id="commited" class="col s12" style="display: none;">
                <div class="tab-content transparent z-depth-0">
                    <app-documentlist [documents]="documentsCommited" [total]="totalDocumentsCommited" folder="commited" (documentChangeEvent)="documentChanged($event)"></app-documentlist>
                    <mat-paginator [length]="totalDocumentsCommited"
                                [pageSize]="pageSize"
                                [pageSizeOptions]="pageSizeOptions"
                                (page)="pageCommitedChanged($event)">
                    </mat-paginator>
                </div>
            </div>
        </div>

        <div *ngIf="document != null">
            <!--div class="row">
                <div class="col s12">
                    <div class="left">
                        <a (click)="unsetDocument()" class="btn waves-effect waves-light"><i class="mdi mdi-keyboard-backspace"></i></a>
                    </div>
                </div>
            </div-->
            <div class="row">
                <div class="col s12">
                    <app-documentdetails-zma [document]="document" (documentChangeEvent)="documentChanged($event)" *ngIf="document.class == 'ZMA'"></app-documentdetails-zma>
                    <app-documentdetails-station [document]="document" (documentChangeEvent)="documentChanged($event)" *ngIf="document.class == 'STATION'"></app-documentdetails-station>
                    <app-documentdetails-zz [document]="document" (documentChangeEvent)="documentChanged($event)" *ngIf="document.class == 'ZZ'"></app-documentdetails-zz>

                    <app-documentdetails-wke-universal [document]="document" (documentChangeEvent)="documentChanged($event)" *ngIf="document.class == 'survey_universal'"></app-documentdetails-wke-universal>
                </div>
            </div>
        </div>
    </div>

</div>


