import { Component, Input, OnInit, EventEmitter, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CustomComponent } from '@app/components/core/custom-component';
import { StorageService } from '@app/services/storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportService } from '@app/services/report.service';
import { Subscription } from 'rxjs';
import { ChartErrorEvent, ChartReadyEvent, ChartSelectEvent, GoogleChartInterface } from 'ng2-google-charts';
import WordCloud from 'wordcloud';
import { Document } from '@app/models/document.model';

declare let M: any;
declare let google: any;
declare let $: any;

@Component({
  selector: 'app-zzreportlist',
  templateUrl: './zzreportlist.component.html',
  styleUrls: ['./zzreportlist.component.css']
})
export class ZZReportlistComponent extends CustomComponent implements OnInit {

  @Input() date;
  @Input() todate;
  @Input() reportData = null;
  private reportDataSub: Subscription;

  @Output()
  documentChangeEvent: EventEmitter<Document> = new EventEmitter<Document>();
  @Output()
  levelChangeEvent: EventEmitter<String> = new EventEmitter<String>();
  
  public gaugeCharts = null;
  public lineCharts = null;

  historyData = {};

  view = null;

  @Input() selectedLevel = null;
  levelData = [
    {'key': '1', 'value': '1'},
    {'key': '2', 'value': '2'},
    {'key': '3', 'value': '3'},
    {'key': '4', 'value': '4'},
    {'key': 'Boarding', 'value': 'Boarding'},
    {'key': 'ITS', 'value': 'ITS'},
    {'key': '0', 'value': 'Kinder'},
    {'key': 'NFA', 'value': 'NFA'},
    {'key': 'OrthAmb', 'value': 'Orthopädische Ambulanz'},
    {'key': 'ZMA', 'value': 'ZMA'}
  ]

  shiftData = [
    {'key': '0', 'value': 'Früh'},
    {'key': '1', 'value': 'Spät'}
  ]

  constructor(
    protected reportService: ReportService,
    protected override storageService: StorageService,
    protected override router: Router
  ) { 
    super(storageService, router, true);
  
  }

  ngOnInit(): void {
    /*
    var list = [
      ['IT', 120], 
      ['Visitenwagen', 50], 
      ['TV', 20], 
      ['Computer', 55], 
      ['Drucker', 30], 
      ['Tablet', 20],
      ['IT2', 120], 
      ['Visitenwagen2', 50], 
      ['TV2', 20], 
      ['Computer2', 55], 
      ['Drucker2', 30], 
      ['Tablet2', 20],
      ['IT3', 120], 
      ['Visitenwagen3', 50], 
      ['TV3', 20], 
      ['Computer3', 55], 
      ['Drucker3', 30], 
      ['IT', 120], 
      ['Visitenwagen', 50], 
      ['TV', 20], 
      ['Computer', 55], 
      ['Drucker', 30], 
      ['Tablet', 20],
      ['IT2', 120], 
      ['Visitenwagen2', 50], 
      ['TV2', 20], 
      ['Computer2', 55], 
      ['Drucker2', 30], 
      ['Tablet2', 20],
      ['IT3', 120], 
      ['Visitenwagen3', 50], 
      ['TV3', 20], 
      ['Computer3', 55], 
      ['Drucker3', 30], 
      ['Tablet3', 20]
    ];

    WordCloud(document.getElementById('negative-canvas'), { 
      list: list,
      shrinkToFit: true,
      color: '#ff0000',
      maxRotation: 0,
      minRotation: 0
    });

    WordCloud(document.getElementById('positive-canvas'), { 
      list: list,
      shrinkToFit: true,
      color: '#00ff00',
      maxRotation: 0,
      minRotation: 0,
    });
    */
   
    /*
    var $canvas = $('#positive-canvas');
    var $htmlCanvas = $('#positive-html-canvas');
    var width = $('#positive-canvas-wrapper').width();
    var height = Math.floor(width * 0.65);
    $canvas.css({'width': width + 'px', 'height': height + 'px'});
    $canvas.attr('width', width);
    $canvas.attr('height', height);
    $htmlCanvas.css({'width': width + 'px', 'height': height + 'px'});
    */

    this.reportDataSub = this.reportService.reportDataChanged.subscribe(
      data => {
        this.reportData = data;
        this.resetGaugeChart();
        this.resetLineChart();
      }
    );
  }

  ngOnDestroy() {
    if (this.reportDataSub) {
      this.reportDataSub.unsubscribe()
    }
  }

  @ViewChild('documentsContent') set documentsContent(element) {
    if (element) {
      M.AutoInit();
    }
  }

  shiftString(shift): string {
    return this.translateValue(this.shiftData, shift)
  }

  levelString(level): string {
    return this.translateValue(this.levelData, level)
  }

  levelfilterChanged(level) {
    this.levelChangeEvent.emit(this.selectedLevel);
  }

  getTooltip(document) {
    var tooltip = "";

    if (document.comment_positive) {
      tooltip += "Positiv: " + document.comment_positive + "\n";
    } else {
      tooltip += "Positiv: -\n";
    }

    if (document.comment_negative) {
      tooltip += "Negativ: " + document.comment_negative;
    } else {
      tooltip += "Negativ: -";
    }

    return tooltip;
  }

  setDocument(document) {
    this.documentChangeEvent.emit(document);
  }

  reload(): void {
    var dates = [this.date, this.todate];
    this.reportService.datesChanged.emit(dates);
  }

  addToHistory(): void {
    var item = <HTMLElement> document.getElementById('resultTable');
    var clone = <HTMLElement> item.cloneNode(true);

    var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    var key = '<b>' + this.date.toLocaleDateString("de-DE", options) + '</b> bis <b>' + this.todate.toLocaleDateString("de-DE", options) + '</b>';
    this.historyData[key] = clone.outerHTML;
  }

  deleteHistory(key) {
    delete this.historyData[key];
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  resetGaugeChart(): void {

    if (this.reportData) {
      var chartData = null;
      if (Array.isArray(this.reportData.scores) && this.reportData.scores.length) {
        chartData = [];
        this.reportData.scores.forEach( (scoreObj) => {
          chartData.push([this.shiftString(scoreObj.shift), Number(scoreObj.score)]);
        }); 
      }

      this.gaugeCharts = {
        chartType: 'Gauge',
        firstRowIsData: true,
        dataTable: {},
        options: {
          animation: {
            easing: 'out'
          },
          min: 1,
          max: 3,
          greenFrom: 1,
          greenTo: 1.15,
          yellowFrom: 1.15,
          yellowTo: 2.0,
          redFrom: 2.0,
          redTo: 3.0,
          minorTicks: 0,
          majorTicks: ['', '', ''],
          shadowEnabled: false
        },
      } as GoogleChartInterface;

      this.gaugeCharts.dataTable = chartData;
    }

  } 

  resetLineChart(): void {

    if (this.reportData) {

      var helperData = [];

      if (Array.isArray(this.reportData.scores_history) && this.reportData.scores_history.length) {
        this.reportData.scores_history.reverse().forEach( (scoreObj) => {

          var entry = helperData[scoreObj.date_frac];
          if (entry == null) {
            entry = {};
          }

          entry[scoreObj.shift] = scoreObj.score;
          helperData[scoreObj.date_frac] = entry;
        }); 
      }

      var chartData = [];
      if (Object.keys(helperData).length > 0) {
        chartData.push(['Datum', 'Früh', 'Spät']);

        Object.keys(helperData).forEach(function (key) {
          chartData.push( [key, Number(helperData[key][0]), Number(helperData[key][1])] )
        });
      }

      this.lineCharts = {
        chartType: 'LineChart',
        options: {
          title: 'Scores',
          width: '100%',
          vAxis: {
            ticks: [1, 2, 3],
          }
        }
      };      

      this.lineCharts.dataTable = chartData;
    }

  } 

}
