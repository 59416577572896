import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { DataService } from '@app/services/data.service';
import { EmployeeService } from '@app/services/employee.service';
import { StorageService } from '@app/services/storage.service';
//import { NativeAudio } from '@ionic-native/native-audio/ngx';

declare let cordova: any;
declare let M: any;

@Component({
  selector: 'app-checkin-self-page',
  templateUrl: './checkin-self-page.component.html',
  styleUrls: ['./checkin-self-page.component.css']
})
export class CheckinSelfPageComponent extends CustomComponent implements OnInit {

  employee = null

  constructor(
    protected override storageService: StorageService,
    protected override router: Router,
    protected employeeService: EmployeeService,
    protected dataService: DataService,
    //private nativeAudio: NativeAudio,
    protected changeDetectorRef: ChangeDetectorRef
  ) { 

    super(storageService, router, true);
  }

  ngOnInit(): void {
    this.scan();
  }

  scan(): void {

    cordova.plugins.barcodeScanner.scan(
      result => {

        try {
          var scanData = JSON.parse(result.text);

          if (scanData.class == "employee_sec") {
            this.decryptData(scanData.data);
        
          } else if (scanData.class == "employee") {
            this.handleScan(scanData);
          }

        } catch (e) {
          M.toast({html: 'Das Scannen ist fehlgeschlagen!', classes: ' red lighten-2 white-text'});
        }
      },
      err => {
        M.toast({html: 'Das Scannen ist fehlgeschlagen!', classes: ' red lighten-2 white-text'});
      },
      {
        preferFrontCamera : false, // iOS and Android
        showFlipCameraButton : true, // iOS and Android
        showTorchButton : true, // iOS and Android
        torchOn: false, // Android, launch with the torch switched on (if available)
        saveHistory: false, // Android, save scan history (default false)
        prompt : "QR-Code", // Android
        resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        formats : "QR_CODE,PDF_417,AZTEC", // default: all but PDF_417 and RSS_EXPANDED
        //orientation : "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
        disableAnimations : false, // iOS
        disableSuccessBeep: false // iOS and Android
      }
    );

  }

  decryptData(data): void {
    this.dataService.decryptData(data)
      .subscribe(
        (encryptDataResponse) => {

          if (encryptDataResponse.status == 200) {
            this.handleScan(encryptDataResponse.data);

          } else {
            M.toast({html: 'Beim Entschlüsseln der Daten ist ein Fehler aufgetreten.', classes: 'red'});
          }
  
        },
        response => {
          M.toast({html: 'Beim Entschlüsseln der Daten ist ein Fehler aufgetreten.', classes: 'red'});
        },
        () => {
            //console.log("The POST observable is now completed.");
        });
  }

  handleScan(data): void {
    if (data.email) {
      var employeeId = data.email.substring(0, data.email.lastIndexOf("@"));

      if (employeeId) {

        this.employeeService.validateEmployeeById(employeeId)
        .subscribe(
          (genericEmployeeResponse) => {
            if (genericEmployeeResponse.status == 200) {
              this.employee = genericEmployeeResponse.employee;

              if (this.employee) {
                this.logCheckin();
                this.playJingle();              
  
                this.changeDetectorRef.detectChanges();
              } else {
                M.toast({html: 'Beim Abrufen der Daten ist ein Fehler aufgetreten.', classes: 'red'});
              }
              
              setTimeout(() => {
                this.employee = null;
                this.changeDetectorRef.detectChanges();
                this.scan();

              }, 2000);

            } else {
              M.toast({html: 'Beim Abrufen der Daten ist ein Fehler aufgetreten.', classes: 'red'});
            }
    
          },
          response => {
            M.toast({html: 'Beim Abrufen der Daten ist ein Fehler aufgetreten.', classes: 'red'});
          },
          () => {
              //console.log("The POST observable is now completed.");
          });

      }

    }

  }

  playJingle() {
    /*
    if (this.employee.validity == 1) {
      this.nativeAudio.preloadSimple('checkin-ok', 'assets/sounds/checkin-ok.mp3').then(
        () => this.nativeAudio.play('checkin-ok', () => { this.nativeAudio.unload('checkin-ok'); })
      );

    } else {
      this.nativeAudio.preloadSimple('checkin-nok', 'assets/sounds/checkin-nok.mp3').then(
        () => this.nativeAudio.play('checkin-nok', () => { this.nativeAudio.unload('checkin-nok'); })
      );
    }
    */
  }

  logCheckin(): void {
    if (this.employee != null) {

      this.employeeService.logCheckin(this.employee)
      .subscribe(
        (statusResponse) => {

          if (statusResponse.status != 200) {
            M.toast({html: 'Beim Speichern der Daten ist ein Fehler aufgetreten.', classes: 'red'});
          }
  
        },
        response => {
          M.toast({html: 'Beim Speichern der Daten ist ein Fehler aufgetreten.', classes: 'red'});
        },
        () => {
            //console.log("The POST observable is now completed.");
        });

    }

  }

}
