<div class="container">
    <div class="section">

        <div class="row" *ngIf="document != null">
            <div class="col s12">
                <app-documentdetails-zz [document]="document" [integration]="false" *ngIf="document.class == 'ZZ'"></app-documentdetails-zz>
            </div>
        </div>

    </div>
</div>


