import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CustomComponent } from '@app/components/core/custom-component';
import { User } from '@app/models/user.model';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { StorageService } from '@app/services/storage.service';
import { UserService } from '@app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

declare let M: any;

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.css']
})
export class AccountProfileComponent extends CustomComponent implements OnInit {
 
  accountDetailsForm: FormGroup;
  @Input() userInput: User;
  action: string = 'view';
  submitted = false;
  succeeded = false;
  loading = false; 

  salutationData = [
    {'key': '', 'value': ''},
    {'key': '0', 'value': 'Keine Angabe'},
    {'key': '1', 'value': 'Herr'},
    {'key': '2', 'value': 'Frau'},
    {'key': '3', 'value': 'Divers'}
  ];

  titleData = [
    {'key': '', 'value': ''},
    {'key': 'dr', 'value': 'Dr.'},
    {'key': 'prof', 'value': 'Prof.'},
    {'key': 'prof_dr', 'value': 'Prof. Dr.'}
  ]

  constructor(
    private formBuilder: FormBuilder,
    protected override storageService: StorageService,
    protected userService: UserService,
    protected override router: Router
  ) { 
    super(storageService, router, true);
  }

  get adf() { return this.accountDetailsForm.controls; }

  ngOnInit(): void {
    this.submitted = false;
    this.succeeded = false;

    this.accountDetailsForm = this.formBuilder.group({
      salutation: new FormControl(''),
      title: new FormControl(''),
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.pattern(this.phonePattern)),
      street: new FormControl('', Validators.required),
      houseno: new FormControl('', Validators.required),
      zipcode: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      voice: new FormControl('')
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userInput']) {
      this.populateFormGroup(this.accountDetailsForm, this.userInput);
    }
  }

  view() {
    this.action = 'view';
  }

  edit(): void {
    this.action = 'edit';
  }

  cancel(): void  {
    this.accountDetailsForm.reset();
    this.populateFormGroup(this.accountDetailsForm, this.userInput);
    this.view();
  }

  save(): void {
    this.submitted = true;
    this.validateAllFormControl(this.accountDetailsForm);

    if (this.accountDetailsForm.invalid) {
        return;

    } else {
      this.populateObject(this.userInput, this.accountDetailsForm);
      this.updateUserDetail();
      this.view();
    }
  }

  updateUserDetail(): void {
    this.loading = true;
    const payload = {
      'user': this.userInput
    };

    this.userService.updateUserDetails(payload)
    .subscribe(
      (getUserResponse) => {
        if (getUserResponse.status == '200') {
          this.submitted = false;
          this.succeeded = true;
          this.action = 'view';
          M.toast({html: 'Ihre Daten wurden gespeichert.', classes: 'primary-bg'});
        }
      },
      response => {
        console.log(response);
      },
      () => {
        this.loading = false;
        //console.log("The POST observable is now completed.");
      });
  }
 
  titleString(): string {
    var returnvalue = '';

    for (const item of this.titleData) {
      if (item.key == this.userInput.title) {
        returnvalue = item.value;
      }
    }

    return returnvalue;
  }

  salutationString(): string {
    var returnvalue = '';

    for (const item of this.salutationData) {
      if (item.key == this.userInput.salutation) {
        returnvalue = item.value;
      }
    }

    return returnvalue;
  }

}
