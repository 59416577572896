import { Component, Input, OnInit, SimpleChanges,EventEmitter, Output } from '@angular/core';
import { CustomComponent } from '@app/components/core/custom-component';
import { Document } from '@app/models/document.model';
import { StorageService } from '@app/services/storage.service';
import { DocumentService } from '@app/services/document.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-documentlist',
  templateUrl: './documentlist.component.html',
  styleUrls: ['./documentlist.component.css']
})
export class DocumentlistComponent extends CustomComponent implements OnInit {

  @Input() documents: Array<Document>;
  @Input() total: number;
  @Input() folder: string;
  document = null;

  @Output()
  documentChangeEvent: EventEmitter<Document> = new EventEmitter<Document>();

  documentClassData = {
    'survey_universal': 'Universal',
    'STATION': 'Station',
    'ZMA': 'ZMA',
    'ZZ': 'ZZ'
  }

  constructor(
    protected override storageService: StorageService,
    protected documentService: DocumentService,
    protected override router: Router
  ) { 

    super(storageService, router, true);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.documents) {
      this.documents = changes.documents.currentValue;
    }
  }

  setDocument(document) {
    this.loadDocument(document);
  }

  loadDocument(document) {
    this.documentService.getDocument(document.guid)
    .subscribe(
      (getDocumentResponse) => {
        if (getDocumentResponse.document != null) {
          this.document = getDocumentResponse.document;
          if (this.isJSON(this.document.content)) {
            this.document.content = JSON.parse(this.document.content);
          }
          this.documentChangeEvent.emit(this.document);
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  className(document) {
    return this.documentClassData[document.class];

  }

}
