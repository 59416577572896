import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { AssetService } from '@app/services/asset.service';
import { ContactService } from '@app/services/contact.service';
import { StorageService } from '@app/services/storage.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignatureComponent } from '@app/components/core/signature/signature.component';

declare let cordova: any;
declare let M: any;

@Component({
  selector: 'app-asset-return',
  templateUrl: './asset-return.component.html',
  styleUrls: ['./asset-return.component.css']
})
export class AssetReturnComponent extends CustomComponent implements OnInit {

  assetlog = null;
  contact = null;
  signatureData = null;
  contacts = null;

  isLoading = false;
  assetlog_guid = null;

  constructor(
    protected override storageService: StorageService,
    protected contactService: ContactService,
    protected assetService: AssetService,
    protected override router: Router,
    protected route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog
  ) { 

    super(storageService, router, true);
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {
      this.assetlog_guid = queryParams.get("assetlog_guid");
      this.loadAssetlog();
    });
  }

  loadAssetlog() {
    this.assetService.getAssetlog(this.assetlog_guid)
    .subscribe(
      (getAssetlogResponse) => {
        if (getAssetlogResponse.status == 200 && getAssetlogResponse.assetlog) {
          this.assetlog = getAssetlogResponse.assetlog;
          this.ref.detectChanges();
        } else {
          M.toast({html: 'Objekt nicht gefunden!', classes: ' red lighten-2 white-text'});
        }
      },
      response => {
        M.toast({html: 'Es ist ein Fehler aufgetreten!', classes: ' red lighten-2 white-text'});
      },
      () => {
          //console.log("The POST observable is now completed.");
      }
    );
  }

  loadContact(guid) {
    this.contactService.getContact(guid)
    .subscribe(
      (getContactResponse) => {
        if (getContactResponse.status == 200 && getContactResponse.contact) {
          this.contact = getContactResponse.contact;
          this.ref.detectChanges();
        } else {
          M.toast({html: 'Kontakt nicht gefunden!', classes: ' red lighten-2 white-text'});
        }
      },
      response => {
        M.toast({html: 'Es ist ein Fehler aufgetreten!', classes: ' red lighten-2 white-text'});
      },
      () => {
          //console.log("The POST observable is now completed.");
      }
    );    
  }

  scan(assetClass): void {

    cordova.plugins.barcodeScanner.scan(
      result => {

        try {
          var scanData = JSON.parse(result.text);

          if (scanData.class == "contact") {
            this.loadContact(scanData.guid);
          }

        } catch (e) {
          M.toast({html: 'Das Scannen ist fehlgeschlagen!', classes: ' red lighten-2 white-text'});
        }
      },
      err => {
        //console.error(err);
        M.toast({html: 'Das Scannen ist fehlgeschlagen!', classes: ' red lighten-2 white-text'});
      },
      {
        preferFrontCamera : false, // iOS and Android
        showFlipCameraButton : true, // iOS and Android
        showTorchButton : true, // iOS and Android
        torchOn: false, // Android, launch with the torch switched on (if available)
        saveHistory: true, // Android, save scan history (default false)
        prompt : "QR-Code", // Android
        resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        formats : "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
        //orientation : "landscape", // Android only (portrait|landscape), default unset so it rotates with the device
        disableAnimations : true, // iOS
        disableSuccessBeep: false // iOS and Android
      }
    );

  }

  save() {

    var errorFields = [];

    if (this.contact == null) {
      errorFields.push("Mitarbeiter/In");
    }

    if (errorFields.length == 0) {
      this.returnAsset();
    } else {
      var errorMessage = "Folgende Felder sind Pflichtfelder:<br><br>" + errorFields.join(", ");
      M.toast({html: errorMessage, classes: ' red lighten-2 white-text'});
    }    
  
  }

  returnAsset() {
    this.assetService.returnAsset(this.assetlog, this.contact)
      .subscribe(
        (issueAssetResponse) => {

          if (issueAssetResponse.status == 200) {
            M.toast({html: 'Die Daten wurden erfolgreich gespeichert.', classes: 'primary-bg'});
            this.router.navigateByUrl('/assets/logs');
          } else {
            M.toast({html: 'Beim Speichern ist ein Fehler aufgetreten.', classes: 'red'});
          }
  
        },
        response => {
          M.toast({html: 'Beim Speichern ist ein Fehler aufgetreten.', classes: 'red'});
          console.log(response);
        },
        () => {
            //console.log("The POST observable is now completed.");
        });
  }

  keyupContact(event:any) {
    if (!event.target.value || event.target.value.length <= 2) {
        this.contacts = [];     

    } else if (event.target.value.length > 2) {
        this.search(event.target.value);
    }
  }

  onClickSuggestion(event, contact) {
    this.contact = contact;
  }

  search(q) {
    this.contactService.searchContacts(q)
    .subscribe(
      (searchContactResponse) => {
        if (searchContactResponse.status == 200) {
          this.contacts = searchContactResponse.contacts;
          this.contacts.push({'id': 0, 'guid': 0, 'firstname': q, 'lastname': ''});
        }
      },
      response => {
        M.toast({html: 'Es ist ein Fehler aufgetreten!', classes: ' red lighten-2 white-text'});
      },
      () => {
          //console.log("The POST observable is now completed.");
      }
    );    

    this.isLoading = false;

  }

  displayFn(contact) {
    return "";
  }

}
