import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { GetDocumentResponse } from '@app/models/responses/getDocumentResponse.model';
import { GetDocumentsResponse } from '@app/models/responses/getDocumentsResponse.model';
import { UpdateDocumentResponse } from '@app/models/responses/updateDocumentResponse.model';
import { DeleteDocumentResponse } from '@app/models/responses/deleteDocumentResponse.model';
import { LockDocumentResponse } from '@app/models/responses/lockDocumentResponse.model';
import { CreateDocumentResponse } from '@app/models/responses/createDocumentResponse.model';
import { GetDocumentScansResponse } from '@app/models/responses/getDocumentScansResponse.model';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private headers = null;
  private options = {};

  constructor(
    private http: HttpClient

  ) { 
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    this.options = {
      headers: this.headers
    }
  }

  getDocuments(documentclass, page, pageSize, status) {
    let params = new HttpParams();
    if (documentclass) {
      params = params.append('class', documentclass);
    }
    params = params.append('status', status);
    params = params.append('page', page);
    params = params.append('page_size', pageSize);
    this.options['params'] = params;

    return this.http.get<GetDocumentsResponse>(environment.apiEndpoint + '/api/documents/1.0.0.php', this.options);
  }

  getDocument(guid) {
    let params = new HttpParams();
    params = params.append('guid', guid);
    this.options['params'] = params;

    return this.http.get<GetDocumentResponse>(environment.apiEndpoint + '/api/document/1.0.0.php', this.options);
  }

  getDocumentScans(guid) {
    let params = new HttpParams();
    params = params.append('guid', guid);
    this.options['params'] = params;

    return this.http.get<GetDocumentScansResponse>(environment.apiEndpoint + '/api/document/scans/1.0.0.php', this.options);
  }

  createDocument(payload: object) {
    const body = payload;
    return this.http.post<CreateDocumentResponse>(environment.apiEndpoint + '/api/document/1.0.0.php', body, this.options);
  }

  updateDocument(payload: object) {
    const body = payload;
    return this.http.put<UpdateDocumentResponse>(environment.apiEndpoint + '/api/document/1.0.0.php', body, this.options);
  }

  deleteDocument(guid) {
    let params = new HttpParams();
    params = params.append('guid', guid);
    this.options['params'] = params;

    return this.http.delete<DeleteDocumentResponse>(environment.apiEndpoint + '/api/document/1.0.0.php', this.options);
  }

  lockDocument(guid) {
    const body = { 
      'guid': guid
    };

    return this.http.post<LockDocumentResponse>(environment.apiEndpoint + '/api/document/lock/1.0.0.php', body, this.options);
  }

  uploadDocument(payload: object) {
    const body = payload;
    return this.http.post(environment.apiEndpoint + '/api/document/upload/1.0.0.php', body, 
      {
        reportProgress: true,
        observe: 'events',
        'headers': this.headers
      });
  }

}
