import { Component } from '@angular/core';

declare let M: any;
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'maihealth';

  constructor() { }

  ngAfterContentChecked(): void {

    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('select');
      var options = document.querySelectorAll('option');
      var instances = M.FormSelect.init(elems, options);

      $(".modal").modal();
      $(".tabs").tabs();

      $(".dropdown-trigger").dropdown();
    });

  }
}
