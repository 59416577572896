import { Component, Input, OnInit } from '@angular/core';
import { CustomComponent } from '@app/components/core/custom-component';
import { User } from '@app/models/user.model';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from '@app/validators/custom-validators';
import { StorageService } from '@app/services/storage.service';
import { UserService } from '@app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

declare let M: any;

@Component({
  selector: 'app-account-password',
  templateUrl: './account-password.component.html',
  styleUrls: ['./account-password.component.css']
})
export class AccountPasswordComponent extends CustomComponent implements OnInit {

  @Input() userInput: User;
  accountPasswordForm: FormGroup;
  submitted = false;
  succeeded = false;
  loading = false;
  action = 'view';

  constructor(
    private formBuilder: FormBuilder,
    protected override storageService: StorageService,
    protected userService: UserService,
    protected override router: Router
  ) { 
    super(storageService, router, true);
  }

  get apf() { return this.accountPasswordForm.controls; }

  ngOnInit(): void {
    this.submitted = false;
    this.succeeded = false;

    this.accountPasswordForm = this.formBuilder.group({
      password: new FormControl('', [
        Validators.required,
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        //CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        Validators.minLength(8)
      ]), 
      confirmPassword: new FormControl('', [Validators.required])
    },
    {
      // check whether our password and confirm password match
      validator: [
        CustomValidators.passwordMatchValidator,
      ]
    });
  }

  submit() {
    this.submitted = true;

    if (this.accountPasswordForm.invalid) {
      return;
      
    } else {
      this.changePassword();
    }

  }

  view() {
    this.action = 'view';
  }

  edit() {
    this.action = 'edit';
  }


  cancel(): void  {
    this.accountPasswordForm.reset();
    this.populateFormGroup(this.accountPasswordForm, this.userInput);
    this.view();
  }

  changePassword(): void {
    this.loading = true;
    const payload = this.accountPasswordForm.value;
    payload['user'] = this.user;
    this.userService.updateUserPassword(payload)
    .subscribe(
      (getUserResponse) => {
        if (getUserResponse.status == '200') {
          this.submitted = false;
          this.succeeded = true;
          this.action = 'view';
          M.toast({html: 'Ihr Passwort wurde gespeichert.', classes: 'primary-bg'});
        }
      },
      response => {
        console.log(response);
      },
      () => {
        this.loading = false;
        //console.log("The POST observable is now completed.");
      });
  }

}
