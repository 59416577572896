import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { GetReportsResponse } from '@app/models/responses/getReportsResponse.model';

import { environment } from 'src/environments/environment';
import { GetAssetsReportsResponse } from '@app/models/responses/getAssetsReportsResponse.model';
import { GetCheckinsReportsResponse } from '@app/models/responses/getCheckinsReportsResponse.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private headers = null;
  private options = {};
  
  datesChanged: EventEmitter<Array<Date>> = new EventEmitter<Array<Date>>();
  reportDataChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private http: HttpClient

  ) { 
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    this.options = {
      headers: this.headers
    }
  }

  getReports(data) {
    let params = new HttpParams();
    Object.entries(data).forEach(([key, value]) => {
      params = params.append(key, value.toString());
    });
    this.options['params'] = params;

    return this.http.get<GetReportsResponse>(environment.apiEndpoint + '/api/reports/1.0.0.php', this.options);
  }

}
