import { Component, Input, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { CustomComponent } from '@app/components/core/custom-component';
import { StorageService } from '@app/services/storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportService } from '@app/services/report.service';
import { Subscription } from 'rxjs';
import { Document } from '@app/models/document.model';

@Component({
  selector: 'app-assetreportlist',
  templateUrl: './assetreportlist.component.html',
  styleUrls: ['./assetreportlist.component.css']
})
export class AssetreportlistComponent extends CustomComponent implements OnInit {

  @Input() date;
  @Input() todate;
  @Input() reportData = null;
  private reportDataSub: Subscription;
  historyData = {};

  @Output()
  documentChangeEvent: EventEmitter<Document> = new EventEmitter<Document>();

  constructor(
    protected reportService: ReportService,
    protected override storageService: StorageService,
    protected override router: Router
  ) { 
    super(storageService, router, true);
  
  }

  ngOnInit(): void {
  }

  getSignatureData(signature) {
    if (signature) {
      var data = JSON.parse(signature);

      if (data != null) {
        return data.png;
      }
    }

    return null;
  }

  getStatusString(assetlog) {
    if (assetlog.status == 1) {
      return "Zurückgenommen";
    } else {
      return "Ausgeliehen";
    }
  }

  reload(): void {
    var dates = [this.date, this.todate];
    this.reportService.datesChanged.emit(dates);
  }

  addToHistory(): void {
    var item = <HTMLElement> document.getElementById('resultTable');
    var clone = <HTMLElement> item.cloneNode(true);

    var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    var key = '<b>' + this.date.toLocaleDateString("de-DE", options) + '</b> bis <b>' + this.todate.toLocaleDateString("de-DE", options) + '</b>';
    this.historyData[key] = clone.outerHTML;
  }

  deleteHistory(key) {
    delete this.historyData[key];
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

}
