<div class="bodyPadding"></div>

<nav class="fixedtop topbar navigation" role="navigation">
    <div class="nav-wrapper container">
        
        <div id="app-logo">
            <a id="logo-container" routerLink="/" class="brand-logo">
                <!--img src="assets/images/logos/header.png" height="40" style="padding-top: 10px;"-->
                <!--i class="mdi mdi-robot-outline">mAIsolution</i-->
                mAIsolution
            </a>
        </div>
        
        <div id="headerNaviContainer">
            <app-navigation></app-navigation>

            <div id="linkNavigation" gaCategory="comp_top_nav">
                <div *ngIf="isLoggedIn() == false">
                    <ul class="tabs">
                        <li class="tab"><a routerLink="/">Startseite</a></li>
                        <li class="tab"><a routerLink="/login">Einloggen</a></li>
                    </ul>
                </div>
                <div *ngIf="isLoggedIn() == true">
                    <ul class="tabs">
                        <li class="tab"><a routerLink="/">Startseite</a></li>
                        <li class="tab" *ngIf="hasPermissions('calls');"><a routerLink="/calls" routerLinkActive="active">Anrufe</a></li>
                        <li class="tab" *ngIf="hasPermissions('config');"><a routerLink="/config" routerLinkActive="active">Einstellungen</a></li>
                        <li class="tab" *ngIf="hasPermissions('reports');"><a routerLink="/stats" routerLinkActive="active">Reports</a></li>
                        <li class="tab"><a routerLink="/account" routerLinkActive="active">Mein Konto</a></li>
                        <li class="tab"><a routerLink="/logout" routerLinkActive="active">Ausloggen</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <br clear="all">
    </div>
</nav>
