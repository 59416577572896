<div class="card-panel">
    <div class="row no-margin">
        <div class="col s12 m12 l6">
            <button mat-button class="toolbar-btn" (click)="setDataRange(0)"><i class="mdi mdi-check" *ngIf="getDataRange()=='Stunde'"></i> Stunde</button>
            <button mat-button class="toolbar-btn" (click)="setDataRange(1)"><i class="mdi mdi-check" *ngIf="getDataRange()=='Tag'"></i> Tag</button>
            <button mat-button class="toolbar-btn" (click)="setDataRange(2)"><i class="mdi mdi-check" *ngIf="getDataRange()=='Woche'"></i> Woche</button>
            <button mat-button class="toolbar-btn" (click)="setDataRange(3)"><i class="mdi mdi-check" *ngIf="getDataRange()=='Monat'"></i> Monat</button>
            <button mat-button class="toolbar-btn" (click)="setDataRange(4)"><i class="mdi mdi-check" *ngIf="getDataRange()=='Jahr'"></i> Jahr</button>
        </div>
        <!--div class="col s8 m10 pad-0">
            <div class="z-depth-1" style="padding: 10px;">
                {{getDataRange()}}
            </div>
        </div>

        <div class="col s4 m2 pad-0 right-align">
            <div style="margin-top: 7px;">
                <button class="btn waves-effect waves-light bg-primary" type="submit" name="action" (click)="clickRangeLeft()">
                    <i class="mdi mdi-arrow-left"></i>
                </button>
                &nbsp;
                <button class="btn waves-effect waves-light bg-primary" type="submit" name="action" (click)="clickRangeRight()">
                    <i class="mdi mdi-arrow-right"></i>
                </button>
            </div>
        </div-->

        <div class="col s8 l4">
            <div class="z-depth-1 center-align" style="padding: 5px;">
                <span *ngIf="dateRangeSelection == 0">{{date | date:'dd.MM.yyyy H:mm'}}</span>
                <span *ngIf="dateRangeSelection == 1">{{date | date:'dd.MM.yyyy'}}</span>
                <span *ngIf="dateRangeSelection == 2">{{date | date:'dd.MM.yyyy'}} - {{todate | date:'dd.MM.yyyy'}}</span>
                <span *ngIf="dateRangeSelection == 3">{{date | date:'MM.yyyy'}}</span>
                <span *ngIf="dateRangeSelection == 4">{{date | date:'yyyy'}}</span>
            </div>
        </div>

        <div class="col s4 l2 right-align">
            <div style="margin-top: 3px;">
                <button class="btn waves-effect waves-light bg-primary" type="submit" name="action" (click)="clickDateLeft()">
                    <i class="mdi mdi-arrow-left"></i>
                </button>
                <button class="btn waves-effect waves-light bg-primary" type="submit" name="action" (click)="clickDateRight()">
                    <i class="mdi mdi-arrow-right"></i>
                </button>
            </div>
        </div>

    </div>
</div>
