import { Component, OnInit, ViewChild, Injectable, NgModule } from '@angular/core';
import { CustomComponent } from '@app/components/core/custom-component';
import { StorageService } from '@app/services/storage.service';
import { DocumentService } from '@app/services/document.service';
import { Document } from '@app/models/document.model';
import { DocumentContentStation } from '@app/models/documentcontent_station.model';
import { DocumentContentZMA } from '@app/models/documentcontent_zma.model';
import { DocumentContentZZ } from '@app/models/documentcontent_zz.model';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

declare let M: any;
declare let $: any;

@Component({
  selector: 'app-documents-page',
  templateUrl: './documents-page.component.html',
  styleUrls: ['./documents-page.component.css']
})
export class DocumentsPageComponent extends CustomComponent implements OnInit {

  documentsToCheck = null;
  documentsCommited = null;
  totalDocumentsToCheck = null;
  totalDocumentsCommited = null;
  pageSize = 10;
  pageToCheck = 0;
  pageCommited = 0;
  document = null;
  pageSizeOptions: number[] = [10, 25, 50];

  documentClass = new FormControl();
  documentClassData = [
    {'key': 'Alle', 'value': ''},
    {'key': 'Universal', 'value': 'survey_universal'},
    {'key': 'Station', 'value': 'STATION'},
    {'key': 'ZMA', 'value': 'ZMA'},
    {'key': 'ZZ', 'value': 'ZZ'}
  ]

  constructor(
    protected override storageService: StorageService,
    protected documentService: DocumentService,
    protected override router: Router
  ) { 

    super(storageService, router, false);
  }

  ngOnInit(): void {
    this.loadDocuments();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  pageToCheckChanged(event) {
    this.pageSize = event.pageSize;
    this.pageToCheck = event.pageIndex;
    this.getDocumentsToCheck();
  }

  pageCommitedChanged(event) {
    this.pageSize = event.pageSize;
    this.pageCommited = event.pageIndex;
    this.getDocumentsCommited();
  }

  documentClassesChanged(event) {
    this.pageCommited = 0;
    this.pageToCheck = 0;
    this.loadDocuments();
  }

  loadDocuments() {
    this.getDocumentsToCheck();
    this.getDocumentsCommited();
  }

  unsetDocument() {
    this.document = null;
  }

  getDocumentsToCheck() {
    this.documentsToCheck = null;
    this.documentService.getDocuments(this.documentClass.value, this.pageToCheck, this.pageSize, 0)
    .subscribe(
      (getDocumentsResponse) => {
        if (getDocumentsResponse.documents != null) {
          this.documentsToCheck = getDocumentsResponse.documents;
          this.totalDocumentsToCheck = getDocumentsResponse.total;
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  getDocumentsCommited() {
    this.documentsCommited = null;
    this.documentService.getDocuments(this.documentClass.value, this.pageCommited, this.pageSize, 1)
    .subscribe(
      (getDocumentsResponse) => {
        if (getDocumentsResponse.documents != null) {
          this.documentsCommited = getDocumentsResponse.documents;
          this.totalDocumentsCommited = getDocumentsResponse.total;
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  documentChanged(event) {
    this.document = event;
    this.loadDocuments();
  }

  @ViewChild('documentsContent') set documentsContent(element) {
    if (element) {
      M.AutoInit();
    }
  }

  createDocument(name) {
    this.document = {} as Document;

    var dateTmp = new Date();
    var date = dateTmp.getFullYear() + "" + (((dateTmp.getMonth() + 1) < 10) ? '0' : '') + (dateTmp.getMonth() + 1) + "" + ((dateTmp.getDate() < 10) ? '0' : '') + dateTmp.getDate() + 
               "_" + dateTmp.getHours() + dateTmp.getMinutes();
    var number = Math.floor((Math.random() * 9000) + 1000);
    this.document.name = date + "_" + number;

    if (name == 'station') {
      this.document.class = 'STATION';
      this.document.content = {} as DocumentContentStation;
    } else if (name == 'zma') {
      this.document.class = 'ZMA';
      this.document.content = {} as DocumentContentZMA;
    } else if (name == 'zz') {
      this.document.class = 'ZZ';
      this.document.content = {} as DocumentContentZZ;
    } else {
      this.document.class = name;
      this.document.content = {};
    }

  }

}

