Auf dieser Seite können Sie ein gescanntes PDF hochladen!<br>

<br>

<div id="item_documents'" class="card margin-top-0">
    <div class="card-content">

        <form [formGroup]="documentAssetForm">
            <div class="card documentAssetsCard" *ngIf="documentAssets.length > 0">
                <span class="card-title"></span>
          
                <div class="card-content">
                    <div class="row documentAssetsRow no-margin" *ngFor="let documentAsset of documentAssets; let i = index">
                        <div class="col s12 center-align" *ngIf="uploadProcessing === true">
                            <br><br>
                            <div id="preloader"></div>
                            <br>
                                <div class="center-align" *ngIf="uploadProgress != 100">Ihre Daten werden hochgeladen ({{uploadProgress}}%)...</div>
                                <div class="center-align" *ngIf="uploadProgress == 100">Ihre Daten werden verarbeitet...</div>
          
                            <div class="progress green lighten-4 pad-top-15">
                                <div class="determinate green lighten-2" style="width: {{uploadProgress}}%"></div>
                            </div>
                        </div> 
                        
                        <span *ngIf="uploadProcessing === false">
                        <div class="col s12">
                            <strong>{{documentAssetsMetadata[i].name}}</strong>
                            <span class="green-text"> ({{documentAssetsMetadata[i].type}}, {{documentAssetsMetadata[i].size}})</span>
                        </div>
                        <div class="input-field col s12">
                            <p *ngIf="submitted && daf.class.errors" class="red-text"> 
                                Eine Auswahl ist notwendig!
                            </p> 
                        </div> 
                        <div class="input-field col s12 center-align">
                            <a class="waves-effect waves-dark btn-large white green-text" (click)="deleteDocumentAsset(i);"><i class="mdi mdi-close-box-outline left"></i>Abbrechen</a>
                            &nbsp;
                            <a class="waves-effect waves-light btn-large primary-bg" (click)="upload(i);"><i class="mdi mdi-upload left"></i>Hochladen</a>
                        </div>
                        </span>
                    </div>
                </div>
          
            </div>
          
            <div class="row pad-top-15" *ngIf="uploadProcessing == false">
                <div class="col s12 dropify-wrapper">
                    <div class="dropify-message green-text">
                        <i class="mdi mdi-cloud-outline icon"></i>
                        <p [ngClass]="{'red-text': submitted && daf.file.errors }">Datei per Drag & Drop oder Klick hochladen</p>
                        <p *ngIf="submitted && daf.file.invalid" [ngClass]="{'red-text': submitted && daf.file.errors }"> 
                            Eine Auswahl ist notwendig!
                        </p>
                    </div>
                    <input type="file" accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" formControlName="file" (change)="onDocumentFileChange($event)" class="dropify"/>
                </div>
            </div>
          </form>
              
    </div>
</div>
