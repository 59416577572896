import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentService } from '@app/services/document.service';
import { ReportService } from '@app/services/report.service';
import { StorageService } from '@app/services/storage.service';
import { StatsPageComponent } from '../stats-page/stats-page.component';

@Component({
  selector: 'app-stats-checkins-page',
  templateUrl: './stats-checkins-page.component.html',
  styleUrls: ['./stats-checkins-page.component.css']
})
export class StatsCheckinsPageComponent extends StatsPageComponent implements OnInit {

  constructor(
    protected override reportService: ReportService,
    protected override storageService: StorageService,
    protected override documentService: DocumentService,
    protected override router: Router
  ) { 
    super(reportService, storageService, documentService, router);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override loadReports(): void {
    var fromDate = this.getDateTime(this.date);
    var toDate = this.getDateTime(this.todate);

    var filter = {
      'from': fromDate,
      'to': toDate,
    };
    this.reportService.getCheckinsReports(filter)
    .subscribe(
      (getCheckinsReportsResponse) => {
        if (getCheckinsReportsResponse.status == 200) {
          this.reportData = getCheckinsReportsResponse.data;
          this.reportService.reportDataChanged.emit(this.reportData);
        }

      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

}
