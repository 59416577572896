import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DocumentService } from '@app/services/document.service';
import { StorageService } from '@app/services/storage.service';
import { DocumentdetailsComponent } from '../../documentdetails/documentdetails.component';
import { MatRadioModule } from '@angular/material/radio'; 

declare let M: any;
declare let $: any;

@Component({
  selector: 'app-documentdetails-wke-universal',
  templateUrl: './documentdetails-wke-universal.component.html',
  styleUrls: ['./documentdetails-wke-universal.component.css']
})
export class DocumentdetailsWkeUniversalComponent extends DocumentdetailsComponent implements OnInit {

  step = 0;

  public infoList = {
    'homepage': 'Homepage', 
    'familie': 'Familie/Freunde', 
    'portale': 'Internetportale', 
    'brochuere': 'Werbematerialien', 
    'website': 'Webseite'
  }

  constructor(
    protected override storageService: StorageService,
    protected override documentService: DocumentService,
    protected override formBuilder: FormBuilder, 
    protected override router: Router,
    protected datePipe: DatePipe
  ) { 

    super(storageService, documentService, formBuilder, router);
  }

  returnZero(): number {
    return 0;
  }

  override ngOnInit(): void {
    if (this.integration == false) {
      this.action = 'edit';
    }

    this.documentForm = this.formBuilder.group({
      month: new FormControl(null, [Validators.required]),
      year: new FormControl('', [Validators.required, Validators.pattern(this.numberPattern), Validators.min(1900), Validators.max(2050)]),
      days: new FormControl('', [Validators.required, Validators.pattern(this.numberPattern), Validators.min(1), Validators.max(365)]),
      level: new FormControl(null),
      department: new FormControl(null),
      //referrer: new FormControl(null),
      info: new FormControl(null, [Validators.required]),
      infoList: new FormControl(null),
      waitingtime_hours: new FormControl('', [Validators.pattern(this.numberPattern), Validators.min(0), Validators.max(24)]),
      waitingtime_minutes: new FormControl('', [Validators.pattern(this.numberPattern), Validators.min(0), Validators.max(59)]),
      age: new FormControl('', [Validators.pattern(this.numberPattern), Validators.min(1), Validators.max(120)]),
      gender: new FormControl(null),
      comment_positive: new FormControl(null),
      comment_negative: new FormControl(null),
      q1_1: new FormControl(null),
      q1_2: new FormControl(null),
      q1_3: new FormControl(null),
      q1_4: new FormControl(null),
      q2_1: new FormControl(null),
      q2_2: new FormControl(null),
      q2_3: new FormControl(null),
      q2_4: new FormControl(null),
      q2_5: new FormControl(null),
      q2_6: new FormControl(null),
      q2_7: new FormControl(null),
      q2_8: new FormControl(null),
      q2_9: new FormControl(null),
      q2_10: new FormControl(null),
      q2_11: new FormControl(null),
      q2_12: new FormControl(null),
      q2_13: new FormControl(null),
      q2_14: new FormControl(null),
      q2_15: new FormControl(null),
      q2_16: new FormControl(null),
      q2_17: new FormControl(null),
      q2_18: new FormControl(null),
      q2_19: new FormControl(null),
      q2_20: new FormControl(null),
      q2_21: new FormControl(null),
      q3_1: new FormControl(null),
      q3_2: new FormControl(null),
      q3_3: new FormControl(null),
      q3_4: new FormControl(null),
      q3_5: new FormControl(null),
      q3_6: new FormControl(null),
      q3_7: new FormControl(null),
      q3_8: new FormControl(null),
      q4_1: new FormControl(null)
    });

    this.populateDocumentForm();
  }

  next(): void {
    this.submitted = true;
    this.setStep(this.step + 1);
  }

  previous(): void {
    this.submitted = true;
    this.setStep(this.step - 1);
  }

  setStep(step): void {
    this.submitted = true;
    
    if (this.documentForm.invalid) {
      return;

    } else {
      this.step = step;
      this.submitted = false;
    }
  }

  override populateDocumentForm() {
    this.populateFormGroup(this.documentForm, this.document.content);

    if (!this.document.guid) {
      this.documentForm.patchValue({
        'month': (new Date()).getMonth() + 1,
        'year': (new Date()).getFullYear()
      });
    }
  }

  save() {
    this.submitted = true;

    if (this.documentForm.invalid) {
        return;

    } else {
      this.document.content = this.documentForm.value;

      if (this.document.guid) {
        this.updateDocument();
      } else {
        this.createDocument();
      }

    }
  }

}
