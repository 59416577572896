import { Component, OnInit } from '@angular/core';
import { StorageService } from '@app/services/storage.service';
import { User } from '@app/models/user.model';
import { CustomComponent } from '@app/components/core/custom-component';
import { Router } from '@angular/router';

declare let $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends CustomComponent implements OnInit {

  constructor(
    protected override storageService: StorageService,
    protected override router: Router
  ) { 
    super(storageService, router, false);
  }

  ngOnInit(): void {
    //this.fixNotch();
  }

  fixNotch() {

    if ($('nav.fixedtop')) {
      var containerPadding = parseInt($('nav.fixedtop .container').css('padding-top'));

      if (containerPadding > 0) {
        $('body').css({
          'padding-top': (containerPadding*2) + 'px'
        });

        var containerPaddingHalf = containerPadding / 2;
        $('nav.fixedtop .container').css({
          'padding-top': containerPaddingHalf + 'px'
        });

        var newHeight = parseInt($('nav.fixedtop').css('height')) + containerPaddingHalf;
        $('nav.fixedtop').css({
          'height': newHeight + 'px'
        });
      
      } else {

        $('body').css({
          'padding-top': '20px'
        });

      }
    }
 
  }  

  override isLoggedIn(): boolean {
    return (this.storageService.getUser() != null);
  }

}
