<div *ngIf="contacts == null">
    <div id="preloader"></div>
</div>

<div *ngIf="contacts != null">
    
    <div class="contacts grey-text valign-wrapper" *ngIf="contacts.length == 0">
        <i class="mdi mdi-account-off small"></i> Keine Kontakte
    </div>

    <ul class="contacts" *ngIf="contacts.length > 0">
        <li *ngFor="let contact of contacts" class="valign-wrapper">
            <i class="mdi mdi-account-check small"></i> {{contact.firstname}} {{contact.lastname}}<br>
        </li>
    </ul>

</div>

