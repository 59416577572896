import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { ReportService } from '@app/services/report.service';
import { StorageService } from '@app/services/storage.service';
import {Subscription} from 'rxjs';

declare let M: any;

@Component({
  selector: 'app-commentlist',
  templateUrl: './commentlist.component.html',
  styleUrls: ['./commentlist.component.css']
})
export class CommentlistComponent extends CustomComponent implements OnInit {

  @Input() date;
  @Input() todate;
  @Input() reportData = null;
  private reportDataSub: Subscription;

  constructor(
    protected reportService: ReportService,
    protected override storageService: StorageService,
    protected override router: Router
  ) { 
    super(storageService, router, true);
  
  }

  ngOnInit(): void {
    this.reportDataSub = this.reportService.reportDataChanged.subscribe(
      data => {
        this.reportData = data;
      }
    );
  }

  ngOnDestroy() {
    if (this.reportDataSub) {
      this.reportDataSub.unsubscribe()
    }
  }

  @ViewChild('documentsContent') set documentsContent(element) {
    if (element) {
      M.AutoInit();
    }
  }

}
