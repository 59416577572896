import { Component, OnInit } from '@angular/core';
import { StorageService } from '@app/services/storage.service';
import { Router } from '@angular/router';
import { User } from '@app/models/user.model';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private storageService: StorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.storageService.deleteUser();
    this.router.navigateByUrl('/');
  }

}
