import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { User } from '@app/models/user.model';
import { GetUserResponse } from '@app/models/responses/getUserResponse.model';
import { ResetPasswortResponse } from '@app/models/responses/resetPasswortResponse.model';
import { environment } from 'src/environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class UserService {

  private headers = null;
  private options = {};

  constructor(
    private http: HttpClient

  ) { 
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    this.options = {
      headers: this.headers
    }
  }
 
  createUser(form: FormGroup) {
    const body = form.value;
    body['type'] = 'landlord';
    return this.http.post<User>(environment.apiEndpoint + '/api/user/1.0.0.php', body, this.options);
  }

  getUser(data) {
    let params = new HttpParams();
    Object.entries(data).forEach(([key, value]) => {
      params = params.append(key, value.toString());
    });
    this.options['params'] = params;

    return this.http.get<GetUserResponse>(environment.apiEndpoint + '/api/user/1.0.0.php', this.options);
  }

  login(form: FormGroup) {
    const body = form.value;
    var result = this.http.post<GetUserResponse>(environment.apiEndpoint + '/api/user/login/1.0.0.php', body, this.options);
    return result;
  }

  resetPassword(form: FormGroup) {
    const body = form.value;
    var result = this.http.post<ResetPasswortResponse>(environment.apiEndpoint + '/api/user/reset/1.0.0.php', body, this.options);
    return result;
  }

  activate(guid: string) {
    const body = { 'guid': guid };
    var result = this.http.post<GetUserResponse>(environment.apiEndpoint + '/api/user/activate/1.0.0.php', body, this.options);
    return result;
  }

  deleteUser(data) {
    const body = data;
    var result = this.http.post<GetUserResponse>(environment.apiEndpoint + '/api/user/delete/1.0.0.php', body, this.options);
    return result;
  }

  updateUserPassword(data) {
    const body = data;
    var result = this.http.post<GetUserResponse>(environment.apiEndpoint + '/api/user/password/1.0.0.php', body, this.options);
    return result;
  }

  updateUserProfilImage(user) {
    const body = {
      'user': user
    };

    var result = this.http.post<GetUserResponse>(environment.apiEndpoint + '/api/user/profileimage/1.0.0.php', body, {
      reportProgress: true,
      observe: 'events',
      'headers': this.headers
    });

    return result;
  }
  
  updateUserDetails(data) {
    const body = data;
    var result = this.http.post<GetUserResponse>(environment.apiEndpoint + '/api/user/details/1.0.0.php', body, this.options);
    return result;
  }

  updateUserNotifications(data) {
    const body = data;
    var result = this.http.post<GetUserResponse>(environment.apiEndpoint + '/api/user/notifications/1.0.0.php', body, this.options);
    return result;
  }

}
