<div class="container">
    <div class="section">
    
        <h1 class="bot-20 sec-tit">Reports (Station)</h1>

        <div *ngIf="document == null">
            <app-report-date-selector 
                [date]="date" [todate]="todate">
            </app-report-date-selector>

            <div *ngIf="reportData != null && reportData.documents != null">
                <div class="card-panel">
            
                    <div class="left">
                        <div class="red-text" *ngIf="reportData.documents.length == 0">
                            Für diesen Zeitraum liegen keine Daten vor.
                        </div>
                        <div *ngIf="reportData.documents.length > 0">
                            Für diesen Zeitraum liegen <b>{{reportData.documents.length}}</b> Datensätze vor.
                        </div>
                    </div>
            
                    <div class="right">
                        <div class="left pad-right-10">
                            <select class="browser-default custom-select" [(ngModel)]="selectedLevel" (change)="levelfilterChanged($event)">
                                <option value="all">Alle</option>
                                <option value="null">Ohne Angabe</option>
                                <option *ngFor="let level of levelData" [ngValue]="level.key">{{level.value}}</option>
                            </select>
                        </div>
            
                        <div class="right">
                            <button class="btn waves-effect waves-light white grey-text margin-right-5" type="submit" name="action" title="Reload" (click)="reload()">
                                <i class="mdi mdi-reload"></i>
                            </button>
                            <!--button class="btn waves-effect waves-light white grey-text" type="submit" name="action" title="Vergleichen" (click)="addToHistory()">
                                <i class="mdi mdi-timetable"></i>
                            </button-->
                        </div>
                    </div>
            
                    <br>
                </div>
            </div>

            <app-commentlist
                [date]="date" [todate]="todate" [reportData]="reportData">
            </app-commentlist>
        </div>

        <!--div *ngIf="document != null">
            <div class="row">
                <div class="col s12">
                    <app-documentdetails-zma [document]="document" (documentChangeEvent)="documentChanged($event)" *ngIf="document.class == 'ZMA'"></app-documentdetails-zma>
                    <app-documentdetails-station [document]="document" (documentChangeEvent)="documentChanged($event)" *ngIf="document.class == 'STATION'"></app-documentdetails-station>
                    <app-documentdetails-zz [document]="document" (documentChangeEvent)="documentChanged($event)" *ngIf="document.class == 'ZZ'"></app-documentdetails-zz>
                </div>
            </div>
        </div-->

    </div>
</div>
