import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { DocumentService } from '@app/services/document.service';
import { ReportService } from '@app/services/report.service';
import { StorageService } from '@app/services/storage.service';
import { Subscription } from 'rxjs';
import { StatsPageComponent } from '../stats-page/stats-page.component';

@Component({
  selector: 'app-stats-assets-page',
  templateUrl: './stats-assets-page.component.html',
  styleUrls: ['./stats-assets-page.component.css']
})
export class StatsAssetsPageComponent extends StatsPageComponent implements OnInit {

  constructor(
    protected override reportService: ReportService,
    protected override storageService: StorageService,
    protected override documentService: DocumentService,
    protected override router: Router
  ) { 
    super(reportService, storageService, documentService, router);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override loadReports(): void {
    var fromDate = this.getDateTime(this.date);
    var toDate = this.getDateTime(this.todate);

    var filter = {
//      'user_guid': this.user.guid,
      'from': fromDate,
      'to': toDate,
    };
    this.reportService.getAssetsReports(filter)
    .subscribe(
      (getAssetsReportsResponse) => {
        if (getAssetsReportsResponse.status == 200) {
          this.reportData = getAssetsReportsResponse.data;
          this.reportService.reportDataChanged.emit(this.reportData);
        }

      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }
}
