import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { StorageService } from '@app/services/storage.service';

@Component({
  selector: 'app-assets-issue-page',
  templateUrl: './assets-issue-page.component.html',
  styleUrls: ['./assets-issue-page.component.css']
})
export class AssetsIssuePageComponent extends CustomComponent implements OnInit {

  /*
  asset = null;
  contact = null;
  companies = null;

  isLoading = false;
  scannerOpen = "0";
  */

  constructor(
    protected override storageService: StorageService,
    protected override router: Router
  ) { 

    super(storageService, router, true);
  }

  ngOnInit(): void {
  }

}
