<div class="card-panel" *ngIf="documents == null">
    <br><br>
    <div id="preloader"></div>
    <br>
    <div class="center-align">Bitte warten...</div>
</div>

<div *ngIf="documents != null && document == null">
    <div *ngIf="folder == 'tocheck'">
        <h2 class="bot-20 sec-tit">Zu prüfenden Befragungen ({{total}})</h2>
    </div>
    
    <div *ngIf="folder == 'commited'">
        <h2 class="bot-20 sec-tit">Freigegebene Befragungen ({{total}})</h2>
    </div>
    
    <ul class="documents" *ngIf="documents.length == 0">
        <li class="document-item card">
            <div class="title">Sie haben keine Befragungen.<br>Laden Sie welche hoch oder legen Sie neue durch direktes Scannen an.</div>
            <br clear="all">
        </li>
    </ul>

    <ul class="documents" *ngIf="documents.length > 0">
        <li class="document-item card" *ngFor="let document of documents" (click)="setDocument(document)">
            <div class="title" title="{{document.id}}"><strong>{{document.name}}</strong> (Score: {{document.score_real}})</div>
            <div class="data">
                <span class="new badge" [ngClass]="document.class" data-badge-caption="">{{className(document)}}</span>
                <span>{{getTimestamp(document.timestamp) | date:'medium':'':'de'}}</span>
            </div>
            <br clear="all">
        </li>
    </ul>

</div>

