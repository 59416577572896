import { Component, OnInit } from '@angular/core';
import { CustomComponent } from '@app/components/core/custom-component';
import { StorageService } from '@app/services/storage.service';
import { UserService } from '@app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { User } from '@app/models/user.model';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.css']
})
export class ProfilePageComponent extends CustomComponent implements OnInit {

  userInput = null;

  constructor(
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected override storageService: StorageService,
    protected userService: UserService,
    protected override router: Router
  ) { 

    super(storageService, router, true);
  }

  ngOnInit(): void {
    this.getUser();
   }

   override getUser(): User {
    this.userService.getUser({'guid': this.user.guid})
    .subscribe(
      (getUserResponse) => {
        if (getUserResponse.status == '200') {
          this.userInput = getUserResponse.user;
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });

      return null;
  }
  
}
