import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { DocumentContentZZ } from '@app/models/documentcontent_zz.model';
import { DocumentService } from '@app/services/document.service';
import { StorageService } from '@app/services/storage.service';

@Component({
  selector: 'app-forms-zzpage',
  templateUrl: './forms-zzpage.component.html',
  styleUrls: ['./forms-zzpage.component.css']
})
export class FormsZZPageComponent extends CustomComponent implements OnInit {

  document = null;

  constructor(
    protected override storageService: StorageService,
    protected documentService: DocumentService,
    protected override router: Router
  ) { 

    super(storageService, router, false);
  }

  ngOnInit(): void {
    this.createDocument();
  }

  createDocument() {
    this.document = {} as Document;

    var dateTmp = new Date();
    var date = dateTmp.getFullYear() + "" + (((dateTmp.getMonth() + 1) < 10) ? '0' : '') + (dateTmp.getMonth() + 1) + "" + ((dateTmp.getDate() < 10) ? '0' : '') + dateTmp.getDate() + 
               "_" + dateTmp.getHours() + dateTmp.getMinutes();
    var number = Math.floor((Math.random() * 9000) + 1000);
    this.document.name = date + "_" + number;

    this.document.class = 'ZZ';
    this.document.content = {} as DocumentContentZZ;
    this.document.content.q1 = '1';
    this.document.content.q2 = '1';
    this.document.content.q3 = '1';
    this.document.content.q4 = '1';
    this.document.content.q5 = '1';
    this.document.content.q6 = '1';
    this.document.content.q7 = '1';
  }  

}
