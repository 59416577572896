<div class="card profileContainer">

    <div class="card-content">
        <span class="card-title">Persönliche Daten</span>

        <div class="row" *ngIf="userInput == null || loading == true">
            <div class="col s12 center-align">
                <br><br>
                <div id="preloader"></div>
                <br>
                <div class="center-align">Bitte warten...</div>
            </div> 
        </div>

        <span *ngIf="userInput != null && loading == false">
            <span *ngIf="action == 'view'">

                <!-- Anrede/Title -->
                <div class="row">
                    <div class="col s6 m6 l2">
                        Anrede
                    </div>
                    <div class="col s6 m6 l4">
                        {{salutationString()}}&nbsp;
                    </div>
                    <div class="col s6 m6 l2">
                        Titel
                    </div>
                    <div class="col s6 m6 l4">
                        {{titleString()}}&nbsp;
                    </div>
                </div>
                <div class="row">
                    <div class="col s6 m6 l2">
                        Vorname
                    </div>
                    <div class="col s6 m6 l4">
                        {{userInput.firstname}}&nbsp;
                    </div>

                    <div class="col s6 m6 l2">
                        Nachname
                    </div>
                    <div class="col s6 m6 l4">
                        {{userInput.lastname}}&nbsp;
                    </div>
                </div>

                <!-- Tel und E-Mail-->
                <div class="row">
                    <div class="col s6 m6 l2">
                        Telefon
                    </div>
                    <div class="col s6 m6 l4">
                        {{userInput.phone}}&nbsp;
                    </div>
                    <div class="col s6 m6 l2">
                        E-Mail
                    </div>
                    <div class="col s6 m6 l4">
                        {{userInput.email}}&nbsp;
                    </div>
                </div>

                <!-- Persönliche Daten -->
                <div class="row">
                    <div class="col s6 m6 l2">
                        Strasse
                    </div>
                    <div class="col s6 m6 l4">
                        {{userInput.street}}&nbsp;
                    </div>

                    <div class="col s6 m6 l2">
                        Hausnummer
                    </div>
                    <div class="col s6 m6 l4">
                        {{userInput.houseno}}&nbsp;
                    </div>
                </div>
                <div class="row">
                    <div class="col s6 m6 l2">
                        PLZ
                    </div>
                    <div class="col s6 m6 l4">
                        {{userInput.zipcode}}
                    </div>

                    <div class="col s6 m6 l2">
                        Ort
                    </div>
                    <div class="col s6 m6 l4">
                        {{userInput.city}}
                    </div>
                </div>

            </span>

            <span *ngIf="action == 'edit'">
                <form [formGroup]="accountDetailsForm" (ngSubmit)="save()">
                <!-- Anrede/Title -->
                <div class="row">
                    <div class="col s6 m6 l2">
                        Anrede
                    </div>
                    <div class="col s6 m6 l4">
                        <select class="browser-default custom-select" formControlName="salutation">
                            <option *ngFor="let salutationItem of salutationData" [value]="salutationItem.key">{{salutationItem.value}}</option>
                        </select>
                        <span *ngIf="submitted && adf.salutation.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                    </div>
                    <div class="col s6 m6 l2">
                        Titel
                    </div>
                    <div class="col s6 m6 l4">
                        <select class="browser-default custom-select" formControlName="title">
                            <option *ngFor="let titleItem of titleData" [value]="titleItem.key">{{titleItem.value}}</option>
                        </select>
                        <span *ngIf="submitted && adf.title.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col s6 m6 l2">
                        <label for="firstname">Vorname</label>
                    </div>
                    <div class="col s6 m6 l4">
                        <input type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'invalid': submitted && adf.firstname.errors }" />
                        <span *ngIf="submitted && adf.firstname.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                    </div>

                    <div class="col s6 m6 l2">
                        Nachname
                    </div>
                    <div class="col s6 m6 l4">
                        <input type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'invalid': submitted && adf.lastname.errors }" />
                        <span *ngIf="submitted && adf.lastname.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                    </div>
                </div>

                <!-- Tel und E-Mail-->
                <div class="row">
                    <div class="col s6 m6 l2">
                        Telefon
                    </div>
                    <div class="col s6 m6 l4">
                        <input type="text" formControlName="phone" class="form-control" [ngClass]="{ 'invalid': submitted && adf.phone.errors }" />
                        <span *ngIf="submitted && adf.phone.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                    </div>
                </div>

                <!-- Persönliche Daten -->
                <div class="row">
                    <div class="col s6 m6 l2">
                        Strasse
                    </div>
                    <div class="col s6 m6 l4">
                        <input type="text" formControlName="street" class="form-control" [ngClass]="{ 'invalid': submitted && adf.street.errors }" />
                        <span *ngIf="submitted && adf.street.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                    </div>

                    <div class="col s6 m6 l2">
                        Hausnummer
                    </div>
                    <div class="col s6 m6 l4">
                        <input type="text" formControlName="houseno" class="form-control" [ngClass]="{ 'invalid': submitted && adf.houseno.errors }" />
                        <span *ngIf="submitted && adf.houseno.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col s6 m6 l2">
                        PLZ
                    </div>
                    <div class="col s6 m6 l4">
                        <input type="text" formControlName="zipcode" class="form-control" [ngClass]="{ 'invalid': submitted && adf.zipcode.errors }" />
                        <span *ngIf="submitted && adf.zipcode.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                    </div>

                    <div class="col s6 m6 l2">
                        Ort
                    </div>
                    <div class="col s6 m6 l4">
                        <input type="text" formControlName="city" class="form-control" [ngClass]="{ 'invalid': submitted && adf.city.errors }" />
                        <span *ngIf="submitted && adf.city.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                    </div>
                </div>
            </form>
            </span>
        </span>
    </div>

    <div class="card-content" *ngIf="action == 'view'">
        <span class="card-title">Accountdaten</span>

        <div class="row" *ngIf="userInput == null || loading == true">
            <div class="col s12 center-align">
                <br><br>
                <div id="preloader"></div>
                <br>
                <div class="center-align">Bitte warten...</div>
            </div> 
        </div>
 
        <span *ngIf="userInput != null && loading == false">
            <span *ngIf="action == 'view'">
                <div class="row">
                    <div class="col s6 m6 l2">
                        Zielnummer
                    </div>
                    <div class="col s6 m6 l4">
                        {{userInput.account_key}}&nbsp;
                    </div>
                </div>
            </span>
        </span>

    </div>

    <div class="card-action right-align">
        <span *ngIf="action == 'view'">
            <a class="waves-effect waves-dark btn-large white teal-text" (click)="edit()"><i class="mdi mdi-square-edit-outline left"></i>Bearbeiten</a>
        </span>

        <span *ngIf="action == 'edit'">
            <a class="waves-effect waves-dark btn-large white teal-text" (click)="cancel();"><i class="mdi mdi-close-box-outline left"></i>Abbrechen</a>
            &nbsp;
            <a class="waves-effect waves-light btn-large primary-bg" (click)="save()"><i class="mdi mdi-arrow-right-bold-box-outline left"></i>Speichern</a>
        </span>
    </div>

</div>
