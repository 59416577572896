<div class="container">
    <div class="section">

        <h1 class="bot-20 sec-tit">Anrufe</h1>

        <div class="card-panel">

            <!--div class="row toolbar margin-bottom-10">
                <b>Filter</b><br>
                <div class="col s12 m6">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Anliegen</mat-label>
                        <mat-select [(ngModel)]="callIntentString" (change)="callIntentChanged2($event)">
                            <mat-option *ngFor="let callIntentItem of callIntentData" [value]="callIntentItem.value">
                                {{callIntentItem.key}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col s12 m6">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="callStatusString" (change)="callStatusChanged2($event)">
                            <mat-option *ngFor="let callStatusItem of callStatusData" [value]="callStatusItem.value">
                                {{callStatusItem.key}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div-->

            <div class="row toolbar margin-bottom-10">
                <b>Filter</b><br>
                <div class="col s12 m6">
                    Anliegen:
                    <select class="browser-default custom-select" [formControl]="callIntent" (change)="callIntentChanged($event)">
                        <option *ngFor="let callIntentItem of callIntentData" [ngValue]="callIntentItem.value">{{callIntentItem.key}}</option>
                    </select>
                </div>
                <div class="col s12 m6">
                    Status:
                    <select class="browser-default custom-select" [formControl]="callStatus" (change)="callStatusChanged($event)">
                        <option *ngFor="let callStatusItem of callStatusData" [ngValue]="callStatusItem.value">{{callStatusItem.key}}</option>
                    </select>
                </div>
            </div>
            
            <div class="row" *ngIf="hasFilter()">
                <div class="col s12">
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="primary">
                                Zusätzliche Filter
                            </mat-panel-title>
                        </mat-expansion-panel-header>
            
                        <form [formGroup]="filterControlsForm">
                            <div class="row">
                                <div *ngFor="let attributeFilter of attributeFilters">
                                    <div class="col m4 s12" *ngIf="attributeFilter.values.length > 0">
                                        {{attributeFilter.name}}:
                                        <select class="browser-default custom-select" [formControlName]="attributeFilter.name" (change)="attributeFilterChanged($event)">
                                            <option value=""></option>
                                            <option *ngFor="let attributeFilterValue of attributeFilter.values" [ngValue]="attributeFilterValue">{{attributeFilterValue}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-expansion-panel>
                </div>
            </div>
        
            <div class="row no-margin">
                <div class="col s12 right-align">
                    <a class="waves-effect waves-light btn-large white primary-text" (click)="loadCalls()"><i class="mdi mdi-reload left"></i>Liste aktualisieren</a>
                    <br><span class="updated-time-label">Letzte Aktualisierung: {{lastUpdate | date:'mediumTime':'':'de'}}</span>
                </div>
            </div>
        </div>

        <div class="row" #callsContent>
            <app-calllist [calls]="calls" [total]="totalCalls" (callSetEvent)="callSet($event)" (callChangeEvent)="callChanged($event)"></app-calllist>
            
            <div class="row card-panel">
                <div class="col s12">
                    <mat-paginator [length]="totalCalls"
                        [pageSize]="pageSize"
                        [pageSizeOptions]="pageSizeOptions"
                        (page)="pageChanged($event)">
                    </mat-paginator>
                </div>
            </div>
        </div>

    </div>

</div>



