<div class="container">
    <div class="section">
    
        <h1 class="bot-20 sec-tit">Reports</h1>

        <app-report-date-selector 
            [date]="date" [todate]="todate">
        </app-report-date-selector>

        <app-reportlist 
            [date]="date" [todate]="todate" [reportData]="reportData">
        </app-reportlist>

    </div>
</div>
