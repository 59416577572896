import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

declare let $: any;

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.css']
})
export class CheckboxGroupComponent implements OnInit {

  @Input() size: number;
  @Input() label: string;
  @Input() value: number;
  @Input() action: string;
  @Input() name: string;
  @Input() form: FormGroup;
  @Input() showPlayButton: Boolean = true;
  @Input() showResetButton: Boolean = true;
  @Input() tooltip: string = null;

  constructor() { 
    if (this.size == null) {
      this.size = 5;
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.action) {
      this.action = changes.action.currentValue;
    }
  }

  resetRadios(radioName: string): void {
    this.form.controls[radioName].setValue(null);
  }

  speakLabel(text) {
    text = text.replace("...", "?");

    this.speak(text);
  }

  speak(text): void {
    var msg = new SpeechSynthesisUtterance();
    //var voices = speechSynthesis.getVoices();
    //msg.voice = voices[10];
    //msg.voiceURI = 'native';
    //msg.volume = 1;
    //msg.rate = 1;
    //msg.pitch = 2;
    msg.text = text;
    msg.lang = 'de-DE';

    speechSynthesis.speak(msg);
  }

}
