import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { DocumentContentIntake } from '@app/models/documentcontent_intake.model';
import { DocumentService } from '@app/services/document.service';
import { StorageService } from '@app/services/storage.service';
 
@Component({
  selector: 'app-forms-intake-page',
  templateUrl: './forms-intake-page.component.html',
  styleUrls: ['./forms-intake-page.component.scss']
})
export class FormsIntakePageComponent extends CustomComponent implements OnInit {

  document = null;
  rguid = null;
  rtype = null;

  constructor(
    protected override storageService: StorageService,
    protected documentService: DocumentService,
    protected override router: Router,
    private route: ActivatedRoute
  ) { 

    super(storageService, router, false);
  }

  ngOnInit(): void {
    /*
    this.route.queryParamMap.subscribe(query => {
      const debug = query.get('debug');
    });
    */

    this.route.paramMap.subscribe(params => {
      this.createDocument(params.get('rtype'), params.get('rguid'));
    });
    
  }

  createDocument(rtype, rguid) {
    this.document = {} as Document;
    this.document.rtype = rtype;
    this.document.rguid = rguid;

    var dateTmp = new Date();
    var date = dateTmp.getFullYear() + "" + (((dateTmp.getMonth() + 1) < 10) ? '0' : '') + (dateTmp.getMonth() + 1) + "" + ((dateTmp.getDate() < 10) ? '0' : '') + dateTmp.getDate() + 
               "_" + dateTmp.getHours() + dateTmp.getMinutes();
    var number = Math.floor((Math.random() * 9000) + 1000);
    this.document.name = date + "_" + number;

    this.document.class = 'customer_intake';
    this.document.content = {} as DocumentContentIntake;
  }  
}
