import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { StorageService } from '@app/services/storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends CustomComponent implements OnInit {

  constructor(
    protected override storageService: StorageService,
    protected override router: Router
  ) { 
    super(storageService, router, false);
  }

  ngOnInit(): void {
  }

}
