import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { StorageService } from '@app/services/storage.service';
import { GetContactResponse } from '@app/models/responses/getContactResponse.model';
import { GetContactsResponse } from '@app/models/responses/getContactsResponse.model';
import { UpdateContactResponse } from '@app/models/responses/updateContactResponse.model';

import { environment } from 'src/environments/environment';
import { GetCompaniesResponse } from '@app/models/responses/getCompaniesResponse.model';
import { SearchContactsResponse } from '@app/models/responses/searchContactsResponse.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private headers = null;
  private options = {};

  constructor(
    protected storageService: StorageService,
    private http: HttpClient

  ) { 
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    this.options = {
      headers: this.headers
    }
  }

  getContact(guid) {
    var user = this.storageService.getUser();

    var params = new HttpParams();
    params = params.append("user_guid", user.guid);
    params = params.append("guid", guid);
    this.options['params'] = params;

    return this.http.get<GetContactResponse>(environment.apiEndpoint + '/api/contact/1.0.0.php', this.options);
  }

  getContacts(page, pageSize) {
    var user = this.storageService.getUser();

    var params = new HttpParams();
    params = params.append('user_guid', user.guid);
    params = params.append('page', page);
    params = params.append('page_size', pageSize);
    this.options['params'] = params;

    return this.http.get<GetContactsResponse>(environment.apiEndpoint + '/api/addressbook/contacts/1.0.0.php', this.options);
  }

  searchContacts(q) {
    var user = this.storageService.getUser();

    var params = new HttpParams();
    params = params.append('q', q);
    this.options['params'] = params;

    return this.http.get<SearchContactsResponse>(environment.apiEndpoint + '/api/contacts/search/1.0.0.php', this.options);
  }

  getCompanies() {
    var params = new HttpParams();
    //params = params.append('user_guid', user.guid);
    this.options['params'] = params;

    return this.http.get<GetCompaniesResponse>(environment.apiEndpoint + '/api/companies/1.0.0.php', this.options);
  }

  updateContact(payload: object) {
    const body = payload;
    return this.http.put<UpdateContactResponse>(environment.apiEndpoint + '/api/addressbook/contact/1.0.0.php', body, this.options);
  }

  createTenant(payload: any) {
    payload.contact.type = 'tenant';

    const body = payload;
    return this.http.post<UpdateContactResponse>(environment.apiEndpoint + '/api/addressbook/contact/1.0.0.php', body, this.options);
  }

  getExternalContacts(category, zipcode) {
    var user = this.storageService.getUser();

    var params = new HttpParams();
    params = params.append('category', category);
    params = params.append('zipcode', zipcode);
    this.options['params'] = params;

    return this.http.get<GetContactsResponse>(environment.apiEndpoint + '/api/addressbook/gelbeseiten/search/1.0.0.php', this.options);
  }

}
