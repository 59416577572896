import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { StorageService } from '@app/services/storage.service';
import * as $ from 'jquery';

declare let cordova: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent extends CustomComponent implements OnInit {

  constructor(
    protected override storageService: StorageService,
    protected override router: Router
  ) { 
    super(storageService, router, false);
  }

  ngOnInit(): void {
    //this.fixNotch();
  }

  fixNotch() {
    if ($('.footer-menu')) {
      var containerPadding = parseInt($('.footer-menu').css('padding-bottom'));
      if (containerPadding > 0) {

          var newHeight = parseInt($('.footer-menu').css('height')) + 15;
          $('.footer-menu').css({
              'height': newHeight + 'px'
          });
      }
    }

 }  

}
