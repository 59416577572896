<div class="card-panel" *ngIf="assets == null">
    <br><br>
    <div id="preloader"></div>
    <br>
    <div class="center-align">Bitte warten...</div>
</div>

<div *ngIf="assets != null">

    <div class="row card-panel toolbar">
        <div class="col s12">
            <button mat-button class="toolbar-btn" routerLink="/asset/new" *ngIf="hasPermissions('assets.edit');" >
                <i class="mdi mdi-key hide-on-small-only"></i> Neu
            </button>
            <button (click)="downloadPDF()" mat-button class="toolbar-btn">
                <i class="mdi mdi-download hide-on-small-only"></i> Download
            </button>
        </div>
    </div>

    <div class="assets card-panel" *ngIf="assets.length == 0">
        <div class="title">Sie haben noch keine Objekte angelegt.</div>
    </div>    
    
    <div class="assets card-panel" *ngIf="assets.length > 0">
        <div class="title">Die Liste beinhaltet {{assets.length}} Einträge.</div>
    </div>    

    <div class="assets" *ngIf="assets.length > 0">
        <table id="assetsTable">
            <thead>
                <tr>
                    <th>Objekt</th>
                    <th>Beschreibung</th>
                    <th>Max. Dauer</th>
                    <th>
                        <label>
                            <input type="checkbox" (click)="selectAll($event)" class="form-control"/>
                            <span>Alle</span>
                        </label>
                    </th>
                </tr>
            <tbody>
                <ng-container *ngFor="let asset of assets">
                <tr *ngIf="asset.grouped == 0">
                    <td valign="top">
                        <span>
                            <i *ngIf="asset.class == 'key'" class="mdi mdi-key-variant left" matTooltip="Schlüssel" matTooltipPosition="above" matTooltipClass="tooltip"></i>
                            <i *ngIf="asset.class == 'keygroup'" class="mdi mdi-shape-circle-plus left" matTooltip="Schlüsselbund" matTooltipPosition="above" matTooltipClass="tooltip"></i>
                            <i *ngIf="asset.class == 'misc'" class="mdi mdi-label-outline left" matTooltip="Sonstiges" matTooltipPosition="above" matTooltipClass="tooltip"></i>
                            <a [ngClass]="{'red-text': asset.restricted == 1}" *ngIf="hasPermissions('assets.edit');" routerLink="/asset/edit" [queryParams]="{guid: asset.guid}">{{asset.name}}</a>
                            <span *ngIf="!hasPermissions('assets.edit');" [ngClass]="{'red-text': asset.restricted == 1}">{{asset.name}}</span>
                        </span>
                    </td>
                    <td>
                        {{asset.description}}<br *ngIf="asset.description != ''">
                        <span class="grey-text" *ngIf="asset.internal_id">({{asset.internal_id}})</span>
                    </td>
                    <td>{{getExpiration(asset)}}</td>
                    <td>
                        <label>
                            <input type="checkbox" [(ngModel)]="asset.selected" class="form-control"/>
                            <span></span>
                        </label>
                    </td>
                </tr>
                </ng-container>
            </tbody>
        </table>
    </div>

</div>

