<div class="container">
    <div class="section">

        <h1 class="bot-20 sec-tit">Check-In</h1>

        <div class="row card-panel toolbar" *ngIf="hasPermissions('employees.admin') || hasPermissions('checkin.admin')">
            <div class="col s12">
                <button mat-button [matMenuTriggerFor]="menu" class="toolbar-btn" *ngIf="hasPermissions('employees.admin')"><i class="mdi mdi-cogs hide-on-small-only"></i> Daten</button>
                <mat-menu #menu="matMenu" class="toolbar">
                    <button mat-menu-item routerLink="/employees"><i class="mdi mdi-account hide-on-small-only"></i> Mitarbeiter</button>
                </mat-menu>

                <button (click)="downloadPDF()" mat-button class="toolbar-btn" *ngIf="hasPermissions('checkin.admin')"><i class="mdi mdi-download"></i> Download</button>
            </div>
        </div>
    
        <div class="card-panel" *ngIf="hasPermissions('checkin.log');">
            <div class="title"><h2>Persönlicher Check-In</h2></div>

            Sie möchten einen Check-In bestätigen? Klicken Sie bitte unten auf "Check-In bestätigen".<br><br>

            <div class="card-action right-align">
                <button routerLink="/checkin/supported" [queryParams]="{scanner: '1'}" class="btn white grey-text" *ngIf="isApp()">
                    <i class="mdi mdi-qrcode-scan right"></i>Check-In bestätigen
                </button>
                <button routerLink="/checkin/supported" class="btn white grey-text" *ngIf="!isApp()">
                    <i class="mdi mdi-qrcode-scan right"></i>Check-In bestätigen
                </button>
            </div>
        </div>

        <div class="card-panel" *ngIf="hasPermissions('checkin.scan');">
            <div class="title"><h2>Check-In Scanner</h2></div>

            Um den Scanner für den Self-Check-In zu starten, klicken Sie unten auf "Scanner starten"!<br><br>

            <div class="card-action right-align">
                <button class="btn white grey-text" routerLink="/checkin/self">
                    <i class="mdi mdi-tablet-ipad right"></i> Scanner starten
                </button>
            </div>
        </div>

    </div>
</div>


