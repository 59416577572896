import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { AssetLog } from '@app/models/assetlog.model';
import { AssetService } from '@app/services/asset.service';
import { StorageService } from '@app/services/storage.service';

declare let M: any;

@Component({
  selector: 'app-assetloglist',
  templateUrl: './assetloglist.component.html',
  styleUrls: ['./assetloglist.component.css']
})
export class AssetloglistComponent extends CustomComponent implements OnInit {

  @Input() assetlogs;
  typeString = 'Schlüssel';
  @Output() assetlogChangeEvent: EventEmitter<AssetLog> = new EventEmitter<AssetLog>();

  constructor(
    protected override storageService: StorageService,
    protected assetService: AssetService,
    protected override router: Router
  ) { 

    super(storageService, router, true);
  }

  ngOnInit(): void {
  }

  getSignatureData(signature) {
      if (signature) {
        var data = JSON.parse(signature);

        if (data != null) {
          return data.png;
        }
      }

      return null;
    }

}
