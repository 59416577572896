import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { Asset } from '@app/models/asset.model';
import { AssetService } from '@app/services/asset.service';
import { StorageService } from '@app/services/storage.service';

declare let M: any;
declare let $: any;

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.css']
})
export class AssetDetailsComponent extends CustomComponent implements OnInit {

  guid = null;
  asset = null;
  action = 'edit';
  submitted = false;
  assetForm: FormGroup;

  classesData = [
    {'key': '', 'value': 'Keine Angabe'},
    {'key': 'key', 'value': 'Schlüssel'},
    {'key': 'keygroup', 'value': 'Schlüsselbund'},
    {'key': 'misc', 'value': 'Sonstiges'}
  ];

  constructor(
    protected override storageService: StorageService,
    protected assetService: AssetService,
    private activatedRoute: ActivatedRoute,
    protected formBuilder: FormBuilder, 
    protected override router: Router
  ) { 

    super(storageService, router, false);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.guid != null) {
       this.guid = params.guid;
       this.loadAsset();
      } else {
        this.asset = {} as Asset;
      }
    });
  }

  get f() { return this.assetForm.controls; }

  ngOnInit(): void {
    this.assetForm = this.formBuilder.group({
      class: new FormControl(null, [Validators.required, Validators.min(1)]),
      name: new FormControl(null, [Validators.required]),
      description: new FormControl(null),
      internal_id: new FormControl(null),
      location: new FormControl(null),
      expiration_min: new FormControl('', [Validators.required, Validators.pattern(this.numberPattern), Validators.min(0), Validators.max(7200)]),
      grouped: new FormControl(null),
      restricted: new FormControl(null)
    });
  }

  save() {
    this.submitted = true;

    if (this.assetForm.invalid) {
        return;

    } else {
      this.populateObject(this.asset, this.assetForm);

      if (this.asset.guid) {
        this.updateAsset();
      } else {
        this.createAsset();
      }

    }
  }

  loadAsset() {
    this.assetService.getAsset(this.guid)
    .subscribe(
      (genericAssetResponse) => {
        if (genericAssetResponse.asset != null) {
          this.asset = genericAssetResponse.asset;
          this.populateAssetForm();
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  populateAssetForm(): void {

    this.populateFormGroup(this.assetForm, this.asset);
  }

  createAsset() {
    var payload = {
      'asset': this.asset
    }

    this.assetService.createAsset(payload)
    .subscribe(
      (genericAssetResponse) => {

        if (genericAssetResponse.status == 200) {
          M.toast({html: 'Die Daten wurden erfolgreich gespeichert.', classes: 'primary-bg'});
          this.router.navigateByUrl('/assets');
        }

      },
      response => {
        M.toast({html: 'Beim Speichern der Daten ist ein Fehler aufgetreten.', classes: 'red'});
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  updateAsset() {
    var payload = {
      'asset': this.asset
    }

    this.assetService.updateAsset(payload)
    .subscribe(
      (genericAssetResponse) => {

        if (genericAssetResponse.status == 200) {
          M.toast({html: 'Ihr Objekt wurde erfolgreich überarbeitet.', classes: 'primary-bg'});
          this.router.navigateByUrl('/assets');
        }

      },
      response => {
        M.toast({html: 'Beim Speichern des Dokuments ist ein Fehler aufgetreten.', classes: 'red'});
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  deleteAsset() {
    this.assetService.deleteAsset(this.asset.guid)
    .subscribe(
      (genericAssetResponse) => {
        if (genericAssetResponse.status == 200) {
          this.asset = null;
          M.toast({html: 'Ihr Objekt wurde erfolgreich gelöscht.', classes: 'primary-bg'});
          this.router.navigateByUrl('/assets');
        }
      },
      response => {
        M.toast({html: 'Beim Löschen des Objekts ist ein Fehler aufgetreten.', classes: 'red'});
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

}
