<div class="card-panel" *ngIf="calls == null">
    <br><br>
    <div id="preloader"></div>
    <br>
    <div class="center-align">Bitte warten...</div>
</div>

<div *ngIf="calls != null">
    
    <ul class="calls" *ngIf="calls.length == 0">
        <li class="document-item card">
            <mat-card class="call-item">
                <mat-card-header>
                    <div mat-card-avatar><span class="mdi mdi-check"></span></div>
                    <mat-card-title>Sie haben keine Anrufe erhalten.</mat-card-title>
                    <mat-card-subtitle>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                </mat-card-content>
                <mat-card-actions>
                </mat-card-actions>
                <mat-card-footer>
                </mat-card-footer>
            </mat-card>
        </li>
    </ul>
 
    <mat-accordion class="calls">
        <mat-expansion-panel class="call-item" *ngFor="let call of calls" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header (click)="setCall(call)">
                <mat-panel-title>
                    <span class="badge" [ngStyle]="{'background-color': stringToColor(call.intent), 'color': textColorBasedOnBackground(call.intent)}" data-badge-caption="">{{intentLabel(call.intent)}}</span>
                </mat-panel-title>
                <mat-panel-description>
                    <div class="datetime hide-on-small-only">
                        {{getTimestamp(call.timestamp) | date:'mediumDate':'':'de'}}, {{getTimestamp(call.timestamp) | date:'mediumTime':'':'de'}}
                    </div>
                    <span>{{getFullname(call)}}</span>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-chip-set aria-label="Info">
                <mat-chip *ngIf="call.birthdate != null">
                    <span class="mdi mdi-cake-variant-outline"></span>
                    {{getTimestamp(call.birthdate) | date:'mediumDate':'':'de'}}
                </mat-chip>
                <mat-chip (click)="dial(call.phonenumber)">
                    <span class="mdi mdi-phone"></span>
                    {{call.phonenumber}}
                </mat-chip>
            </mat-chip-set>

            <div class="call-content">
                <br>
                <div class="show-on-small-only"><span class="mdi mdi-clock-time-eight-outline"></span> {{getTimestamp(call.timestamp) | date:'mediumDate':'':'de'}}, {{getTimestamp(call.timestamp) | date:'mediumTime':'':'de'}}<br></div>
                <div *ngIf="call.summary" alt="Zusammenfassung"><span class="mdi mdi-robot-outline"></span> {{call.summary}}<br></div>
                <div *ngIf="call.transcript" alt="Transkript" class="transcript teal-text text-lighten-1"><span class="mdi mdi-account-voice"></span> {{call.transcript}}</div>

                <div *ngIf="call.attributes">
                    <br>
                    <mat-chip-set aria-label="Info">
                        <span *ngFor="let attribute of call.attributes | keyvalue">
                            <mat-chip *ngIf="attribute.value">
                                <span class="mdi mdi-lightbulb-on-10"></span>
                                {{attribute.key}}: {{attribute.value}}
                            </mat-chip>
                        </span>
                    </mat-chip-set>
                </div>

                <mat-tab-group class="margin-top-10" dynamicHeight mat-stretch-tabs animationDuration="0ms">
        
                    <mat-tab label="Details">
                        <div class="pad-10">
                            <form>

                                <div class="row">
                                    <div class="col s12">
                                        <b>Offene Anrufe von {{call.phonenumber}}:</b>

                                        <span *ngIf="!callRelations || callRelations.length == 0"><br>Keine.</span>

                                        <mat-selection-list #relations [multiple]="true" [(ngModel)]="callRelationsSelected" class="margin-top-10">
                                            <mat-list-option *ngFor="let relation of callRelations" [value]="relation">
                                                <span class="badge hide-on-small-only" [ngStyle]="{'background-color': stringToColor(relation.intent), 'color': textColorBasedOnBackground(relation.intent)}" data-badge-caption="">{{intentLabel(relation.intent)}}</span>
                                                <span class="hide-on-small-only">{{getTimestamp(relation.timestamp) | date:'mediumDate':'':'de'}}, {{getTimestamp(relation.timestamp) | date:'mediumTime':'':'de'}}, </span>
                                                {{getFullname(relation)}}
                                                <span class="mdi mdi-information-outline"
                                                    [matTooltip]="relation.transcript"></span>
                                            </mat-list-option>
                                        </mat-selection-list>
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </mat-tab>
            
                    <mat-tab label="Notizen">
                        <div class="pad-10">
                            <form [formGroup]="noteFormGroup">

                                <div class="row">
                                    <div class="col s12">
                                        <mat-form-field class="input-full-width">
                                            <mat-label>Neue Notiz</mat-label>
                                            <textarea #noteInput class="input-full-width" maxlength="255" formControlName="note" matInput required [ngClass]="{ 'invalid': submitted && nf.note.errors }"></textarea>
                                            <mat-hint align="start">{{noteInput && noteInput.value.length}}/255</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 right-align">
                                        <a class="waves-effect waves-light btn-large white primary-text" (click)="addNote()"><i class="mdi mdi-playlist-plus left"></i>Hinzufügen</a>
                                    </div>
                                </div>

                                <!--span class="mdi mdi-chat-outline"></span-->
                                <div class="row history">
                                    <div class="col s12">
                                        <div *ngFor="let historyEntry of history">
                                            <div><span class="mdi mdi-face-agent"></span> Interne Notiz - {{getTimestamp(historyEntry.timestamp) | date:'medium':'':'de'}}</div>
                                            <div class="history-subtext" style="white-space: pre-line;">{{historyEntry.text}}</div>
                                        </div>

                                        <!--mat-list>note-subtext
                                            <div mat-subheader>Einträge</div>
                                            <mat-list-item *ngFor="let historyEntry of history">
                                                <div matListItemTitle><span class="mdi mdi-face-agent"></span> Interne Notiz - {{getTimestamp(historyEntry.timestamp) | date:'medium':'':'de'}}</div>
                                                <div matListItemLine style="height: auto; white-space: pre-line;">{{historyEntry.text}}</div>
                                            </mat-list-item>
                                        </mat-list-->
                                    </div>
                                </div>

                            </form>
                        </div>
                    </mat-tab>
        
                    <!--mat-tab label="SMS">
                        <div class="pad-10">
                            <form [formGroup]="smsFormGroup">

                                <div class="row">
                                    <div class="col s12">
                                        <mat-form-field class="input-full-width">
                                            <mat-label>Neue SMS</mat-label>
                                            <textarea #smsInput class="input-full-width" maxlength="160" formControlName="sms" matInput required></textarea>
                                            <mat-hint align="start">{{smsInput.value.length}}/160</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 right-align">
                                        <a class="waves-effect waves-light" (click)="open(call)"><i class="mdi mdi-arrow-right-bold-box-outline left"></i>SMS senden</a>
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </mat-tab-->

                </mat-tab-group>                

            </div>

            <mat-action-row>
                <a *ngIf="isDone(call)" class="waves-effect waves-light btn-large primary-bg" (click)="open(call)"><i class="mdi mdi-arrow-right-bold-box-outline left"></i>Öffnen</a>
                <a *ngIf="isOpen(call)" class="waves-effect waves-light btn-large primary-bg" (click)="archive(call)"><i class="mdi mdi-check-circle-outline left"></i>Erledigt <span *ngIf="callRelationsSelected && callRelationsSelected.length > 0">(+{{callRelationsSelected.length}})</span></a>
            </mat-action-row>

        </mat-expansion-panel>
    </mat-accordion>
      
</div>

