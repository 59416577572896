import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { ConfigService } from '@app/services/config.service';
import { StorageService } from '@app/services/storage.service';
import { Intent } from '@app/models/intent.model';
import { Attribute } from '@app/models/attribute.model';
import { DialogConfig } from '@app/models/dialogconfig.model';

declare let M: any;
declare let $: any;

@Component({
  selector: 'app-config-dialogs',
  templateUrl: './config-dialogs.component.html',
  styleUrls: ['./config-dialogs.component.scss']
})
export class ConfigDialogsComponent extends CustomComponent implements OnInit {

  action = "view";
  succeeded = false;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, SPACE, COMMA] as const;
  panelOpenState = false;

  dialogConfig = null;
  dialogFormGroup = this.formBuilder.group({
    voice: ['', Validators.required],
    intents: ['', Validators.required],
    attributes: [''],
    greeting: ['', Validators.required],
    conversation: this.formBuilder.array([]),
    goodbye: ['', Validators.required]
  });

  voiceData = [
    {'key': 'de-DE-FlorianMultilingualNeural', 'value': 'Florian'},
    {'key': 'de-DE-SeraphinaMultilingualNeural', 'value': 'Seraphina'},
    {'key': 'de-DE-KatjaNeural', 'value': 'Katja'},
    {'key': 'de-DE-ConradNeural', 'value': 'Conrad'},
    {'key': 'de-DE-AmalaNeural', 'value': 'Amala'},
    {'key': 'en-US-AvaMultilingualNeural', 'value': 'Ava'},
    {'key': 'en-US-AndrewMultilingualNeural', 'value': 'Andrew'},
    {'key': 'en-US-EmmaMultilingualNeural', 'value': 'Emma'},
    {'key': 'en-US-BrianMultilingualNeural', 'value': 'Brian'},
    {'key': 'en-US-RyanMultilingualNeural', 'value': 'Ryan'},
    {'key': 'en-US-AdamMultilingualNeural', 'value': 'Adam'},
    {'key': 'en-US-AmandaMultilingualNeural', 'value': 'Amanda'},
    {'key': 'en-US-BrandonMultilingualNeural', 'value': 'Brandon'},
    {'key': 'en-US-ChristopherMultilingualNeural', 'value': 'Christopher'},
    {'key': 'en-US-CoraMultilingualNeural', 'value': 'Cora'},
    {'key': 'en-US-DerekMultilingualNeural', 'value': 'Derek'},
    {'key': 'en-US-DustinMultilingualNeural', 'value': 'Dustin'},
    {'key': 'en-US-EvelynMultilingualNeural', 'value': 'Evelyn'},
    {'key': 'en-US-NancyMultilingualNeural', 'value': 'Nancy'}
  ];

  constructor(
    protected override storageService: StorageService,
    protected configService: ConfigService,
    protected formBuilder: FormBuilder,
    protected override router: Router
  ) { 

    super(storageService, router, true);
  }

  ngOnInit(): void {
    this.loadDialogConfig();
  }

  get conversationControls() {
    return this.dialogFormGroup.get('conversation') as FormArray;
  }
  
  attrClick(obj) {
    console.log(obj);
  }

  loadDialogConfig() {
    this.dialogConfig = null;
    this.configService.getDialogConfig(this.user.guid)
    .subscribe(
      (getDialogConfigResponse) => {
        if (getDialogConfigResponse.config != null) {
          this.dialogConfig = getDialogConfigResponse.config;
          if (this.dialogConfig.conversation.length == 0 ) {
            this.dialogConfig.conversation = ["", "", ""];
          }
          this.populateFormGroup(this.dialogFormGroup, this.dialogConfig);
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  updateDialogConfig() {
    var payload = {
      'guid': this.user.guid,
      'config': this.dialogConfig
    }
    this.configService.updateDialogConfig(payload)
    .subscribe(
      (object) => {
        if (object.status == 200) {
          this.succeeded = true;
          M.toast({html: 'Ihre Einstellungen wurden erfolgreich gespeichert.', classes: 'primary-bg'});
          this.router.navigateByUrl('/config');
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  edit(){
    this.action = "edit";
  }

  cancel(){
    this.action = "view";
  }

  save(){
    this.action = "view";
    this.populateObject(this.dialogConfig, this.dialogFormGroup);
    this.updateDialogConfig();
  }
 
  addIntent(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      if (value.length > 25) {  
        M.toast({html: 'Das Anliegen darf maximal 25 Zeichen lang sein und keine Leerzeichen enthalten.', classes: 'red'});

      } else {
        this.dialogConfig.intents.push({name: value});
        event.chipInput!.clear();
      }
    }

  }

  removeIntent(intent: Intent): void {
    const index = this.dialogConfig.intents.indexOf(intent);

    if (index >= 0) {
      this.dialogConfig.intents.splice(index, 1);
    }
  }

  editIntent(intent: Intent, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove skill if it no longer has a name
    if (!value) {
      this.removeIntent(intent);
      return;
    }

    const index = this.dialogConfig.intents.indexOf(intent);
    if (index >= 0) {
      this.dialogConfig.intents[index].name = value;
    }
  }

  addAttribute(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      if (value.length > 25 || value.includes(' ')) {  
        M.toast({html: 'Das Attribut darf maximal 25 Zeichen lang sein und keine Leerzeichen enthalten.', classes: 'red'});

      } else {
        this.dialogConfig.attributes.push({name: value});
        event.chipInput!.clear();
      }
    }
  }

  removeAttribute(attribute: Attribute): void {
    const index = this.dialogConfig.attributes.indexOf(attribute);

    if (index >= 0) {
      this.dialogConfig.attributes.splice(index, 1);
    }

    //this.storageService.setUser(this.user);
  }

  editAttribute(attribute: Attribute, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove skill if it no longer has a name
    if (!value) {
      this.removeAttribute(attribute);
      return;
    }

    const index = this.dialogConfig.attributes.indexOf(attribute);
    if (index >= 0) {
      this.dialogConfig.attributes[index].name = value;
    }
  }

  addConversationItem() {
    this.conversationControls.push(new FormControl(''));
  }

  // Remove a text field
  removeConversationItem(index: number) {
    this.conversationControls.removeAt(index);
  }

  voiceString(): string {
    var returnvalue = '';

    for (const item of this.voiceData) {
      if (item.key == this.dialogConfig.voice) {
        returnvalue = item.value;
      }
    }

    return returnvalue;
  }

}
