<!-- Regular page -->
<div *ngIf="succeeded == false">

    <!-- Buttons -->
    <div class="card-panel" *ngIf="integration == true">

        <div class="left">
        </div>

        <div class="right">
            <a (click)="unsetDocument()" class="btn primary-bg"><i class="mdi mdi-close-box-outline"></i></a>
        </div>

        <br clear="all">
    </div>

    <!-- Details -->
    <div class="card darken-1">
        <div class="card-content">
            <!--span class="card-title">Bogen: {{document.name}} ({{document.class}})</span-->

            <form [formGroup]="documentForm">
                
                <div class="row no-margin">
                    <div class="col s12 l8">

                        <div class="row no-margin">
                            <div class="col s12 m6 l4">
                                <b>Datum *</b>
                            </div>
                            <div class="col s12 m6 l8">
                                <div *ngIf="action == 'view'">
                                    {{getTimestamp(document.content.date) | date:'mediumDate':'':'de'}}
                                </div>
                                <div *ngIf="action == 'edit'" #actionChanged>
                                    <input type="text" formControlName="date" id="date" class="datepicker" [ngClass]="{ 'invalid': submitted && f.date.errors }">
                                    <label for="date"></label>
                                    <span *ngIf="submitted && f.date.errors" class="helper-text" data-error="Fehlerhafte Eingabe" data-success=""></span>
                                </div>

                            </div>
                        </div>

                        <div class="row no-margin">
                            <div class="col s12 m6 l4">
                                <b>Waren Sie mal bei uns? *</b>
                            </div>
                            <div class="col s12 m6 l8">
                                <div *ngIf="action == 'view'">
                                    {{customerString()}}
                                </div>
                                <div *ngIf="action == 'edit'">
                                    <select class="browser-default custom-select" formControlName="customer">
                                        <option *ngFor="let customerItem of customerData" [value]="customerItem.key">{{customerItem.value}}</option>
                                    </select>
                                    <span *ngIf="submitted && f.customer.errors" class="helper-text red-text">Fehlerhafte Eingabe</span>
                                </div>
                            </div>
                        </div>

                        <div class="row no-margin">
                            <div class="col s12 m6 l4">
                                <b>Grund *</b>
                            </div>
                            <div class="col s12 m6 l8">
                                <div *ngIf="action == 'view'">
                                    {{intentString()}}
                                </div>
                                <div *ngIf="action == 'edit'">
                                    <select class="browser-default custom-select" formControlName="intent">
                                        <option *ngFor="let intentItem of intentData" [value]="intentItem.key">{{intentItem.value}}</option>
                                    </select>
                                    <span *ngIf="submitted && f.intent.errors" class="helper-text red-text">Fehlerhafte Eingabe</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <br>
                <div class="divider"></div>
                <br>
                
                <div class="row">
                    <div class="col s12">
                        <b>Vorname</b>
                    </div>
                    <div class="col s12">
                        <div *ngIf="action == 'view'">
                            {{document.content.firstname}}
                        </div>
                        <div *ngIf="action == 'edit'" class="input-field">
                            <input type=text formControlName="firstname" id="firstname">
                            <label for="firstname"></label>
                        </div>
                        <span *ngIf="submitted && f.firstname.errors" class="helper-text red-text">Fehlerhafte Eingabe</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12">
                        <b>Nachname *</b>
                    </div>
                    <div class="col s12">
                        <div *ngIf="action == 'view'">
                            {{document.content.lastname}}
                        </div>

                        <div *ngIf="action == 'edit'" class="input-field">
                            <input type=text formControlName="lastname" id="lastname">
                            <label for="lastname"></label>
                        </div>
                        <span *ngIf="submitted && f.lastname.errors" class="helper-text red-text">Fehlerhafte Eingabe</span>
                    </div>
                </div>
                
                <div class="row headline-row">
                    <div class="col s12 m12 l6"></div>
                    <div class="col s12 l6">
                        <div class="col s2 center-align" style="background-color: #ccc; color: #fff;">Ja</div>
                        <div class="col s2 center-align" style="background-color: #ccc; color: #fff;">Nein</div>
                        </div>
                </div>

                <span *ngIf="submitted && f.q1.errors" class="helper-text red-text">Fehlerhafte Eingabe</span>
                <app-checkbox-group [label]="formData[0].title" [description]="formData[0].description" 
                    size=2 [action]="action" [form]="documentForm" [showResetButton]="false" name="q1">
                </app-checkbox-group>

                <span *ngIf="submitted && f.q2.errors" class="helper-text red-text">Fehlerhafte Eingabe</span>
                <app-checkbox-group [label]="formData[1].title" [description]="formData[1].description"
                    size=2 [action]="action" [form]="documentForm" [showResetButton]="false" name="q2"></app-checkbox-group>

                <br>
                <div class="divider"></div>
                <br>
    
                <div class="row" *ngIf="action == 'edit'">
                    <div class="col s12">
                        Alle mit einem * markierten Felder sind Pflichtfelder und müssen ausgefüllt werden!
                    </div>
                </div>

            </form>

        </div>

    </div>

    <!-- Buttons -->
    <div class="card-panel" *ngIf="integration == false">

        <div class="left">
        </div>

        <div class="right">
            <div *ngIf="action == 'edit'">
                <a (click)="save()" class="btn primary-bg"><i class="mdi mdi-content-save-outline left"></i>Speichern</a>
            </div>
        </div>

        <br clear="all">
    </div>

</div>

<!-- Thank you page -->
<div *ngIf="succeeded == true">

    <h1>Erfolgreich übermittel.</h1>

    <div class="card darken-1">
        <div class="card-content">
            Vielen Dank für Ihr Feedback!<br>
            Ihre Daten wurden übernommen.
        </div>
    </div>
    
</div>

