<div class="container">
    <div class="section">

        <div class="row">
            <div class="col s6 pad-0">
                <h1 class="bot-20 sec-tit">Freie Kapazitäten SHK & umliegende Kreise</h1>

                Quelle: <a href="https://www.intensivregister.de/#/aktuelle-lage/downloads" target="_blank">DIVI Register</a><br>
                Stand: täglich 13:00 Uhr
            </div>
            <div class="col s6 pad-0 right-align">
                <img src="/assets/custom/images/logos/wke.png" class="responsive-img" width="150">
            </div>
        </div>

        <div class="row">
            
            <div class="col s12 pad-0">
                <div *ngFor="let line of data | keyvalue">
                    <h2>{{line.key}}</h2>

                    <table class="highlight divi">
                        <thead>
                        <tr>
                            <th>Datum</th>
                            <th class="center-align">Betten Belegt (Gesamt)</th>
                            <th class="center-align">Betten Frei (Gesamt)</th>
                            <th class="center-align">COVID Fälle / Invasiv</th>
                            <th class="center-align">Belegt / Frei</th>
                            <th class="center-align">% COVID an Belegt</th>
                        </tr>
                        </thead>
                
                        <tbody>
                            <tr *ngFor="let item of line.value">
                                <td>{{item[0] | date:'EEE':'':'de'}} {{item[0] | date:'longDate':'':'de'}}</td>
                                <td class="center-align">{{item[8]}} von {{item[11]}}</td>
                                <td class="center-align">{{item[7]}} von {{item[11]}}</td>
                                <td class="center-align">{{item[5]}} / {{item[6]}}</td>
                                <td class="center-align" [ngClass]="{'red': item[12]*100 > 99, 'yellow': (item[12]*100 <= 99 && item[12]*100 > 80), 'green': item[12]*100 <= 80}">{{item[12]*100 | number:'1.0-0':'de'}}% / {{item[13]*100 | number:'1.0-0'}}%</td>
                                <td class="center-align">{{item[14]*100 | number:'1.0-0':'de'}}%</td>
                            </tr>
                        </tbody>
                    </table>

                    <br>

                </div>
            </div>

        </div>

    </div>
</div>

<!--
"date",
"bundesland",
"gemeindeschluessel",
"anzahl_standorte",
"anzahl_meldebereiche",
"faelle_covid_aktuell",
"faelle_covid_aktuell_invasiv_beatmet",
"betten_frei",
"betten_belegt",
"betten_belegt_nur_erwachsen",
"betten_frei_nur_erwachsen",
"betten_gesamt",
"belegt_gesamt",
"frei_gesamt",
"covid_belegt_gesamt"
-->