import { Component, OnInit,ApplicationRef } from '@angular/core';
import { StorageService } from '@app/services/storage.service';
import { DocumentService } from '@app/services/document.service';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Time } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { CustomComponent } from '@app/components/core/custom-component';

declare let cordova: any;
declare let M: any;
declare let $: any;

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.css']
})
export class UploadDocumentComponent extends CustomComponent implements OnInit {

  documents = null;

  public guid;
  public sub;
  public team;

  documentAssetForm: FormGroup;
  documentAssets = [];
  documentAssetsMetadata = [];

  submitted = false;
  succeeded = false;
  uploadProcessing = false;
  uploadProgress: number = 0;

  constructor(
    private app: ApplicationRef,
    protected override storageService: StorageService,
    protected documentService: DocumentService,
    private formBuilder: FormBuilder, 
    protected override router: Router,
  ) { 

    super(storageService, router, true);
  }

  get daf() { return this.documentAssetForm.controls; }

  onDocumentClassChange(event){
    this.documentAssetForm.patchValue({
      class: event.target.value
    });
  }

  ngOnInit(): void {
    this.documentAssetForm = this.formBuilder.group({
      class : new FormControl(null),
      scan: new FormControl(true),
      file : new FormControl(null),
      fileSource: new FormControl(null),
    });

    this.documents = [];
    M.AutoInit();
  }

  upload(i: number) {
    this.submitted = true;

    if (this.documentAssetForm.invalid) {
        return;

    } else {
      this.uploadDocument(i);
    }
  }

  uploadDocument(i: number) {
    this.uploadProcessing = true;

    var payload = {
      'document': this.documentAssetForm.value,
      'documentAssets': this.documentAssets,
      'documentAssetsMetadata': this.documentAssetsMetadata
    }

    this.documentService.uploadDocument(payload)
      .subscribe((event: HttpEvent<any>) => {
      
        switch (event.type) {
          case HttpEventType.Sent:
            // console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            // console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.uploadProgress = Math.round(event.loaded / event.total * 100);
            // console.log(`Uploaded! ${this.uploadProgress}%`);
            break;
          case HttpEventType.Response:
            this.resetDocumentAssetLists();
            this.succeeded = true;
            this.uploadProcessing = false;
            M.toast({html: 'Ihr Dokument wurde erfolgreich hochgeladen!', classes: 'primary-bg'});
  
            setTimeout(() => {
              this.uploadProgress = 0;
            }, 1500);
  
        }      
      });
  }

  onDocumentFileChange(event) {
    if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          var file = event.target.files[i];
          var reader = new FileReader();

          reader.onload = (event:any) => {
            //console.log(event.target.result);
            this.documentAssets.push(event.target.result); 

            this.documentAssetForm.patchValue({
              fileSource: this.documentAssets
            });
          }

          this.documentAssetsMetadata.push({
            'name': file.name,
            'lastmodified': file.lastModified,
            'size': file.size,
            'type': file.type
          });

          reader.readAsDataURL(file);
        }
    }
  }

  deleteDocumentAsset(i: number) {
    this.documentAssets.splice(i, 1);
    this.documentAssetsMetadata.splice(i, 1);
  }

  resetDocumentAssetLists() {
    this.documentAssets = [];
    this.documentAssetsMetadata = [];
  }

}
