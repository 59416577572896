<div class="container">
    <div class="section">
    
        <h1 class="bot-20 sec-tit">Objekte</h1>

        <!--div class="row card-panel">
            <div class="col s12"-->
                <!--button mat-button [matMenuTriggerFor]="menu" class="toolbar-btn">Neu <i class="mdi mdi-dots-horizontal hide-on-small-only"></i></button>
                <mat-menu #menu="matMenu" class="toolbar">
                    <button mat-menu-item (click)="createAsset('key')">Schlüssel</button>
                </mat-menu-->

                <!--button mat-button (click)="toggleCodes()" class="toolbar-btn">
                    <i *ngIf="this.qrcodesDisplay == true" class="mdi mdi-eye-off-outline left"></i>
                    <i *ngIf="this.qrcodesDisplay == false" class="mdi mdi-eye-outline left"></i>
                    QR-Codes
                </button>
            </div>
        </div-->

        <app-assetlist [assets]="assets" *ngIf="qrcodesDisplay == false"></app-assetlist>

        <div class="qrcodelist card-panel" *ngIf="assets != null && assets.length > 0 && qrcodesDisplay == true">
            <ng-container *ngFor="let asset of getPrintableAssets(); let i = index">
                <div class="qrcode" style="text-align: center;">
                    <!--i *ngIf="asset.class == 'key'" class="mdi mdi-key-variant"></i>
                    <i *ngIf="asset.class == 'keygroup'" class="mdi mdi-shape-circle-plus"></i-->

                    <span class="assetname">{{asset.name}}</span>
                    <ngx-qrcode 
                        elementType="text" 
                        [value] = "getAssetCodeValue(asset)"
                        cssClass = "asset_qrcode"
                        errorCorrectionLevel = "L">
                    </ngx-qrcode>
                </div>
                <div *ngIf="i%24==23"><br clear="all"><br><br><br></div>
            </ng-container>
            <br clear="all">
        </div>    

        <div class="card-panel right-align">
            <button class="btn white grey-text" routerLink="/assets/logs">Zurück</button>
        </div>

    </div>
</div>


