import { Injectable, EventEmitter } from '@angular/core';
import { User } from '@app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  user: User;
  userChanged: EventEmitter<User> = new EventEmitter<User>();

  constructor() { }

  getValue(key): any {
    var data = localStorage.getItem(key);
    if (data != 'undefined') {
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  setValue(key, value) {
    var data = JSON.stringify(value);
    localStorage.setItem(key, data);
  }

  getUser(): User {
    if (this.user == null) {
      var userString = localStorage.getItem('user');
      this.user = JSON.parse(userString);
    }

    return this.user;
  }

  setUser(user: User) {
    this.user = user;

    var userString = JSON.stringify(this.user);
    localStorage.setItem('user', userString);

    this.userChanged.emit(this.user);
  }

  deleteUser() {
    let user = this.getUser();
    if (user != null) {
      localStorage.removeItem('user');
      this.user = null;
    }
  }

}
