import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericEmployeeResponse } from '@app/models/responses/genericEmployeeResponse.model';
import { StatusResponse } from '@app/models/responses/statusResponse.model';
import { environment } from 'src/environments/environment';
import { SearchEmployeesResponse } from '@app/models/responses/searchEmployeesResponse.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private headers = null;
  private options = {};


  constructor(
    private http: HttpClient

  ) { 
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    this.options = {
      headers: this.headers
    }
  }

  createEmployee(employee: object) {
    var payload = {
      'employee': employee
    }

    return this.http.post<GenericEmployeeResponse>(environment.apiEndpoint + '/api/employee/1.0.0.php', payload, this.options);
  }

  updateEmployee(employee: object) {
    var payload = {
      'employee': employee
    }

    return this.http.put<GenericEmployeeResponse>(environment.apiEndpoint + '/api/employee/1.0.0.php', payload, this.options);
  }

  deleteEmployee(guid) {
    if (guid == null) {
      return null;
    }

    let params = new HttpParams();
    params = params.append('guid', guid);
    this.options['params'] = params;

    return this.http.delete<GenericEmployeeResponse>(environment.apiEndpoint + '/api/employee/1.0.0.php', this.options);
  }

  searchEmployees(q) {
    var options = Object.assign({}, this.options);
    var params = new HttpParams();
    params = params.append('q', q);
    options['params'] = params;

    return this.http.get<SearchEmployeesResponse>(environment.apiEndpoint + '/api/employees/search/1.0.0.php', options);
  }

  validateEmployeeById(employeeId) {
    var params = new HttpParams();
    params = params.append('internal_id', employeeId);
    this.options['params'] = params;

    return this.http.get<GenericEmployeeResponse>(environment.apiEndpoint + '/api/employee/validate/1.0.0.php', this.options);
  }

  checkin(payload) {
    return this.http.post<StatusResponse>(environment.apiEndpoint + '/api/employee/checkin/1.0.0.php', payload, this.options);
  }

  logCheckin(payload) {
    return this.http.post<StatusResponse>(environment.apiEndpoint + '/api/employee/logcheckin/1.0.0.php', payload, this.options);
  }

  public async downloadEmployeesPDF(): Promise<void> {
    window.URL = window.URL || window.webkitURL;

    this.options['responseType'] = 'blob';
    var data = {};

    const blob =  await this.http.post<Blob>(environment.apiEndpoint + '/api/employees/print/1.0.0.php', data, this.options).toPromise();
    const url = window.URL.createObjectURL(blob);
    
    window.location.assign(url);
  }

}
