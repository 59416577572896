import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { AssetService } from '@app/services/asset.service';
import { StorageService } from '@app/services/storage.service';

@Component({
  selector: 'app-assets-page',
  templateUrl: './assets-page.component.html',
  styleUrls: ['./assets-page.component.css']
})
export class AssetsPageComponent extends CustomComponent implements OnInit {

  assets = null;
  qrcodesDisplay = false;

  constructor(
    protected override storageService: StorageService,
    protected assetService: AssetService,
    protected override router: Router
  ) { 

    super(storageService, router, true);

  }

  ngOnInit(): void {
    this.loadAssets();
  }

  toggleCodes() {
    this.qrcodesDisplay = !this.qrcodesDisplay;
  }

  loadAssets() {
    this.assetService.getAssets()
      .subscribe(
        (getAssetsResponse) => {
          if (getAssetsResponse.status == 200) {
            this.assets = getAssetsResponse.assets;
          }
        },
        response => {
          console.log(response);
        },
        () => {
            //console.log("The POST observable is now completed.");
        }
      );
  }

  getPrintableAssets() {
    var printAssets = [];
    this.assets.forEach(asset => {
      if (asset.grouped == false) {
        printAssets.push(asset);
      }
    });
    return printAssets;
  }

  getAssetCodeValue(asset) {
    var qrcodeData = {
      'class': asset.class,
      'guid': asset.guid
    }

    return JSON.stringify(qrcodeData);
  }

  createAsset(asset) {
  }

}
