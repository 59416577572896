<div class="row" *ngIf="data != null">

    <div class="headline">Check-In</div>

    <div class="col s12">
        Hallo {{data.firstname}} {{data.lastname}}!<br><br>

        <div *ngIf="data.validity == 0">Der Status muss manuell geprüft werden! Bitte am Empfang melden.</div>
        <div *ngIf="data.validity == 1">Der Status ist OK! Wir wünschen einen erfolgreichen Tag!</div>
    </div>

    <div class="checkin-icons col s12">
        <div class="card-panel center-align">
            <br><br><br>

            <div *ngIf="data.validity == 0">
                <i id="checkin-nok" class="mdi mdi-close-circle-outline red-text"></i>
            </div>

            <div *ngIf="data.validity == 1">
                <i id="checkin-ok" class="mdi mdi-checkbox-marked-circle-outline green-text"></i>
            </div>

            <br>
        </div>
    </div>

    <div class="col s12">
        Vielen Dank!
    </div>

</div>
