import { Component, Input, OnInit, SimpleChanges,EventEmitter, Output, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { CustomComponent } from '@app/components/core/custom-component';
import { Document } from '@app/models/document.model';
import { StorageService } from '@app/services/storage.service';
import { DocumentService } from '@app/services/document.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

declare let M: any;
declare let $: any;

@Component({
  selector: 'app-documentdetails',
  templateUrl: './documentdetails.component.html',
  styleUrls: ['./documentdetails.component.css']
})
export class DocumentdetailsComponent extends CustomComponent implements OnInit {

  genderData = [
    {'key': '', 'value': 'Keine Angabe'},
    {'key': '0', 'value': 'Weiblich'},
    {'key': '1', 'value': 'Männlich'},
    {'key': '9', 'value': 'Divers'}
  ];
  departmentData = [
    {'key': '', 'value': 'Keine Angabe'},
    {'key': 'chirurgie', 'value': 'Chirurgie'},
    {'key': 'innere', 'value': 'Innere Medizin'},
    {'key': 'knie', 'value': 'Knie'},
    {'key': 'kinder', 'value': 'Kinder'},
    {'key': 'handfuss', 'value': 'Hand und Fuß'},
    {'key': 'huefte', 'value': 'Hüfte'},
    {'key': 'sport', 'value': 'Sport'},
    {'key': 'unfall', 'value': 'Unfall/Trauma'},
    {'key': 'wirbelsaeule', 'value': 'Wirbelsäule'}
  ];
  levelData = [
    {'key': '', 'value': 'Keine Angabe'},
    {'key': '0', 'value': 'Kinder'},
    {'key': '1', 'value': '1'},
    {'key': '2', 'value': '2'},
    {'key': '3', 'value': '3'},
    {'key': '4', 'value': '4'},
    {'key': 'WLS', 'value': 'WLS'}
  ]
  monthData = [
    {'key': '', 'value': 'Keine Angabe'},
    {'key': '1', 'value': 'Januar'},
    {'key': '2', 'value': 'Februar'},
    {'key': '3', 'value': 'März'},
    {'key': '4', 'value': 'April'},
    {'key': '5', 'value': 'Mai'},
    {'key': '6', 'value': 'Juni'},
    {'key': '7', 'value': 'Juli'},
    {'key': '8', 'value': 'August'},
    {'key': '9', 'value': 'September'},
    {'key': '10', 'value': 'Oktober'},
    {'key': '11', 'value': 'November'},
    {'key': '12', 'value': 'Dezember'}
  ]

  submitted = false;
  succeeded = false;
  showScans = false;
  showComments = false;
  autolockDocument = false;

  @Input() integration: Boolean = true;
  @Input() document = null;
  action = 'view';

  @Output()
  documentChangeEvent: EventEmitter<Document> = new EventEmitter<Document>();

  documentForm: FormGroup;

  constructor(
    protected override storageService: StorageService,
    protected documentService: DocumentService,
    protected formBuilder: FormBuilder, 
    protected override router: Router
  ) { 

    super(storageService, router, false);
  }

  ngOnInit(): void {
  }

  get df() { return this.documentForm.controls; }

  setDocument(document) {
    this.document = document;
    this.documentChangeEvent.emit(this.document);
  }

  unsetDocument() {
    this.document = null;
    this.documentChangeEvent.emit(this.document);
  }

  setAction(action) {
    this.action = action;
  }

  cancel() {
    this.populateDocumentForm();
    this.setAction('view');
  }

  populateDocumentForm() {

  }

  createDocument() {
    var payload = {
      'document': this.document
    }

    this.documentService.createDocument(payload)
    .subscribe(
      (createDocumentResponse) => {

        if (createDocumentResponse.status == '201') {
          this.setDocument(createDocumentResponse.document);
          this.action = 'view';
          this.succeeded = true;
          M.toast({html: 'Die Daten wurden erfolgreich gespeichert.', classes: 'primary-bg'});

          if (this.autolockDocument) {
            this.lockDocument();
          } else {
            this.createDocumentRedirect();
          }

        } else if (createDocumentResponse.status == '23000') {
          M.toast({html: 'Ein Eintrag mit diesen Daten existiert bereits.', classes: 'red'});
        }

      },
      response => {
        M.toast({html: 'Beim Speichern der Daten ist ein Fehler aufgetreten.', classes: 'red'});
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  createDocumentRedirect() {
    this.unsetDocument();

    if (this.integration == true) {
      this.router.navigateByUrl('/documents');
    }

  }

  updateDocument() {
    var payload = {
      'document': this.document
    }

    this.documentService.updateDocument(payload)
    .subscribe(
      (updateDocumentResponse) => {
        if (updateDocumentResponse.status == '200') {
          this.setDocument(updateDocumentResponse.document);
          this.action = 'view';
          this.succeeded = true;
          M.toast({html: 'Ihr Dokument wurde erfolgreich überarbeitet.', classes: 'primary-bg'});

          if (this.autolockDocument) {
            this.lockDocument();
          } else {
            this.createDocumentRedirect();
          }

        }
      },
      response => {
        M.toast({html: 'Beim Speichern des Dokuments ist ein Fehler aufgetreten.', classes: 'red'});
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  lockDocument() {
    this.documentService.lockDocument(this.document.guid)
    .subscribe(
      (lockDocumentResponse) => {
        if (lockDocumentResponse.status == '200') {
          this.unsetDocument();
          this.succeeded = true;
          M.toast({html: 'Ihr Dokument wurde erfolgreich freigegeben.', classes: 'primary-bg'});
          this.router.navigateByUrl('/documents');
        }
      },
      response => {
        M.toast({html: 'Beim Freigeben des Dokuments ist ein Fehler aufgetreten.', classes: 'red'});
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  deleteDocument() {
    this.documentService.deleteDocument(this.document.guid)
    .subscribe(
      (deleteDocumentResponse) => {
        if (deleteDocumentResponse.status == '200') {
          this.unsetDocument();
          this.succeeded = true;
          M.toast({html: 'Ihr Dokument wurde erfolgreich gelöscht.', classes: 'primary-bg'});
          this.router.navigateByUrl('/documents');
        }
      },
      response => {
        M.toast({html: 'Beim Löschen des Dokuments ist ein Fehler aufgetreten.', classes: 'red'});
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  loadScans() {
    this.toggleScans();
    
    this.documentService.getDocumentScans(this.document.guid)
    .subscribe(
      (getDocumentScansResponse) => {
        if (getDocumentScansResponse.status == '200') {

          var imageData0 = getDocumentScansResponse.pages["page0"];
          var page0 = (<HTMLImageElement>document.getElementById("page0"));
          page0.src = "data:image/jpeg;base64," + imageData0;

          var imageData1 = getDocumentScansResponse.pages["page1"];
          var page1 = (<HTMLImageElement>document.getElementById("page1"));
          page1.src = "data:image/jpeg;base64," + imageData1;
        }
      },
      response => {
        M.toast({html: 'Beim Laden der Scans ist ein Fehler aufgetreten.', classes: 'red'});
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  } 

  toggleScans() {
    this.showScans = !this.showScans;
  }

  loadComments() {
    this.toggleComments();
    
    this.documentService.getDocumentScans(this.document.guid)
    .subscribe(
      (getDocumentScansResponse) => {
        if (getDocumentScansResponse.status == '200') {

          var imageData0 = getDocumentScansResponse.fragments["comments_positive"];
          var page0 = (<HTMLImageElement>document.getElementById("comments_positive"));
          page0.src = "data:image/jpeg;base64," + imageData0;

          var imageData1 = getDocumentScansResponse.fragments["comments_negative"];
          var page1 = (<HTMLImageElement>document.getElementById("comments_negative"));
          page1.src = "data:image/jpeg;base64," + imageData1;
        }
      },
      response => {
        M.toast({html: 'Beim Laden der Scans ist ein Fehler aufgetreten.', classes: 'red'});
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  } 

  toggleComments() {
    this.showComments = !this.showComments;
  }

  genderString(): string {
    return this.translateValue(this.genderData, this.document.content.gender)
  }

  departmentString(): string {
    return this.translateValue(this.departmentData, this.document.content.department)
  }

  levelString(): string {
    return this.translateValue(this.levelData, this.document.content.level)
  }

  monthString(): string {
    return this.translateValue(this.monthData, this.document.content.month)
  }

}
