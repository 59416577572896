import { Component, Input, OnInit, SimpleChanges,EventEmitter, Output, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { DocumentdetailsComponent } from '@app/components/document/documentdetails/documentdetails.component';
import { StorageService } from '@app/services/storage.service';
import { DocumentService } from '@app/services/document.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

declare let M: any;
declare let $: any;

@Component({
  selector: 'app-documentdetails-intake',
  templateUrl: './documentdetails-intake.component.html',
  styleUrls: ['./documentdetails-intake.component.scss']
})
export class DocumentdetailsIntakeComponent extends DocumentdetailsComponent implements OnInit {

  intentData = [
    {'key': 'firstcontact', 'value': 'Erstkontakt'},
    {'key': 'appointment', 'value': 'Termin'},
    {'key': 'pickup', 'value': 'Abholung'}
  ]

  customerData = [
    {'key': '0', 'value': 'Nein'},
    {'key': '1', 'value': 'Ja'}
  ]

  formData = [];

  constructor(
    protected override storageService: StorageService,
    protected override documentService: DocumentService,
    protected override formBuilder: FormBuilder, 
    protected override router: Router,
    protected datePipe: DatePipe
  ) { 

    super(storageService, documentService, formBuilder, router);
  }

  override ngOnInit(): void {
    this.document.content.intent = this.storageService.getValue('intent');
 
    if (this.integration == false) {
      this.action = 'edit';
    }

    this.documentForm = this.formBuilder.group({
      date: new FormControl(null, Validators.required),
      intent: new FormControl(null, Validators.required),
      customer: new FormControl('', Validators.required),
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      q1: new FormControl(null, Validators.required),
      q2: new FormControl(null, Validators.required)
    });

    this.documentForm.get("intent").valueChanges.subscribe(x => {
      this.prepareFormData();
    })

    this.populateDocumentForm();
  }

  get f() { return this.documentForm.controls; }

  prepareFormData() {
    this.formData = [
      {
        'title': '1. Kontakt', 
        'description': 'Kennen Sie bereits eine Mitarbeiterin oder einen Mitarbeiter aus unserem Team?'
      },
      {
        'title': '2. Buchung', 
        'description': 'Haben Sie schon mal bei uns was gebucht oder bestellt?'
      }
    ];

    if (['Boarding', 'OrthAmb', 'NFA', 'ZMA'].includes(this.documentForm.controls['intent'].value)) {
      this.formData[0] = {'title': '1. Arztpräsenz', 'description': 'War ein rascher, strukturierter Arbeitsablauf ohne lange Wartezeiten möglich oder waren die Ärzte in andere Abteilungen involviert?'};
      this.formData[1] = {'title': '2. Ausfallkompensation vs. Arbeitsaufwand', 'description': 'Waren genügend Kollegen verfügbar, um den Arbeitsaufwand zu erfüllen?'};
      this.formData[4] = {'title': '5. Teamwork', 'description': 'Wie hat die Zusammenarbeit mit den Kollegen meiner Abteilung funktioniert?'};
    }
  }

  customerString(): string {
    return this.translateValue(this.customerData, this.document.content.customer)
  }

  intentString(): string {
    return this.translateValue(this.intentData, this.document.content.intent)
  }

  override populateDocumentForm() {
    this.populateFormGroup(this.documentForm, this.document.content);

    var dateTmp = new Date();
    var date;

    if (this.document.content.date) {
      date = this.datePipe.transform(this.document.content.date, 'dd.MM.yyyy');
      this.documentForm.patchValue({'date': date});
    } else {
      date = (dateTmp.getDate() < 10 ? "0":"") + dateTmp.getDate() + "." + 
            (dateTmp.getMonth() < 9 ? "0":"") + (dateTmp.getMonth() + 1) + "." + 
             dateTmp.getFullYear();
      this.documentForm.patchValue({'date': date});
    }

    this.resetDatepicker();
  }

  resetDatepicker() {
    $(document).ready(function() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1); // Increment the date by 1 to get tomorrow

      $(".datepicker").datepicker({
        minDate: tomorrow,
        firstDay: 1,
        i18n: {
          months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
          monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
          weekdays: ["Sonntag","Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
          weekdaysShort: ["So","Mo", "Di", "Mi", "Do", "Fr", "Sa"],
          weekdaysAbbrev: ["S","M", "D", "M", "D", "F", "S"],
          cancel: 'Abbrechen',
          clear: 'Löschen',
          done: 'Ok'
        },
        format: "dd.mm.yyyy"
      });
    
    });

  }

  @ViewChild('actionChanged') set actionChanged(element) {
    if (element) {
      this.resetDatepicker();
    }
  }

  save() {
    this.submitted = true;
    var inputValue = (<HTMLInputElement>document.getElementById("date")).value;
    this.documentForm.patchValue({'date': inputValue});

    if (this.documentForm.invalid) {
      return;

    } else {
      this.populateObject(this.document, this.documentForm);
      this.populateObject(this.document.content, this.documentForm);

      var date = this.getSQLDateFromUIDate(this.documentForm.controls['date'].value);
      this.document.content.date = date;

      this.createDocument();

      this.storageService.setValue('intent', this.document.content.intent);
    }
  }

}
