<div *ngIf="reportData == null">
    <div class="card-panel center-align">
        <br><br>
        <div id="preloader"></div>
        <br>
        <div class="center-align">Bitte warten...</div>
    </div> 
</div>

<div *ngIf="reportData != null">
    <div class="card-panel">

        <div class="left">
            <div class="red-text" *ngIf="reportData.assetlogs.length == 0">
                Für diesen Zeitraum liegen keine Daten vor.
            </div>
            <div *ngIf="reportData.assetlogs.length > 0">
                Für diesen Zeitraum liegen <b>{{reportData.assetlogs.length}}</b> Datensätze vor.
            </div>
        </div>

        <div class="right">
            <button class="btn waves-effect waves-light white grey-text margin-right-5" type="submit" name="action" title="Reload" (click)="reload()">
                <i class="mdi mdi-reload"></i>
            </button>
            <!--button class="btn waves-effect waves-light white grey-text" type="submit" name="action" title="Vergleichen" (click)="addToHistory()">
                <i class="mdi mdi-timetable"></i>
            </button-->
        </div>

        <br>
    </div>

    <div id="resultTable" *ngIf="reportData.assetlogs.length > 0">

        <table class="highlight slim">
            <thead>
                <tr>
                    <th>Datum/Rückgabe</th>
                    <th>Objekt</th>
                    <th>Kontakt</th>
                    <th>Unterschrift</th>
                    <th>Status</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let assetlog of reportData.assetlogs">
                    <td>
                        {{getTimestamp(assetlog.timestamp) | date:'medium':'':'de'}}
                        <span *ngIf="assetlog.timestamp_return != null"><br>{{getTimestamp(assetlog.timestamp_return) | date:'medium':'':'de'}}</span>
                    </td>
                    <td>{{assetlog.asset_name}}</td>
                    <td>{{assetlog.contact_name}}</td>
                    <td class="hide-on-small-only"><img src="{{getSignatureData(assetlog.signature)}}" height="50" *ngIf="assetlog.signature"></td>
                    <td>{{getStatusString(assetlog)}}</td>
                </tr>
            </tbody>
        </table>            
    
    </div>

</div>

<div class="row" *ngIf="historyData && !isEmptyObject(historyData)">
    <h1>Vergleichstabellen</h1>

    <div *ngFor="let historyItem of historyData | keyvalue; let i = index;">
        <div class="card-panel">
            <div style="float: left">
                <span [innerHTML]="historyItem.key"></span>
            </div>

            <div style="float: right">
                <button class="btn waves-effect waves-light white grey-text" type="submit" name="action" title="Löschen" (click)="deleteHistory(historyItem.key)">
                    <i class="mdi mdi-delete-forever"></i>
                </button>
            </div>
            <br clear="all">
        </div>

        <div [innerHTML]="historyItem.value"></div>
    </div>

</div>
