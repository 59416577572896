import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { ReportService } from '@app/services/report.service';
import { StorageService } from '@app/services/storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checkinreportlist',
  templateUrl: './checkinreportlist.component.html',
  styleUrls: ['./checkinreportlist.component.css']
})
export class CheckinreportlistComponent extends CustomComponent implements OnInit {

  @Input() date;
  @Input() todate;
  @Input() reportData = null;
  private reportDataSub: Subscription;
  historyData = {};

  constructor(
    protected reportService: ReportService,
    protected override storageService: StorageService,
    protected override router: Router
  ) { 
    super(storageService, router, true);
  
  }
  
  ngOnInit(): void {
  }

  reload(): void {
    var dates = [this.date, this.todate];
    this.reportService.datesChanged.emit(dates);
  }

  addToHistory(): void {
    var item = <HTMLElement> document.getElementById('resultTable');
    var clone = <HTMLElement> item.cloneNode(true);

    var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    var key = '<b>' + this.date.toLocaleDateString("de-DE", options) + '</b> bis <b>' + this.todate.toLocaleDateString("de-DE", options) + '</b>';
    this.historyData[key] = clone.outerHTML;
  }

  deleteHistory(key) {
    delete this.historyData[key];
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

}
