import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { StorageService } from '@app/services/storage.service';

@Component({
  selector: 'app-checkin-supported-page',
  templateUrl: './checkin-supported-page.component.html',
  styleUrls: ['./checkin-supported-page.component.css']
})
export class CheckinSupportedPageComponent extends CustomComponent implements OnInit {

  constructor(
    protected override storageService: StorageService,
    protected override router: Router
  ) { 

    super(storageService, router, true);
  }

  ngOnInit(): void {
  }

}
