<div class="card primary">

    <div class="card-content">

        <div class="row">
            <div class="col s12 m3">
                <b>Gegenstand</b>
                <div class="show-on-small"><br></div>
            </div>
            
            <div class="col s10 m7" *ngIf="asset == null">
                <span class="red-text">Keine Auswahl...</span>
            </div>
            <div class="col s10 m7" *ngIf="asset != null">
                <span>{{asset.name}}</span><br>
                <span class="grey-text" *ngIf="asset.internal_id">({{asset.internal_id}})<br></span>
                <span [ngClass]="{'red-text': asset.restricted == 1}">
                    {{asset.description}}
                </span>
            </div>

            <div class="col s2 m2 right-align">
                <button class="waves-effect waves-light btn white grey-text" (click)="scan('asset')"><i class="mdi mdi-qrcode-scan"></i></button>
            </div>
        </div>

        <div class="row">
            <div class="col s12 m3">
                <b>Aushändigen an</b>
                <div class="show-on-small"><br></div>
            </div>

            <div class="col s10 m7">
                <span class="red-text" *ngIf="contact == null"></span>
                <span *ngIf="contact != null">{{contact.firstname}} {{contact.lastname}}</span>
                <span class="grey-text" *ngIf="contact != null && contact.company != null"><br>({{contact.company?.name}})</span>
                
                <input matInput placeholder="" aria-label="" [matAutocomplete]="auto" (keyup)="keyupContact($event)">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!isLoading">
                    <mat-option *ngFor="let contact of contacts" [value]="contact.guid"
                        (onSelectionChange)="onClickSuggestion($event, contact)">
                    <span>{{contact.firstname}} {{contact.lastname}}<span *ngIf="contact.company" class="grey-text"> ({{contact.company.name}})</span></span>
                    </mat-option>
                </ng-container>
                </mat-autocomplete>
            </div>

            <div class="col s2 m2 right-align">
                <button class="waves-effect waves-light btn white grey-text" (click)="scan('contact')"><i class="mdi mdi-qrcode-scan"></i></button>
            </div>

        </div>

        <div class="row no-margin">
            <div class="col s12 m3">
                <b>Unterschrift</b>
                <div class="show-on-small"><br></div>
            </div>
            
            <div class="col s10 m7" *ngIf="signatureData == null">
                <span class="red-text"></span>
            </div>
            <div class="col s10 m7 signature-field" *ngIf="signatureData != null" (click)="sign()">
                <span class="green-text"><img src="{{signatureData.png}}" height="100"></span>
            </div>

            <div class="col s2 m2 right-align">
                <button class="waves-effect waves-light btn white grey-text" (click)="sign()"><i class="mdi mdi-lead-pencil"></i></button>
            </div>
            
        </div>

    </div>

    <div class="card-action right-align">
        <button class="waves-effect waves-light btn-large white grey-text" routerLink="/assets/logs">Abbrechen</button>
        &nbsp;
        <a class="waves-effect waves-light btn-large primary-bg" (click)="save()"><i class="mdi mdi-arrow-right-bold-box-outline left"></i>Speichern</a>
    </div>

</div>
