<div *ngIf="reportData == null">
    <div class="card-panel center-align">
        <br><br>
        <div id="preloader"></div>
        <br>
        <div class="center-align">Bitte warten...</div>
    </div> 
</div>

<!--div class="card-panel row">
    <div class="col s12 m6 center-align">
        <div id="positive-canvas-wrapper" class="canvas-wrapper">
            <canvas id="positive-canvas" class="canvas"></canvas>
            <div id="positive-html-canvas" class="canvas html-canvas hide"></div>
        </div>
    </div>
    <div class="col s12 m6">
        <div id="negative-canvas-wrapper" class="canvas-wrapper">
            <canvas id="negative-canvas" class="canvas"></canvas>
            <div id="negative-html-canvas" class="canvas html-canvas hide"></div>
        </div>
    </div>
</div-->

<div *ngIf="reportData != null && reportData.documents != null">
    <div class="card-panel">

        <div class="left">
            <div class="red-text" *ngIf="reportData.documents.length == 0">
                Für diesen Zeitraum liegen keine Daten vor.
            </div>
            <div *ngIf="reportData.documents.length > 0">
                Für diesen Zeitraum liegen <b>{{reportData.documents.length}}</b> Datensätze vor.
            </div>
        </div>

        <div class="right">
            <div class="left pad-right-10">
                <select class="browser-default custom-select" [(ngModel)]="selectedLevel" (change)="levelfilterChanged($event)">
                    <option value="">Alle</option>
                    <option *ngFor="let level of levelData" [ngValue]="level.key">{{level.value}}</option>
                </select>
            </div>

            <div class="right">
                <button class="btn waves-effect waves-light white grey-text margin-right-5" type="submit" name="action" title="Reload" (click)="reload()">
                    <i class="mdi mdi-reload"></i>
                </button>
                <!--button class="btn waves-effect waves-light white grey-text" type="submit" name="action" title="Vergleichen" (click)="addToHistory()">
                    <i class="mdi mdi-timetable"></i>
                </button-->
            </div>
        </div>

        <br>
    </div>

    <div class="card-panel row" *ngIf="reportData.documents.length > 0">
        <div class="col s12 m12 l4">
            <div id="gauge-container" *ngIf="reportData.scores.length > 0">
                <google-chart [data]="gaugeCharts" class="gauge-charts" *ngIf="gaugeCharts != null"></google-chart>
            </div>
        </div>
        <div class="col s12 m12 spacer-medium hide-on-large-only"></div>
        <div class="col s12 m12 l8" *ngIf="reportData.scores_history.length > 0">
            <google-chart [data]="lineCharts" *ngIf="lineCharts != null"></google-chart>
        </div>
    </div>

    <div id="resultTable" *ngIf="reportData.documents.length > 0">
        <div class="card-panel zz-charts">

            <table class="highlight slim">
                <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Ebene</th>
                        <th>Schicht</th>
                        <th>Score</th>
                        <th class="center-align" matTooltip="Visitenbegleitung" matTooltipPosition="above" matTooltipClass="tooltip">Q1 <i class="mdi mdi-information-outline"></i></th>
                        <th class="center-align" matTooltip="Ausfallkompensation vs. Pflegeaufwand" matTooltipPosition="above" matTooltipClass="tooltip">Q2 <i class="mdi mdi-information-outline"></i></th>
                        <th class="center-align" matTooltip="Interdisziplinäre Schnittstelle" matTooltipPosition="above" matTooltipClass="tooltip">Q3 <i class="mdi mdi-information-outline"></i></th>
                        <th class="center-align" matTooltip="Einarbeitung neuer Kollegen/Betreuung Azubis/Schüler" matTooltipPosition="above" matTooltipClass="tooltip">Q4 <i class="mdi mdi-information-outline"></i></th>
                        <th class="center-align" matTooltip="Teamwork" matTooltipPosition="above" matTooltipClass="tooltip">Q5 <i class="mdi mdi-information-outline"></i></th>
                        <th class="center-align" matTooltip="Funktionierende Infrastruktur; IT (Visitenwagen, ORBIS, Drucker, etc.)" matTooltipPosition="above" matTooltipClass="tooltip">Q6 <i class="mdi mdi-information-outline"></i></th>
                        <th class="center-align" matTooltip="Funktionierende Infrastruktur; Technik (TV, Aufzug, etc.)" matTooltipPosition="above" matTooltipClass="tooltip">Q7 <i class="mdi mdi-information-outline"></i></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let document of reportData.documents" matTooltip="{{getTooltip(document)}}" matTooltipPosition="above" matTooltipClass="tooltip">
                        <td>{{getTimestamp(document.date) | date:'dd.MM.'}} ({{getTimestamp(document.date) | date:'EEEE':'':'de'}})</td>
                        <td>{{levelString(document.level)}}</td>
                        <td>{{shiftString(document.shift)}}</td>
                        <td>{{document.score_total}}</td>
                        <td class="q_{{document.q1}}"></td>
                        <td class="q_{{document.q2}}"></td>
                        <td class="q_{{document.q3}}"></td>
                        <td class="q_{{document.q4}}"></td>
                        <td class="q_{{document.q5}}"></td>
                        <td class="q_{{document.q6}}"></td>
                        <td class="q_{{document.q7}}"></td>
                    </tr>
                </tbody>
            </table>            

            <div class="spacer-large"></div>
            <h2>Kommentare</h2>

            <div class="row" #documentsContent>
                <div class="col s12">
                    <ul class="tabs full-transparent transparent z-depth-0">
                        <li class="tab col s6"><a href="#comments_positive_container" class="active">Positiv</a></li>
                        <li class="tab col s6"><a href="#comments_negative_container">Negativ</a></li>
                    </ul>
                </div>
                <div id="comments_positive_container" class="col s12 active">
                    <div class="tab-content transparent z-depth-0">
                        <table class="highlight slim" *ngIf="reportData.documents.length > 0">
                            <tr *ngFor="let document of reportData.documents">
                                <td *ngIf="document.comment_positive">{{document.comment_positive}} - <span class="grey-text">({{levelString(document.level)}}, {{shiftString(document.shift)}})</span>&nbsp;<a class="details_link" (click)="setDocument(document)">[Details]</a></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div id="comments_negative_container" class="col s12" style="display: none;">
                    <div class="tab-content transparent z-depth-0">
                        <table class="highlight slim" *ngIf="reportData.documents.length > 0">
                            <tr *ngFor="let document of reportData.documents">
                                <td *ngIf="document.comment_negative">{{document.comment_negative}} - <span class="grey-text">({{levelString(document.level)}}, {{shiftString(document.shift)}})</span>&nbsp; <a class="details_link" (click)="setDocument(document)">[Details]</a></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        
        </div>
    </div>

</div>

<div class="row" *ngIf="historyData && !isEmptyObject(historyData)">
    <h1>Vergleichstabellen</h1>

    <div *ngFor="let historyItem of historyData | keyvalue; let i = index;">
        <div class="card-panel">
            <div style="float: left">
                <span [innerHTML]="historyItem.key"></span>
            </div>

            <div style="float: right">
                <button class="btn waves-effect waves-light white grey-text" type="submit" name="action" title="Löschen" (click)="deleteHistory(historyItem.key)">
                    <i class="mdi mdi-delete-forever"></i>
                </button>
            </div>
            <br clear="all">
        </div>

        <div [innerHTML]="historyItem.value"></div>
    </div>

</div>
