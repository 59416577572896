import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { AssetLog } from '@app/models/assetlog.model';
import { GetAssetlogResponse } from '@app/models/responses/getAssetlogResponse.model';
import { GetAssetLogsResponse } from '@app/models/responses/getAssetLogsResponse.model';
import { GenericAssetResponse } from '@app/models/responses/genericAssetResponse.model';
import { GetAssetsResponse } from '@app/models/responses/getAssetsResponse.model';
import { IssueAssetResponse } from '@app/models/responses/issueAssetResponse.model';
import { ReturnAssetResponse } from '@app/models/responses/returnAssetResponse.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  private headers = null;
  private options = {};

  assetlogChanged: EventEmitter<AssetLog> = new EventEmitter<AssetLog>();

  constructor(
    private http: HttpClient

  ) { 
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    this.options = {
      headers: this.headers
    }
  }

  public async downloadAssetsPDF(guidList: string[]): Promise<void> {
    window.URL = window.URL || window.webkitURL;

    this.options['responseType'] = 'blob';
    var data = {'guids': guidList};

    const blob =  await this.http.post<Blob>(environment.apiEndpoint + '/api/assets/print/1.0.0.php', data, this.options).toPromise();
    const url = window.URL.createObjectURL(blob);
    
    window.location.assign(url);
  }

  getAssets() {
    var params = new HttpParams();
    //params = params.append('user_guid', user.guid);
    this.options['params'] = params;

    return this.http.get<GetAssetsResponse>(environment.apiEndpoint + '/api/assets/1.0.0.php', this.options);
  }

  getOpenAssetLogs() { 
    var params = new HttpParams();
    params = params.append('status', '0');
    this.options['params'] = params;

    return this.http.get<GetAssetLogsResponse>(environment.apiEndpoint + '/api/assets/log/1.0.0.php', this.options);
  }

  getAssetLogs() {
    var params = new HttpParams();
    //params = params.append('user_guid', user.guid);
    this.options['params'] = params;

    return this.http.get<GetAssetLogsResponse>(environment.apiEndpoint + '/api/assets/log/1.0.0.php', this.options);
  }

  getAsset(guid) {
    var params = new HttpParams();
    params = params.append('guid', guid);
    this.options['params'] = params;

    return this.http.get<GenericAssetResponse>(environment.apiEndpoint + '/api/asset/1.0.0.php', this.options);
  }

  getAssetlog(guid) {
    var params = new HttpParams();
    params = params.append('guid', guid);
    this.options['params'] = params;

    return this.http.get<GetAssetlogResponse>(environment.apiEndpoint + '/api/asset/log/1.0.0.php', this.options);
  }

  issueAsset(asset, contact, signatureData) {
    var payload = {
      'asset': {
        'guid': asset.guid,
        'name': asset.name
      },
      'contact': {
        'guid': contact.guid,
        'firstname': contact.firstname,
        'lastname': contact.lastname
      },
      'signature': JSON.stringify(signatureData)
    };
    return this.http.post<IssueAssetResponse>(environment.apiEndpoint + '/api/asset/issue/1.0.0.php', payload, this.options);
  }

  returnAsset(assetlog, contact) {
    var payload = {
      'assetlog': {
        'guid': assetlog.guid
      },
      'contact': {
        'guid': contact.guid,
        'firstname': contact.firstname,
        'lastname': contact.lastname
      },
    };
    return this.http.post<ReturnAssetResponse>(environment.apiEndpoint + '/api/asset/return/1.0.0.php', payload, this.options);
  }

  sendToSalesforce(asset, contact, signatureData) {
    var payload = {
      'asset': {
        'guid': asset.guid,
        'name': asset.name
      },
      'contact': {
        'guid': contact.guid,
        'firstname': contact.firstname,
        'lastname': contact.lastname,
        'email': contact.email
      },
      'signature': JSON.stringify(signatureData)
    };
    return this.http.post<IssueAssetResponse>(environment.apiEndpoint + '/api/salesforce/post.php', payload, this.options);
  }

  createAsset(payload: object) {
    const body = payload;
    return this.http.post<GenericAssetResponse>(environment.apiEndpoint + '/api/asset/1.0.0.php', body, this.options);
  }

  updateAsset(payload: object) {
    const body = payload;
    return this.http.put<GenericAssetResponse>(environment.apiEndpoint + '/api/asset/1.0.0.php', body, this.options);
  }

  deleteAsset(guid) {
    let params = new HttpParams();
    params = params.append('guid', guid);
    this.options['params'] = params;

    return this.http.delete<GenericAssetResponse>(environment.apiEndpoint + '/api/asset/1.0.0.php', this.options);
  }

}
