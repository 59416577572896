<div class="container">
    <div class="section">
    
        <h1 class="bot-20 sec-tit">Reports (ZZ)</h1>

        <div *ngIf="document == null">
            <app-report-date-selector 
                [date]="date" [todate]="todate">
            </app-report-date-selector>

            <app-zzreportlist 
                [selectedLevel]="selectedLevel" [date]="date" [todate]="todate" [reportData]="reportData" (documentChangeEvent)="documentChanged($event)"
                (levelChangeEvent)="levelChanged($event)">
            </app-zzreportlist>
        </div>

        <div *ngIf="document != null">
            <div class="row">
                <div class="col s12">
                    <app-documentdetails-zma [document]="document" (documentChangeEvent)="documentChanged($event)" *ngIf="document.class == 'ZMA'"></app-documentdetails-zma>
                    <app-documentdetails-station [document]="document" (documentChangeEvent)="documentChanged($event)" *ngIf="document.class == 'STATION'"></app-documentdetails-station>
                    <app-documentdetails-zz [document]="document" (documentChangeEvent)="documentChanged($event)" *ngIf="document.class == 'ZZ'"></app-documentdetails-zz>
                </div>
            </div>
        </div>

    </div>
</div>
